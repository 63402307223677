import styled from "styled-components";

export const StyledHelpModalContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 60px;
  margin-right: 60px;
  height: 100%;
`;

export const StyledTextContainer = styled.div`
  margin-top: 20px;
  height: auto;
  width: 520px;

  white-space: pre-wrap;
`;

export const StyledPagination = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledControlsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
`;

export const StyledControlsContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  padding: 5px 0px;
`;

export const StyledControlsImage = styled.img`
  padding-right: 30px;
  height: 50px;
`;

export const StyledControlsImportance = styled.div`
  font-weight: 600;
  padding-right: 5px;
`;

export const StyledCloseButtonParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
