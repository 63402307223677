import React, { useState, useEffect } from "react";
import SliderComponent from "./SliderComponent";
import { useParametricData } from "../hooks/ParametricDataContext";

export default function ImageSlider(props) {
  const parametricData = useParametricData();

  //Change this if more components need to use images
  const staticImageArray = [
    ["curve1and2", "curve1and2", "curve3", "curve4"],
    ["angle1and2", "angle1and2", "angle3", "angle4"],
  ];

  const [imageIndex, setImageIndex] = useState({
    primaryIndex: parametricData.BaffleZigZagOrWave ? 0 : 1,
    secondaryIndex: parametricData.BaffleZigZagType - 1,
    path: parametricData.BaffleZigZagOrWave ? "Curve" : "Angle",
  });

  useEffect(() => {
    let newImageIndex = {};
    newImageIndex.primaryIndex = parametricData.BaffleZigZagOrWave ? 0 : 1;
    newImageIndex.path = parametricData.BaffleZigZagOrWave ? "Curve" : "Angle";
    newImageIndex.secondaryIndex = parametricData.BaffleZigZagType - 1;
    setImageIndex(newImageIndex);
    console.log("ZIGZAG");
  }, [parametricData]);
  return (
    <div>
      <SliderComponent
        inputEmitter={props.inputEmitter}
        componentData={props.componentData}
        key={props.componentData.Name}
      />

      <img
        src={
          "/icons/" +
          imageIndex.path +
          "/" +
          staticImageArray[imageIndex.primaryIndex][imageIndex.secondaryIndex] +
          ".jpg"
        }
        alt=""
        height="200px"
        width="100%"
        style={{ borderRadius: "8px", marginTop: "20px" }}
      />
    </div>
  );
}
