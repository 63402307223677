import React from "react";
import Modal from "react-modal";
import ConfigLayout from "./ConfigLayout";
function LoadConfigModal(props) {
  return (
    <Modal
      style={{
        content: {
          width: "650px",
          height: "650px",
          background: "#fff",
          overflow: "auto",
          outline: "none",
          padding: "20px",
        },
      }}
      isOpen={true}
      onRequestClose={() => {}}
      className="modalMain"
      overlayClassName="modalOverlay"
    >
      <div>
        <h2>Saved Configs</h2>
        <hr />
        <div className="lookContainer">
          <ConfigLayout
            launch={props.launch}
            isModal
            inputEmitter={props.inputEmitter}
            hideSave
          />
        </div>
        <hr />
        <br />
        <br />
        <div className="flexContainer">
          <button className="modal-button login" onClick={props.onBack}>
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default LoadConfigModal;
