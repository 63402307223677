import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import Tick from "../../assets/icons/tick.svg";
import {
  useParametricData,
  useParametricUpdate,
} from "../../hooks/ParametricDataContext";
import ColourPickerComponent from "../ColourPickerComponent";
import { coloursObject, findColour } from "../../globals/GlobalColours";
const palletes = [
  { name: "Palette 1", colours: [2, 10, 18] },
  { name: "Palette 2", colours: [1, 9, 17] },
  { name: "Palette 3", colours: [0, 8, 16] },
  { name: "Palette 4", colours: [4, 12, 36] },
  { name: "Palette 5", colours: [5, 13, 21] },
  { name: "Palette 6", colours: [0, 16, 11] },
];
function SingleAssetColourMenu(props) {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();

  const [palleteNumber, setPalleteNumber] = useState(0);
  const [colourNumber, setColourNumber] = useState(
    parametricData.Colours.ThirdColourPercentage > 0
      ? 3
      : parametricData.Colours.SecondColourPercentage > 0
      ? 2
      : 1
  );
  const [item, setItem] = useState([
    findColour(parametricData.Colours.MainColour, 3),
    findColour(parametricData.Colours.SecondColour, 3),
    findColour(parametricData.Colours.ThirdColour, 3),
  ]);
  const [picking, setPicking] = useState(false);
  const [colourIndex, setColourIndex] = useState(0);

  const onSelectPallete = (palleteIndex) => {
    console.log(picking);
    if (palleteIndex === palleteNumber) {
      console.log(picking);
      setPicking(false);
      return;
    }
    var message = { messageType: "SetValue", PaletteIndex: palleteIndex };
    message["Colours"] = {
      mainColour: {
        r: coloursObject[palletes[palleteIndex].colours[0]].r,
        g: coloursObject[palletes[palleteIndex].colours[0]].g,
        b: coloursObject[palletes[palleteIndex].colours[0]].b,
        a: 255,
      },
      secondColour: {
        r: coloursObject[palletes[palleteIndex].colours[1]].r,
        g: coloursObject[palletes[palleteIndex].colours[1]].g,
        b: coloursObject[palletes[palleteIndex].colours[1]].b,
        a: 255,
      },
      thirdColour: {
        r: coloursObject[palletes[palleteIndex].colours[2]].r,
        g: coloursObject[palletes[palleteIndex].colours[2]].g,
        b: coloursObject[palletes[palleteIndex].colours[2]].b,
        a: 255,
      },
      mainColourPercentage: 100,
      secondColourPercentage: 100,
      thirdColourPercentage: colourNumber === 2 ? 0 : 100,
    };
    parametricData.Colours.MainColour = {
      r: coloursObject[palletes[palleteIndex].colours[0]].r,
      g: coloursObject[palletes[palleteIndex].colours[0]].g,
      b: coloursObject[palletes[palleteIndex].colours[0]].b,
      a: 255,
    };

    parametricData.Colours.SecondColour = {
      r: coloursObject[palletes[palleteIndex].colours[1]].r,
      g: coloursObject[palletes[palleteIndex].colours[1]].g,
      b: coloursObject[palletes[palleteIndex].colours[1]].b,
      a: 255,
    };
    parametricData.Colours.ThirdColour = {
      r: coloursObject[palletes[palleteIndex].colours[2]].r,
      g: coloursObject[palletes[palleteIndex].colours[2]].g,
      b: coloursObject[palletes[palleteIndex].colours[2]].b,
      a: 255,
    };
    parametricData.Colours.MainColourPercentage = 100;
    parametricData.Colours.SecondColourPercentage = 100;
    parametricData.Colours.ThirdColourPercentage = colourNumber === 2 ? 0 : 100;
    parametricData.PaletteIndex = palleteIndex;
    parametricData.ColourIndex = -1;
    props.inputEmitter.EmitUIInteraction(message);
    setPicking(false);
    setPalleteNumber(palleteIndex);
    setParametricData({ ...parametricData });
    setItem([
      palletes[palleteIndex].colours[0],
      palletes[palleteIndex].colours[1],
      palletes[palleteIndex].colours[2],
    ]);
  };
  const toggleItem = (index) => {
    var items = { ...item };
    items[colourIndex] = index;
    setItem(items);

    props.setNewColour({
      r: coloursObject[index].r,
      g: coloursObject[index].g,
      b: coloursObject[index].b,
      a: 255,
    });
  };
  useEffect(() => {
    console.log(parametricData.Colours);
    setColourNumber(
      parametricData.Colours.ThirdColourPercentage > 0
        ? 3
        : parametricData.Colours.SecondColourPercentage > 0
        ? 2
        : 1
    );
    setItem([
      findColour(parametricData.Colours.MainColour, 3),
      findColour(parametricData.Colours.SecondColour, 3),
      findColour(parametricData.Colours.ThirdColour, 3),
    ]);
  }, [parametricData.Colours]);
  const colourPicker = () => (
    <ColourPickerComponent
      coloursObject={coloursObject}
      onColourSelected={(index) => {
        setPicking(false);
        toggleItem(index);
      }}
      selectedIndex={item}
      disabled={props.disabled}
    />
  );

  const singleView = () => (
    <div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          opacity: props.disabled && "0.3",
        }}
      >
        <div
          onClick={() => {
            setColourIndex(0);
            setPicking(true);
            props.setShowCheckbox(false);
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
          style={{
            marginRight: 10,
            cursor: "pointer",
            display: "inline-block",
            borderWidth: 2,
            borderColor: "#5EE1CB",
            borderStyle: "solid",
            height: 36,
            width: 26,
            pointerEvents: props.disabled && "none",
            background: !props.disabled
              ? `rgb(${coloursObject[item[0]].r}, ${coloursObject[item[0]].g},${
                  coloursObject[item[0]].b
                })`
              : `rgb(0, 0, 0, 1)`,
          }}
        />
        <div>{coloursObject[item[0]].swatchName}</div>
        <img style={{ display: "inline-block" }} src={Tick} alt="tick" />
      </div>
    </div>
  );
  return (
    <div style={{}}>
      <div>Select a colour for the asset</div>

      <div>
        <br />

        {picking ? colourPicker() : item === -1 ? colourPicker() : singleView()}
      </div>
    </div>
  );
}

export default SingleAssetColourMenu;
