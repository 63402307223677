import React, { useEffect, useState } from "react";
import { useParametricData } from "../hooks/ParametricDataContext";

import { useSavesData, UseSetSaves } from "../hooks/SavesHook";
import { useUser } from "../hooks/UseContext";
import "./ConfigLayout.css";
import { TempModal } from "./TempModal";
import { Auth } from "aws-amplify";
function ConfigButton(props) {
  const parametricData = useParametricData();
  const names = useSavesData();
  const setNames = UseSetSaves();
  const user = useUser();
  const [showModal, setShowModal] = useState(false);
  function onModalClicked(value) {
    var message = { messageType: "Save", Name: value };
    console.log(message);
    props.inputEmitter.EmitUIInteraction(message);

    setShowModal(false);
    // const interval = setInterval(async () => {
    //   if (!names.includes(value)) {
    //     setNames([...names, value]);
    //     console.log(names.length);
    //     try {
    //       const currentUser = await Auth.currentAuthenticatedUser();
    //       await Auth.updateUserAttributes(currentUser, {
    //         "custom:Configs": (names.length + 1).toString(),
    //       });
    //     } catch (e) {
    //       console.log(e);
    //       clearInterval(interval);
    //     }
    //   }

    //   clearInterval(interval);
    // }, 3000);
  }

  return !user ? (
    <></>
  ) : (
    <div style={{ flex: 1 }}>
      <TempModal
        buttonTitle="Save"
        fieldTitle="Save name:"
        onModalClick={onModalClicked}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        label="Save"
      />

      <>
        <button className="button" onClick={() => setShowModal(true)}>
          Save Config
        </button>
      </>
    </div>
  );
}

export default ConfigButton;
