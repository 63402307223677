/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import CognitoUser from "amazon-cognito-identity-js";
import ReactGA from "react-ga4";
import axios from "axios";
import {
  LaunchStatusEvent,
  LaunchStatusType,
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  InputEmitter,
  DefaultStreamerOptions,
  StreamerStatus,
  StreamContribution,
} from "@pureweb/platform-sdk";
import "semantic-ui-css/semantic.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useSearchParams,
} from "react-router-dom";
import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  LaunchRequestOptions,
  VideoStream,
  System,
} from "@pureweb/platform-sdk-react";
import * as qs from "query-string";
import React, { useEffect, useState, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { Button, Icon } from "semantic-ui-react";
// import { Button } from "semantic-ui-react";
import useAsyncEffect from "use-async-effect";
import "./App.css";
import clientConfig from "./client.json";

// import { LaunchView } from "./Launch";
import logger from "./Log";

// import Modal from "./components/ModalComponent";
import RightMenu from "./components/RightMenu";
import MainMenu from "./components/MainMenu";
// import TempSaveConfigComponent from "./components/TempSaveConfigComponent";
import logo from "./assets/sonifyLogo.svg";
import {
  useParametricData,
  useParametricUpdate,
} from "./hooks/ParametricDataContext";
import { useSetUser, useUser } from "./hooks/UseContext";
import { RiAccountCircleFill } from "react-icons/ri";
import JourneyModals from "./components/JourneyModals";
import { FaPagelines } from "react-icons/fa";
import TopMenu from "./components/TopMenu";
import { UseSetSaves } from "./hooks/SavesHook";
import { API, Analytics, Auth, Hub } from "aws-amplify";
import { HubCapsule, Logger } from "@aws-amplify/core";
import { Loader } from "semantic-ui-react";
import { parametricData, setParametricData } from "./globals/GolbalConfig";
import SceneControlMenu from "./components/SceneControlMenu";
import AdminDashboard from "./components/AdminDashboard";
import { Config } from "./globals/Config";
import UpdateModal from "./components/UpdateModal/UpdateModal";

import packageJson from "../package.json";
import HelpButton from "./components/HelpModal/HelpButton";
import CustomHeightModal from "./components/CustomRoom/CustomHeightModal";
import {
  getUserGroup,
  isUserInternal,
} from "./components/HelpFunctions/analytics";
import { UseSetRaftCardsContext } from "./hooks/RaftCardsContext";
import { useSetGuideImage } from "./hooks/GuideImageContext";

const config = Config;
// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
// import { Button, Icon } from "semantic-ui-react";
// import { convertCompilerOptionsFromJson } from "typescript";
const DebugMenu = true && process.env.NODE_ENV === "development";
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  environmentId?: string;
  launchType?: string;
  projectId?: string;
  modelId?: string;
  version?: string;
  endpoint?: string;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
  useNativeTouchEvents?: boolean;
}

class ClientOptions {
  // Overridable connection options
  LaunchType?: string;

  // Launch queue configuration
  ProjectId?: string;
  ModelId?: string;
  Version?: string;
  EnvironmentId?: string;
  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;
  UseNativeTouchEvents?: boolean;
  UsePointerLock?: boolean;
  PointerLockRelease?: boolean;

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }
    if (!this.ModelId) {
      return false;
    }
    return true;
  }
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
  if (
    props.StreamerStatus === StreamerStatus.Connected ||
    props.StreamerStatus === StreamerStatus.Completed
  ) {
    return <div />;
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>
          Your browser does not support the necessary WebRTC capabilities.
        </h3>
      </div>
    );
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    );
  } else {
    content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ height: 150, width: 150 }}>
          <video loop muted autoPlay>
            <source src="/SonifyLoop.mp4" type="video/mp4" />
            <track kind="caption" />
          </video>
        </div>

        <h3>Please wait, your session is loading.</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div style={{ textAlign: "center" }}>{content}</div>
    </div>
  );
};

interface ViewProps {
  MessageSubject: any;
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  PointerLockRelease: boolean;
  StreamLoaded: boolean;
  menuIndex: number;
  setMenuIndex: (index: number) => void;
  Reset: () => void;
}

const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  useEffect(() => {}, [props.InputEmitter]);

  useEffect(() => {
    const subscription = props.MessageSubject.subscribe((value: any) => {
      const message = JSON.parse(value);

      if (message.messageType === "SetRafts") {
        let NewRafts = [];
        for (let i = 0; i < message.count; i++) {
          NewRafts.push({ id: i, name: "Raft" + (i + 1) });
        }
        setRaft(NewRafts);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.MessageSubject]);

  const [collisionMessage, setCollisionMessage] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const handle = useFullScreenHandle();

  const initialRafts = [{ id: 0, name: "Raft 1" }];
  const [raft, setRaft] = useState(initialRafts);
  const [raftIndex, setRaftIndex] = useState(raft.length);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [movingRaft, setMovingRaft] = useState<number[]>([]);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCustomHeightModal, setShowCustomHeightModal] = useState(false);

  let latestRenderedVersion = window.localStorage.getItem("UPDATE_MODAL");
  const appVersion = packageJson.version;

  // will show what's new modal if version is updated
  useEffect(() => {
    if (appVersion !== latestRenderedVersion) {
      setShowUpdateModal(true);
      window.localStorage.setItem("UPDATE_MODAL", appVersion);
    }
  }, []);

  useEffect(() => {
    if (parametricData.Level === 5) {
      setShowCustomHeightModal(true);
    }
  }, []);
  useEffect(() => {
    const subscription = props.MessageSubject.subscribe((value: any) => {
      const message = JSON.parse(value);

      if (message.messageType === "collision") {
        if (message.collisionMessage == "") setCollisionMessage("");
        else {
          setCollisionMessage(
            "Collision Detected on Raft " + message.collisionMessage
          );
        }
      }

      return () => {
        subscription.unsubscribe();
      };
    });
  }, [props.MessageSubject]);

  function handleNewRaft() {
    let newRaft = { id: raftIndex + 1, name: "Raft " + (raftIndex + 1) };
    setRaft([...raft, newRaft]);
    setSelectedIndex(raftIndex);
    setMovingRaft([raftIndex]);

    setRaftIndex(raftIndex + 1);

    props.InputEmitter.EmitUIInteraction({
      messageType: "SpawnRaft",
    });
    props.InputEmitter.EmitUIInteraction({
      messageType: "SelectRaft",
      Index: raftIndex,
    });
  }

  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  // const isIPhone =
  //   System.Browser().os === "iOS" &&
  //   !window.navigator.userAgent.includes("iPad");
  return (
    <div id="ui" style={{ height: "100%" }}>
      <FullScreen handle={handle}>
        {!DebugMenu && (
          <IdleTimeout
            Status={props.StreamerStatus}
            WarningThreshold={300}
            ExitThreshold={120}
            WarningCallback={handle.exit}
            ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
          />
        )}

        <LoadingView
          LaunchRequestStatus={props.LaunchRequestStatus}
          StreamerStatus={props.StreamerStatus}
        />
        <VideoStream
          VideoRef={videoRef}
          Emitter={props.InputEmitter}
          Stream={props.VideoStream}
          UseNativeTouchEvents={props.UseNativeTouchEvents}
          UsePointerLock={props.UsePointerLock}
          PointerLockRelease={props.PointerLockRelease}
        />

        {/* <Button
          onClick={handle.enter}
          style={{ position: "absolute", top: 10, right: 10 }}
          className={
            isIPhone ||
            handle.active ||
            props.StreamerStatus !== StreamerStatus.Connected
              ? "hidden"
              : ""
          }
        >
          <Icon name="expand"/>
        </Button> */}
        <img src={logo} className="sonify-logo" alt="Sonify by zentia" />
        <div
          id="ui-parent"
          className="UiParent"
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: 50,
            right: 10,
          }}
        >
          {/* <Modal /> */}

          {(props.StreamLoaded || DebugMenu) && (
            <>
              {showUpdateModal && (
                <UpdateModal onClose={() => setShowUpdateModal(false)} />
              )}

              {showCustomHeightModal && (
                <CustomHeightModal
                  onClose={() => setShowCustomHeightModal(false)}
                  inputEmitter={props.InputEmitter}
                />
              )}

              <RightMenu
                onSelectMenu={props.setMenuIndex}
                menuIndex={props.menuIndex}
                inputEmitter={props.InputEmitter}
              />

              <MainMenu
                menuIndex={props.menuIndex}
                setMenuIndex={props.setMenuIndex}
                inputEmitter={props.InputEmitter}
                messageSubject={props.MessageSubject}
                reset={props.Reset}
                rafts={raft}
                setRaft={setRaft}
                onNewRaftClick={handleNewRaft}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                movingRaft={movingRaft}
                setMovingRaft={setMovingRaft}
              />
              <TopMenu
                menuIndex={props.menuIndex}
                inputEmitter={props.InputEmitter}
                collisionMessage={collisionMessage}
              />
            </>
          )}
        </div>
        {parametricData.Level !== 5 && (
          <SceneControlMenu inputEmitter={props.InputEmitter} />
        )}
        {/* {props.StreamerStatus !== StreamerStatus.Connected && (
          <img
            alt="PureWeb Logo"
            src="/pureweb.svg"
            style={{ width: 100, position: "absolute", bottom: 50, right: 10 }}
          />
        )} */}
        {parametricData.Level === 5 && props.StreamLoaded && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              right: 10,
              marginRight: props.menuIndex === -1 ? 60 : 400,
            }}
          >
            <HelpButton
              title="Custom Room Scene"
              section="custom_room"
              clickable={true}
            />
          </div>
        )}
      </FullScreen>
    </div>
  );
};

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 0.0;

// Parse query parameters
const query = qs.parse(window.location.search);
const clientOptions: ClientOptions = new ClientOptions();
clientOptions.LaunchType = (query["launchType"] as string) ?? client.launchType;
if (query["collaboration"] && query["collaboration"] === "true")
  clientOptions.LaunchType = "local";

clientOptions.Endpoint = (query["endpoint"] as string) ?? client.endpoint;
clientOptions.ProjectId = (query["projectId"] as string) ?? client.projectId;
clientOptions.ModelId = (query["modelId"] as string) ?? client.modelId;
clientOptions.Version = (query["version"] as string) ?? client.version;
clientOptions.EnvironmentId =
  (query["environmentId"] as string) ?? client.environmentId;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query["usePointerLock"] === undefined
    ? client.usePointerLock
    : query["usePointerLock"] === "true") ?? true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query["pointerLockRelease"] === undefined
    ? client.pointerLockRelease
    : query["pointerLockRelease"] === "true") ?? true;

clientOptions.ForceRelay = query["forceRelay"] !== undefined ?? false;
clientOptions.UseNativeTouchEvents =
  (query["useNativeTouchEvents"] === undefined
    ? client.useNativeTouchEvents
    : query["useNativeTouchEvents"] === "true") ?? false;
// Initialize platform reference
const platform = new PlatformNext();
platform.initialize({
  endpoint: clientOptions.Endpoint || "https://api.pureweb.io",
});

const App: React.FC = () => {
  //PUREWEB VARS
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] =
    useState(false);
  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition()
  );
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const [launchRequestError, setLaunchRequestError] = useState<Error>();
  const streamerOptions = DefaultStreamerOptions;
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [loadingShared, setLoadingShared] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);

  //Test to see if it is a shared links
  useEffect(() => {
    if (params.get("data") !== null) {
      let linkData = JSON.parse(window.atob(params.get("data") as string));
      delete linkData.LoadName;
      delete linkData.Loading;
      setParaData({ ...paraData, ...linkData });
      console.log(paraData);
      setLoadingShared(true);
    }
  }, []);

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info("Initializing available models: " + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(
          clientOptions.ProjectId,
          clientOptions.EnvironmentId
        );
        await platform.connect();
        logger.info("Agent Connected: " + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials
          .iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;
        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug("Available models", models);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [clientOptions]);

  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (
        model: ModelDefinition
      ): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (
            clientOptions.Version &&
            clientOptions.Version === model.version
          ) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        setModelDefinitionUnavailable(true);
      }
    }
  }, [availableModels]);

  useEffect(() => {
    if (modelDefinition.type !== 0) {
      if (loadingShared || params.get("selectedUser") !== null) launch();
    }
  }, [modelDefinition]); // eslint-disable-line react-hooks/exhaustive-deps

  const paraData = useParametricData();
  const setParaData = useParametricUpdate();
  const [streamLoaded, setStreamLoaded] = useState(false);
  const launchRequestOptions: LaunchRequestOptions = {
    regionOverride: query["regionOverride"] as string,
    virtualizationProviderOverride: query[
      "virtualizationProviderOverride"
    ] as string,
  };
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    platform,
    modelDefinition,
    launchRequestOptions
  );
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] =
    useStreamer(platform, launchRequest, streamerOptions);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(0);
  const [queuedInitialize, setQueueInitialize] = useState(false);
  const user = useUser();
  const setGuideImage = useSetGuideImage();

  const productLevel = [
    "School",
    "Restaurant",
    "Lobby",
    "Boardroom",
    "Hospital",
    "Custom",
  ];
  const productType = [
    "Canopy Island",
    "Canopy Circle Island",
    "Canopy Hexagon Island",
    "Baffle Island",
    "Baffle Shapes",
    "Canopy Nexus",
    "Baffle Nexus",
  ];

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
    if (streamerStatus === StreamerStatus.Connected && emitter) {
      console.log("CONNECTED");
      if (queuedInitialize) {
        setTimeout(() => {
          inizialize();
        }, 500);
      }
    }
  }, [streamerStatus, emitter]);

  function inizialize() {
    if (!queuedInitialize) return;
    console.log("INTITIALIZING");
    if (
      params.get("selectedUser") != null &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admin"
      )
    ) {
      emitter.EmitUIInteraction({
        messageType: "Login",
        UserID: params.get("selectedUser"),
      });

      var message = { messageType: "Load", Name: params.get("id") };
      emitter.EmitUIInteraction(message);
      parametricData.Admin = true;
      setParaData({ ...paraData, Admin: true });
    } else if (paraData.Loading) {
      emitter.EmitUIInteraction({
        messageType: "Login",
        UserID: user.username,
      });

      var message = { messageType: "Load", Name: paraData.LoadName };
      emitter.EmitUIInteraction(message);
    } else {
      let message: any = {
        MessageType: "Initialize",
        level: paraData.Level,

        user: user ? user.username : "",
        type: paraData.Type,
        BaffleMatrix: paraData.bMatrixCanopyOrBaffles,
      };
      if (loadingShared) {
        message.initialData = JSON.stringify(paraData);
      }
      emitter.EmitUIInteraction(message);
      if (
        window.location.hostname !== "localhost" &&
        window.location.hostname !== "127.0.0.1" &&
        window.location.hostname !== ""
      ) {
        try {
          Analytics.record({
            name: "productSelections",
            immediate: true,
            attributes: {
              scene: productLevel[paraData.Level],
              raft: productType[paraData.Type],
              group: getUserGroup(user),
              account: user !== null ? user.attributes.email : "NONE",
            },
          });
          ReactGA.event("productSelections", {
            scene: productLevel[paraData.Level],
            raft: productType[paraData.Type],
          });
          console.log("recorded scene and raft type to analytics...");
        } catch (err) {
          console.log("error scene and raft record: " + err);
        }
      }
    }
  }

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  const launch = async () => {
    setLoading(true);
    if (streamLoaded) return;
    // console.log(modelDefinition);

    audio.load();
    console.log(paraData.Type);
    if (streamerStatus !== StreamerStatus.Connected || emitter == null) {
      setQueueInitialize(true);
    } else if (streamerStatus === StreamerStatus.Connected && emitter) {
      let message: any = {
        MessageType: "Initialize",
        level: paraData.Level,

        user: user ? user.username : "",
        type: paraData.Type,
        BaffleMatrix: paraData.bMatrixCanopyOrBaffles,
      };

      emitter.EmitUIInteraction(message);
    }
    if (clientOptions.LaunchType !== "local") {
      try {
        await queueLaunchRequest();
      } catch (err) {
        setLaunchRequestError(err);
      }
    }

    // else {
    //   emitter.EmitUIInteraction({
    //     MessageType: "Initialize",
    //     level: paraData.Level,
    //     user: user ? user.username : "",
    //     type: paraData.Type,
    //     BaffleMatrix: paraData.bMatrixCanopyOrBaffles,
    //   });
    // }
  };

  function setObjectKeys(obj: any, parametricData: any | undefined) {
    var output = { ...parametricData };
    console.log(output);
    for (var i in obj) {
      let key =
        i.charAt(0) !== i.charAt(0).toUpperCase() &&
        i.charAt(1) !== i.charAt(1).toUpperCase()
          ? i.charAt(0).toUpperCase() + i.slice(1)
          : i;
      if (Object.prototype.toString.apply(obj[i]) === "[object Object]") {
        output[key] = setObjectKeys(obj[i], null);
      } else {
        output[key] = obj[i];
      }
    }
    return output;
  }

  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value) => {
        const message = JSON.parse(value);

        if (message.messageType === "initialized") {
          // emitter.EmitUIInteraction({
          //   MessageType: "Initialize",
          //   level: paraData.Level,
          //   user: user ? user.username : "",
          //   type: paraData.Type,
          //   BaffleMatrix: paraData.bMatrixCanopyOrBaffles,
          // });
        } else if (message.messageType === "loaded") {
          setStreamLoaded(true);
        } else if (message.messageType === "SetState") {
          console.log(message.values);
          console.log("SETTING STATE");
          let tempData = setObjectKeys(message.values, paraData);

          tempData.Level = tempData.LevelIndex;

          setParaData(tempData);
        } else if (message.messageType === "screenShot") {
          fetch(
            "https://zentia-screenshots.s3.eu-west-2.amazonaws.com/" +
              message.name,
            {
              method: "GET",
              headers: {},
            }
          )
            .then((response) => {
              response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                console.log("menu index is " + menuIndex);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Screenshot.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
                setGuideImage(url);
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      (err) => {
        console.log(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, paraData]);

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus);
  }, [status, streamerStatus]);

  const setSaves = UseSetSaves();

  async function onSignIn(user: CognitoUser.CognitoUser) {
    try {
      const authString = user
        .getSignInUserSession()
        ?.getIdToken()
        .getJwtToken();

      const headers = {
        Authorization: authString ? authString : "",
      };

      if (config.isProd || config.testEmarsys) {
        const emarsysData = await axios.post(
          "https://mu0q7g8j95.execute-api.eu-west-2.amazonaws.com/Prod/emarysuser",
          {
            firstName: user.attributes["name"].split(" ")[0],
            lastName: user.attributes["name"].split(" ")[1],
            email: user.attributes["email"],
            company: user.attributes["custom:Company"],
            optIn: user.attributes["custom:gdpr"] === 1,
            newsLetter: user.attributes["custom:MailList"] === 1,
          },
          { headers }
        );
      }

      // Get the saved projects of the signed in user
      await axios
        .get(
          "https://mu0q7g8j95.execute-api.eu-west-2.amazonaws.com/Prod/getConfigs?id=" +
            user.getUsername(),
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          setSaves([...response.data]);
        })
        .catch((e) => {
          setSaves([]);
        });
    } catch (e) {
      console.error(e);
    }
  }

  const setUser = useSetUser();
  const logger = new Logger("logger");
  const listener = (data: HubCapsule) => {
    console.log(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        if (data.payload.data) {
          setUser(data.payload.data);
          onSignIn(data.payload.data);
          ReactGA.set({
            userId: data.payload.data.username,
          });
          ReactGA.gtag("set", "user_properties", {
            group: getUserGroup(data.payload.data),
          });
        }
        break;
      case "signUp":
        console.log(data.payload.data);
        setUser(null);
        break;
      case "signOut":
        logger.info("user signed out");
        setUser(null);
        ReactGA.set({
          userId: null,
        });
        ReactGA.gtag("set", "user_properties", {
          group: getUserGroup(null),
        });
        break;
      case "signIn_failure":
        logger.error("user sign in failed");
        break;
      case "tokenRefresh":
        console.log("hiiii");
        break;
      case "tokenRefresh_failure":
        logger.error("token refresh failed");
        break;
      case "configured":
        console.log("hiiii");
    }
  };
  const [authLoading, setAuthLoading] = useState(true);

  let nextToken: any;
  const [searchParams, setSearchParams] = useSearchParams();
  const setRaftData = UseSetRaftCardsContext();
  useEffect(() => {
    Hub.listen("auth", listener);
    const getLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          await onSignIn(user);
          setUser({ ...user });
          console.log(user);
          ReactGA.initialize("G-92J6G41CQ8", {
            gaOptions: {
              userId: user.username,
            },
          });
          ReactGA.gtag("set", "user_properties", {
            group: getUserGroup(user),
          });
          if (
            window.location.hostname !== "localhost" &&
            window.location.hostname !== "127.0.0.1" &&
            window.location.hostname !== ""
          ) {
            recordUser(user);
          }
        } else {
          console.log("NOOOOO USER");
          ReactGA.initialize("G-92J6G41CQ8");
          ReactGA.gtag("set", "user_properties", {
            group: getUserGroup(null),
          });
          if (
            window.location.hostname !== "localhost" &&
            window.location.hostname !== "127.0.0.1" &&
            window.location.hostname !== ""
          ) {
            recordUser(null);
          }
        }

        setAuthLoading(false);
      } catch (e) {
        console.log("NOOOOO USER");
        ReactGA.initialize("G-92J6G41CQ8");
        ReactGA.gtag("set", "user_properties", {
          group: getUserGroup(null),
        });
        if (
          window.location.hostname !== "localhost" &&
          window.location.hostname !== "127.0.0.1" &&
          window.location.hostname !== ""
        ) {
          recordUser(null);
        }
        console.error(e);
        setAuthLoading(false);
      }
    };
    getLoggedIn();

    const getUserType = (localUser: any) => {
      let result = "";
      if (localUser === null) {
        result = "NoAccount";
      } else {
        if (localUser.attributes.company === "zentia") {
          result = "InternalAccount";
        } else {
          result = localUser.attributes.email;
        }
      }

      return result;
    };

    const recordUser = (localUser: any) => {
      try {
        Analytics.record({
          name: "sessionStart",
          immediate: true,
          attributes: {
            group: getUserGroup(localUser),
            account: localUser ? localUser.attributes.email : "NO EMAIL",
          },
        });
        console.log("triggered recording.." + getUserGroup(localUser));
      } catch (error) {
        console.error("Error recording event:", error);
      }
    };

    // return function cleanup() {
    //   Hub.remove("auth", listener);
    // };
  }, []);

  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>
          Your client has one or more configuration errors. Please consult the{" "}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client">
            {" "}
            README{" "}
          </a>{" "}
          for details on how to configure the client template.
        </p>
      </div>
    );
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>The model that you have requested does not exist</span>
      </div>
    );
  }

  if (launchRequestError) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>
          {process.env.NODE_ENV === "development"
            ? `There was an error with the launch request: ${launchRequestError}`
            : "It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available."}
        </span>
      </div>
    );
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Disconnected from stream</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Failure during stream</h2>
        <h2>Please refresh to request a new session</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Streamer contribution withdrawn</h2>
      </div>
    );
  }

  function reset() {
    setRaftData(null, -1);
    setParaData(parametricData);
    setLoadingShared(false);
    setLoading(false);
    searchParams.delete("data");
    searchParams.delete("selectedUser");
    searchParams.delete("id");
    parametricData.Admin = false;
    setParaData({ ...paraData, Admin: false });
    setSearchParams(searchParams);
  }

  if (loading) {
    return (
      <EmbeddedView
        MessageSubject={messageSubject}
        VideoStream={videoStream}
        StreamerStatus={streamerStatus as StreamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
        UsePointerLock={clientOptions.UsePointerLock!}
        PointerLockRelease={clientOptions.PointerLockRelease!}
        StreamLoaded={streamLoaded}
        Reset={reset}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
    );
  } else if (clientOptions.LaunchType !== "local" && !availableModels) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Initializing...</h2>
      </div>
    );
  } else if (clientOptions.LaunchType !== "local" && !availableModels?.length) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>No models are currently available in this environment.</h2>
      </div>
    );
  } else if (!authLoading) {
    //return <LaunchView Launch={launch} />;

    return <JourneyModals launch={launch} inputEmitter={emitter} />;
  } else {
    return (
      <>
        <Loader />
      </>
    );
  }
};

const AppWrapper: React.FC = () => {
  return System.IsBrowserSupported() ? (
    <Router>
      <Routes>
        <Route path="/admin" element={<AdminDashboard />} />

        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  );
};

export default AppWrapper;
