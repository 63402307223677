import {
  Document,
  Page,
  View,
  Image,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";

import sonifyLogo from "../assets/sonifyLogoBlackNew.png";
import zentiaLogo from "../assets/zentiaLogoBlack.png";

const ExportGuideDoc = ({ guideImage, raftImage, boqSheet }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.logos}>
          <Image src={sonifyLogo} style={styles.sonifyLogo} />
          <Image src={zentiaLogo} style={styles.zentiaLogo} />
        </View>
        <View style={styles.guideImg}>
          <Image src={guideImage} style={styles.img} />
        </View>
        <View>
          <Image src={raftImage} style={styles.img} />
        </View>
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
            Please read this install guide in conjunction with the standard
            Sonify Installation guides and datasheets, which can be found on the
            Zentia website - www.zentia.com/en-gb/sonify. For additional
            support, contact your local Area Sales Manager, or visit our
            ‘Contact Us’ page on the Zentia website.
          </Text>
        </View>
      </Page>
      <Page style={styles.page}>
        <View>
          <BoQTable boqSheet={boqSheet} />
        </View>
      </Page>
    </Document>
  );
};

const BoQTable = ({ boqSheet }) => {
  return (
    <View style={styles.table}>
      {boqSheet.map((data, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {data.map((cell, cellIndex) => (
            <Text
              key={cellIndex}
              style={[
                styles.tableCell,
                cellIndex === 0 && styles.indexColumn,
                cellIndex === 1 && styles.codeColumn,
                cellIndex === 2 && styles.descriptionColumn,
                cellIndex === 3 && styles.quantityColumn,
                {
                  color:
                    rowIndex >= 3 && cellIndex === 0
                      ? /grid/i.test(data[2])
                        ? "red"
                        : "cyan"
                      : "black",
                },
              ]}
            >
              {cellIndex === 0 && cell === 0 ? "" : cell}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
    width: "80%",
  },
  sonifyLogo: {
    width: "170px",
    height: "70px",
  },
  zentiaLogo: {
    width: "160px",
    height: "60px",
  },
  guideImg: {
    marginBottom: 20,
  },
  img: {
    height: "260px",
  },
  text: {
    fontSize: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "80%",
    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    fontSize: 10,
  },
  footerContainer: {
    textAlign: "center",
    padding: 70,
  },
  footerText: {
    fontSize: 10,
    fontStyle: "italic",
    color: "gray",
  },
  indexColumn: {
    width: "10%",
  },
  codeColumn: {
    width: "25%",
  },
  descriptionColumn: {
    width: "50%",
  },
  quantityColumn: {
    width: "15%",
  },
});

export default ExportGuideDoc;
