import React from "react";

export default function MessageButton({
  componentData,
  inputEmitter,
  onClick,
  disabled,
  clickEvent,
  hidden,
  overrideClass,
}) {
  function sendMessage() {
    clickEvent && clickEvent();
    inputEmitter.EmitUIInteraction(componentData.Message);
  }

  return (
    <button
      className={overrideClass ? overrideClass : "button"}
      onClick={componentData.DisplayName !== "New Raft" ? sendMessage : onClick}
      disabled={disabled}
      hidden={hidden}
    >
      {componentData.DisplayName}
    </button>
  );
}
