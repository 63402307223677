import React, { useState } from "react";
import Modal from "react-modal";

import button1Off from "../assets/icons/schoolInactive.png";
import button2Off from "../assets/icons/restaurantInactive.png";
import button3Off from "../assets/icons/hospitalInactive.png";
import button4Off from "../assets/icons/boardroomInactive.png";
import button5Off from "../assets/icons/lobbyInactive.png";
import customRoom from "../assets/icons/customRoom.png";
import blankRoom from "../assets/icons/blankRoom.png";

import {
  StyledLookContainer,
  StyledOverallContainer,
  StyledLabels,
  StyledReferenceLabel,
} from "../styles/SceneModal.styled";

import "../assets/css/modal.css";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";

Modal.setAppElement("#root");

const SceneModal = (props) => {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const [item, setItem] = useState(0);

  function onClickSelection(index) {
    console.log("CLICKED SCENE");
    setItem(index);
    setParametricData({ ...parametricData, Level: index });
  }

  const SelectionData = [
    {
      id: 0,
      name: "School",
      image: button1Off,
      measurements: "20m x 34m x 9m",
      wallAbsorberCompatible: false,
    },
    {
      id: 1,
      name: "Restaurant",
      image: button2Off,
      measurements: "15m x 14m x 4.5m",
      wallAbsorberCompatible: false,
    },
    {
      id: 2,
      name: "Lobby",
      image: button5Off,
      measurements: "12m x 9.2m x 4.8m",
      wallAbsorberCompatible: false,
    },
    {
      id: 3,
      name: "Boardroom",
      image: button4Off,
      measurements: "5.2m x 11m x 3.6m",
      wallAbsorberCompatible: false,
    },
    {
      id: 4,
      name: "Hospital",
      image: button3Off,
      measurements: "16m x 11m x 8m",
      wallAbsorberCompatible: false,
    },
    {
      id: 6,
      name: "Wall",
      image: blankRoom,
      measurements: "15m x 15m x 5m",
      wallAbsorberCompatible: true,
    },
    {
      id: 5,
      name: "Custom",
      image: customRoom,
      measurements: "Sketch your room",
      wallAbsorberCompatible: true,
    },
  ];

  return (
    <Modal
      appElement={document.getElementById("ui-parent")}
      style={{
        content: {
          maxWidth: "1100px",
          maxHeight: "100%",
          background: "#fff",
          overflow: "auto",
          outline: "none",
          padding: "20px",
        },
      }}
      isOpen={true}
      onRequestClose={() => {}}
      className="modalMain"
      overlayClassName="modalOverlay"
    >
      <StyledOverallContainer>
        <h2>Select the Sonify ceiling solution you would like to configure</h2>
        <hr />
        <StyledLookContainer>
          {SelectionData.map((selection) => {
            return (
              <div
                class="img-wrap"
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                onKeyUp={() => {}}
                onClick={() => onClickSelection(selection.id)}
                role="button"
                tabIndex={0}
              >
                {item === selection.id && <div class="tick" />}
                <img
                  class="img-inner scene"
                  src={selection.image}
                  style={
                    props.height && { height: props.height, width: props.width }
                  }
                  alt={selection.name}
                />
                <StyledLabels>
                  <div style={{ fontSize: "14px" }}>
                    <span>{selection.name}</span>{" "}
                    {selection.wallAbsorberCompatible && <sup>[1]</sup>}
                  </div>
                  <div>{selection.measurements}</div>
                </StyledLabels>
              </div>
            );
          })}
        </StyledLookContainer>
        <div>
          <br />
          <br />
          <img alt="Information" className="infoImage" />
          Select the scene that matches closest to your project
        </div>
        <StyledReferenceLabel>
          <p>[1] - Compatible with Wall Absorber</p>
        </StyledReferenceLabel>
        <hr />
        <br />
        <br />
        <div className="flexContainer">
          <button className="modal-button back" onClick={props.onBack}>
            Back to tour
          </button>
          <button className="modal-button " onClick={() => props.next()}>
            <span>Next</span>
          </button>
        </div>
      </StyledOverallContainer>
    </Modal>
  );
};

export default SceneModal;
