import React, { useEffect } from "react";
import Modal from "react-modal";

import {
  AiOutlineDownload,
  AiOutlineFolderOpen,
  AiOutlineFileDone,
} from "react-icons/ai";
import { BsPuzzle } from "react-icons/bs";

const customStyles = {
  content: {
    width: "40%",
    height: "auto",
    top: "50%",
    left: "40%",
    bottom: "auto",
    transform: "translate(-30%, -50%)",
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement("#root");

export default function DownloadModal(props) {
  return (
    <Modal isOpen={true} style={customStyles} contentLabel={props.label}>
      <h2>Revit Download</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 0.5,
          }}
          id="pluginDownload"
          className="revit-download-section"
        >
          <BsPuzzle
            alt="config"
            size={30}
            style={{ display: "block" }}
            color={"#212121"}
          />
          <div
            className="download-modal-button"
            style={{ height: "2rem", width: "100%", border: "0px" }}
          >
            Download Plugin & Families
          </div>
          <p className="modal-subtext">
            Download the latest installer for the Revit plugin.
          </p>
          <a
            className="download-modal-button"
            style={{ border: "0px", width: "auto" }}
            href="/Revit/SonifyRevitPlugin.zip"
            download={true}
          >
            <AiOutlineDownload
              alt="config"
              size={30}
              style={{ display: "block" }}
              color={"#212121"}
            />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: "1px solid #d8d8d8",
            justifyContent: "flex-end",
            flex: 0.5,
          }}
          className="revit-download-section"
        >
          <AiOutlineFileDone
            alt="config"
            size={30}
            style={{ display: "block" }}
            color={"#212121"}
          />
          <div
            className="download-modal-button"
            style={{ border: "0px", width: "auto", height: "2rem" }}
          >
            Export File
          </div>
          <p className="modal-subtext">
            Export current project settings as a .sonify file that can be used
            with the Revit plugin.
          </p>
          <div
            className="download-modal-button"
            style={{ border: "0px", width: "auto" }}
          >
            <AiOutlineDownload
              alt="config"
              size={30}
              style={{ display: "block" }}
              color={"#212121"}
              onClick={props.onDownload}
            />
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.4,
          }}
          className="revit-download-section"
        >
          <AiOutlineFolderOpen
            alt="config"
            size={30}
            style={{ display: "block" }}
            color={"#212121"}
          />
          <div
            className="download-modal-button"
            style={{ height: "2rem", width: "100%", border: "0px" }}
          >
            Download Revit Families
          </div>
          <p className="modal-subtext">
            Download the Revit families used by the Revit plugin.
          </p>
          <a
            className="download-modal-button"
            href="/Revit/SonifyFamilies.zip"
            download={true}
            style={{ border: "0px", width: "auto" }}
          >
            <AiOutlineDownload
              alt="config"
              size={30}
              style={{ display: "block" }}
              color={"#212121"}
            />
          </a>
        </div> */}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="modal-button"
          onClick={props.onClose}
          style={{ width: "25%" }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
