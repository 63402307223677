import { API, Auth } from "aws-amplify";
import { object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "semantic-ui-react";
import { useUser } from "../hooks/UseContext";
import AdminUserTable from "./AdminUserTable";
import SelectedUserAdminPanel from "./SelectedUserAdminPanel";

function AdminDashboard(props) {
  const user = useUser();
  const [authUser, setAuthUser] = useState();
  const [token, setToken] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState(null);
  const navigation = useNavigate();
  const [searchState, setSearchState] = useState({
    searchText: "",
    searchedUser: null,
  });

  async function listUsers(limit, inputToken = token?.next) {
    let apiName = "AdminQueries";
    let path = "/listUsers";
    let myInit = {
      queryStringParameters: {
        limit: limit,
        token: inputToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    try {
      const { NextToken, ...rest } = await API.get(apiName, path, myInit);
      const newToken = {
        previous: token === undefined ? null : token,
        next: NextToken === undefined ? null : NextToken,
      };
      setToken(newToken);
      console.log(newToken);

      return rest;
    } catch (e) {
      navigation("/");
    }
  }
  async function getUsers() {
    const tempUser = await listUsers(15);
    console.log(tempUser);
    setUsers(tempUser);
  }

  async function getPreviousUsers() {
    const tempUser = await listUsers(15, token?.previous);
    console.log(tempUser);
    setUsers(tempUser);
  }
  useEffect(async () => {
    const getLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthUser(user);
        console.log(user);
        if (user) {
          console.log("setting user");
          await getUsers();
          console.log(users);
        } else {
          console.log("noUser");
          navigation("/");
        }
      } catch (e) {
        console.error(e);
        navigation("/");
      }
    };
    await getLoggedIn();
  }, []);

  function getAttributeByName(attributes, name) {
    let attr = "";
    attributes.forEach((element) => {
      if (element.Name === name) {
        attr = element.Value;
      }
    });
    return attr;
  }

  async function searchForUser() {
    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      queryStringParameters: {
        username: searchState.searchText,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    try {
      const foundUser = await API.get(apiName, path, myInit);

      foundUser.Attributes = foundUser.UserAttributes;
      delete foundUser.UserAttributes;
      console.log(foundUser);
      setSearchState({ ...searchState, searchedUser: [foundUser] });
    } catch (e) {}
  }
  function emailCell({ value, row }) {
    return (
      <button
        style={{
          border: "none",
          color: "blue",
          background: "transparent",
          cursor: "pointer",
        }}
        className="email"
        onClick={() =>
          setSelectedUser(
            searchState.searchedUser
              ? searchState.searchedUser[0]
              : users.Users[row.index]
          )
        }
      >
        {value}
      </button>
    );
  }

  function renderPanels() {
    return selectedUser === null ? (
      <div
        style={{
          overflowY: "scroll",
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "100%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Admin Dashboard</h1>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <div
            style={{ display: "flex", width: "80%", justifyContent: "right" }}
          >
            <input
              placeholder="search..."
              type={"text"}
              value={searchState.searchText}
              onChange={(e) => {
                setSearchState({ ...searchState, searchText: e.target.value });
              }}
              onKeyDown={async (e) => {
                if (e.key !== "Enter") return;
                await searchForUser();
              }}
            />
            <button
              className="button"
              style={{ width: 80, padding: 0, margin: 0 }}
              onClick={async () => {
                if (searchState.searchedUser)
                  setSearchState({ searchText: "", searchedUser: null });
                else await searchForUser();
              }}
            >
              {searchState.searchedUser ? "clear" : "search"}
            </button>
          </div>
        </div>
        <AdminUserTable
          data={
            searchState.searchedUser ? searchState.searchedUser : users.Users
          }
          columns={[
            {
              Header: "Email",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "email"
                );
              },
              Cell: emailCell,
            },
            {
              Header: "Name",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "name"
                );
              },
            },
            {
              Header: "Status",
              accessor: "UserStatus",
            },
            {
              Header: "Company",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "custom:Company"
                );
              },
            },
            {
              Header: "Mail List",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "custom:MailList"
                ) == 1
                  ? "True"
                  : "False";
              },
            },
            {
              Header: "GDPR",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "custom:gdpr"
                ) == 1
                  ? "True"
                  : "False";
              },
            },
            {
              Header: "Project Count",
              accessor: (data) => {
                return getAttributeByName(
                  data.Attributes ? data.Attributes : data.UserAttributes,
                  "custom:Configs"
                );
              },
            },
            {
              Header: "Creation Date",
              accessor: (data) => {
                return new Date(data.UserCreateDate).toDateString();
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            disabled={(token.previous === null) | undefined}
            style={{ width: 100 }}
            className="button"
            onClick={async () => {
              getPreviousUsers();
            }}
          >
            prev
          </button>
          <button
            disabled={token.next === null}
            style={{ width: 100 }}
            className="button"
            onClick={async () => {
              getUsers();
            }}
          >
            next
          </button>
        </div>
      </div>
    ) : (
      <SelectedUserAdminPanel
        onBack={() => setSelectedUser(null)}
        user={selectedUser}
        signedInUser={user}
      />
    );
  }
  return users !== null && <div>{renderPanels()}</div>;
}

export default AdminDashboard;
