import { PDFDownloadLink } from "@react-pdf/renderer";
import styled, { css } from "styled-components";

export const StyledCard = styled.div`
  margin: 10px;
  padding: 10px 10px 15px 10px;
  border-radius: 2px;
  border: ${({ isSelected }) =>
    isSelected ? "2px solid #3cdbc0" : "2px solid #c5c5c5"};
  transition: all 0.4s ease-in-out;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #fff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    `}
`;

export const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBodySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
`;

export const StyledRaftButton = styled.button`
  padding: 10px 0;
  position: relative;
  background-color: #fff;
  border: ${({ selected }) =>
    selected ? "2px solid #3cdbc0" : "2px solid #C5C5C5"};
  border-radius: 2px;
  height: auto;
  width: 47%;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4 ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

export const StyledActionButton = styled.button`
  text-align: center;

  background-color: ${({ isMoving }) => (isMoving ? "#3cdbc0" : "#fff")};
  box-shadow: ${({ isMoving }) => isMoving && "0px 4px 6px rgba(0, 0, 0, 0.3)"};
  border: 2px solid #3cdbc0;
  border-radius: 2px;
  height: 35px;
  width: 47%;
  padding-top: 4px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4 ease-in-out;

  ${({ action }) =>
    (action === "boq" || action === "pdf") &&
    css`
      margin-top: 15px;
      width: 100%;
    `}
`;

export const StyledSelectBox = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isChecked }) => isChecked && "#3cdbc0"};

  ${({ isChecked }) =>
    !isChecked &&
    css`
      cursor: pointer;
      border: 1px solid black;
    `}
`;

export const StyledTypeSelectTick = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isChecked }) => isChecked && "#3cdbc0"};

  ${({ isChecked }) =>
    !isChecked &&
    css`
      cursor: pointer;
      border: 1px solid black;
    `}
`;

export const StyledDownloadLink = styled(PDFDownloadLink)`
  margin: 0px auto;
  width: 70%;
`;

export const StyledSpinner = styled.span`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #000 #000 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #3cdbc0 #3cdbc0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  &::before {
    width: 32px;
    height: 32px;
    border-color: #000 #000 transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;
