import React from "react";
import MessageButton from "../MessageButton";
import { StyledPageButtons } from "../../styles/ConstructionGuide.styled";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ExportGuideDoc from "../../docs/ExportGuideDoc";

const PageButtons = ({
  menuStage,
  inputEmitter,
  guideImage,
  raftImage,
  boqSheet,
  selectedRaftIndex,
}) => {
  const exportData = {
    DisplayName: "Export Construction Guide",
    Message: {
      messageType: "ExportGuide",
    },
  };

  return (
    <StyledPageButtons>
      <PDFDownloadLink
        document={
          <ExportGuideDoc
            guideImage={guideImage}
            raftImage={raftImage}
            boqSheet={boqSheet}
          />
        }
        fileName="Export Testing"
      >
        {({ loading }) =>
          loading ? (
            <MessageButton
              inputEmitter={inputEmitter}
              componentData={exportData}
              overrideClass="con-page-button"
              disabled={true}
            />
          ) : (
            <MessageButton
              inputEmitter={inputEmitter}
              componentData={exportData}
              overrideClass="con-page-button"
              disabled={menuStage !== 2}
            />
          )
        }
      </PDFDownloadLink>
      {/* <MessageButton
        inputEmitter={inputEmitter}
        componentData={exportData}
        overrideClass="con-page-button"
        disabled={menuStage !== 2}
      /> */}
      {/* <MessageButton
        inputEmitter={inputEmitter}
        componentData={backData}
        overrideClass="con-page-button"
        clickEvent={() => {
          setMenuIndex(-1);
         setGuideImage(""); 
        }}
      /> */}
    </StyledPageButtons>
  );
};

export default PageButtons;
