import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import Tick from "../assets/icons/tick.svg";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import ColourPickerComponent from "./ColourPickerComponent";
import PalleteDisplayComponent from "./PalleteDisplayComponent";
import PalleteEditorComponent from "./PalleteEditorComponent";
import { coloursObject, findColour } from "../globals/GlobalColours";
const palletes = [
  { name: "Palette 1", colours: [2, 10, 18] },
  { name: "Palette 2", colours: [1, 9, 17] },
  { name: "Palette 3", colours: [0, 8, 16] },
  { name: "Palette 4", colours: [4, 12, 36] },
  { name: "Palette 5", colours: [5, 13, 21] },
  { name: "Palette 6", colours: [0, 16, 11] },
];
function ColourMenu(props) {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();

  const [palleteNumber, setPalleteNumber] = useState(0);
  const [colourNumber, setColourNumber] = useState(
    parametricData.Colours.ThirdColourPercentage > 0
      ? 3
      : parametricData.Colours.SecondColourPercentage > 0
      ? 2
      : 1
  );
  const [item, setItem] = useState([
    findColour(parametricData.Colours.MainColour, 3),
    findColour(parametricData.Colours.SecondColour, 3),
    findColour(parametricData.Colours.ThirdColour, 3),
  ]);
  const [picking, setPicking] = useState(false);
  const [colourIndex, setColourIndex] = useState(0);

  const onSelectPallete = (palleteIndex) => {
    console.log(picking);
    if (palleteIndex === palleteNumber) {
      console.log(picking);
      setPicking(false);
      return;
    }
    var message = { messageType: "SetValue", PaletteIndex: palleteIndex };
    message["Colours"] = {
      mainColour: {
        r: coloursObject[palletes[palleteIndex].colours[0]].r,
        g: coloursObject[palletes[palleteIndex].colours[0]].g,
        b: coloursObject[palletes[palleteIndex].colours[0]].b,
        a: 255,
      },
      secondColour: {
        r: coloursObject[palletes[palleteIndex].colours[1]].r,
        g: coloursObject[palletes[palleteIndex].colours[1]].g,
        b: coloursObject[palletes[palleteIndex].colours[1]].b,
        a: 255,
      },
      thirdColour: {
        r: coloursObject[palletes[palleteIndex].colours[2]].r,
        g: coloursObject[palletes[palleteIndex].colours[2]].g,
        b: coloursObject[palletes[palleteIndex].colours[2]].b,
        a: 255,
      },
      mainColourPercentage: 100,
      secondColourPercentage: 100,
      thirdColourPercentage: colourNumber === 2 ? 0 : 100,
    };
    parametricData.Colours.MainColour = {
      r: coloursObject[palletes[palleteIndex].colours[0]].r,
      g: coloursObject[palletes[palleteIndex].colours[0]].g,
      b: coloursObject[palletes[palleteIndex].colours[0]].b,
      a: 255,
    };

    parametricData.Colours.SecondColour = {
      r: coloursObject[palletes[palleteIndex].colours[1]].r,
      g: coloursObject[palletes[palleteIndex].colours[1]].g,
      b: coloursObject[palletes[palleteIndex].colours[1]].b,
      a: 255,
    };
    parametricData.Colours.ThirdColour = {
      r: coloursObject[palletes[palleteIndex].colours[2]].r,
      g: coloursObject[palletes[palleteIndex].colours[2]].g,
      b: coloursObject[palletes[palleteIndex].colours[2]].b,
      a: 255,
    };
    parametricData.Colours.MainColourPercentage = 100;
    parametricData.Colours.SecondColourPercentage = 100;
    parametricData.Colours.ThirdColourPercentage = colourNumber === 2 ? 0 : 100;
    parametricData.PaletteIndex = palleteIndex;
    parametricData.ColourIndex = -1;
    props.inputEmitter.EmitUIInteraction(message);
    setPicking(false);
    setPalleteNumber(palleteIndex);
    setParametricData({ ...parametricData });
    setItem([
      palletes[palleteIndex].colours[0],
      palletes[palleteIndex].colours[1],
      palletes[palleteIndex].colours[2],
    ]);
  };
  const toggleItem = (index) => {
    var items = { ...item };
    items[colourIndex] = index;
    setItem(items);
    var message = { messageType: "SetValue" };
    message["Colours"] = {};
    let key =
      colourIndex === 0
        ? "mainColour"
        : colourIndex === 1
        ? "secondColour"
        : "thirdColour";
    message.Colours[key] = {
      r: coloursObject[index].r,
      g: coloursObject[index].g,
      b: coloursObject[index].b,
      a: 255,
    };
    key =
      colourIndex === 0
        ? "MainColour"
        : colourIndex === 1
        ? "SecondColour"
        : "ThirdColour";
    console.log(key);
    parametricData.Colours[key] = {
      r: coloursObject[index].r,
      g: coloursObject[index].g,
      b: coloursObject[index].b,
      a: 255,
    };
    if (colourNumber == 1) {
      message.Colours.thirdColourPercentage = 0;
      parametricData.Colours.ThirdColourPercentage = 0;

      message.Colours.secondColourPercentage = 0;
      parametricData.Colours.SecondColourPercentage = 0;
      setPalleteNumber(-1);
    }

    parametricData.ColourIndex = index;
    parametricData.PaletteIndex = -1;
    setParametricData({ ...parametricData });
    props.inputEmitter.EmitUIInteraction(message);
  };
  useEffect(() => {
    console.log(parametricData.Colours);
    setColourNumber(
      parametricData.Colours.ThirdColourPercentage > 0
        ? 3
        : parametricData.Colours.SecondColourPercentage > 0
        ? 2
        : 1
    );
    setItem([
      findColour(parametricData.Colours.MainColour, 3),
      findColour(parametricData.Colours.SecondColour, 3),
      findColour(parametricData.Colours.ThirdColour, 3),
    ]);
  }, [parametricData.Colours]);
  const colourPicker = () => (
    <ColourPickerComponent
      coloursObject={coloursObject}
      onColourSelected={(index) => {
        setPicking(false);
        props.inputEmitter.EmitUIInteraction({
          messageType: "SetValue",
          PaletteIndex: index,
        });
        parametricData.ColourIndex = index;
        toggleItem(index);
      }}
      selectedIndex={item[colourIndex]}
    />
  );

  const singleView = () => (
    <div>
      <div>Choose the colour of the overall ceiling</div>
      <div
        style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
      >
        <div
          onClick={() => {
            setColourIndex(0);
            setPicking(true);
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
          style={{
            marginRight: 10,
            cursor: "pointer",
            display: "inline-block",
            borderWidth: 2,
            borderColor: "#5EE1CB",
            borderStyle: "solid",
            height: 36,
            width: 26,
            background: `rgb(${coloursObject[item[0]].r}, ${
              coloursObject[item[0]].g
            },${coloursObject[item[0]].b})`,
          }}
        />
        <div>{coloursObject[item[0]].swatchName}</div>
        <img style={{ display: "inline-block" }} src={Tick} alt="tick" />
      </div>
    </div>
  );
  return (
    <div>
      <div>You can choose up to 3 colours for this ceiling type.</div>

      <div>
        <div className="colour-buttons-container">
          <button
            className={
              colourNumber === 1
                ? "colour-button colour-selected"
                : "colour-button"
            }
            onClick={() => {
              if (colourNumber !== 1) {
                var message = { messageType: "SetValue" };
                message["Colours"] = {
                  ScatteringType: 1,
                };
                props.inputEmitter.EmitUIInteraction(message);
                parametricData.PaletteIndex = -1;
                setColourNumber(1);
              }
              setColourIndex(0);
            }}
          >
            Single Colour
          </button>
          <button
            className={
              colourNumber === 2
                ? "colour-button colour-selected"
                : "colour-button"
            }
            onClick={() => {
              if (colourNumber !== 2) {
                var message = { messageType: "SetValue" };
                message["Colours"] = {
                  thirdColourPercentage: 0,
                };
                parametricData.ColourIndex = -1;
                parametricData.Colours.ThirdColourPercentage = 0;
                setParametricData({ ...parametricData });
                props.inputEmitter.EmitUIInteraction(message);
              }
              setColourNumber(2);
            }}
          >
            Two Colours
          </button>
          <button
            className={
              colourNumber === 3
                ? "colour-button colour-selected"
                : "colour-button"
            }
            onClick={() => {
              if (colourNumber !== 3) {
                var message = { messageType: "SetValue" };
                message["Colours"] = {
                  thirdColourPercentage: 100,
                };

                parametricData.ColourIndex = -1;
                parametricData.Colours.ThirdColourPercentage = 100;
                setParametricData({ ...parametricData });
                props.inputEmitter.EmitUIInteraction(message);
              }
              setColourNumber(3);
            }}
          >
            Three Colours
          </button>
        </div>
        {colourNumber === 1 &&
          (picking
            ? colourPicker()
            : item === -1
            ? colourPicker()
            : singleView())}
        {colourNumber > 1 &&
          (picking && palleteNumber !== -1 ? (
            colourPicker()
          ) : picking ? (
            <PalleteDisplayComponent
              selectedPallete={palleteNumber}
              onPalleteSelected={onSelectPallete}
              count={colourNumber}
              colours={coloursObject}
              palletes={palletes}
              items={item}
            />
          ) : palleteNumber === -1 ? (
            <PalleteDisplayComponent
              selectedPallete={palleteNumber}
              onPalleteSelected={onSelectPallete}
              count={colourNumber}
              colours={coloursObject}
              palletes={palletes}
              items={item}
            />
          ) : (
            <PalleteEditorComponent
              openSelector={(index) => {
                console.log(index);
                setColourIndex(index);
                setPicking(true);
              }}
              count={colourNumber}
              pallete={palletes[palleteNumber]}
              colours={coloursObject}
              items={item}
              inputEmitter={props.inputEmitter}
              clickPallete={() => {
                setPalleteNumber(-1);
                setPicking(true);
              }}
            />
          ))}
      </div>
    </div>
  );
}

export default ColourMenu;
