export function getUserGroup(user, log) {
  if (log) console.log(user.attributes["custom:Company"]);
  if (user === null) return "No Account";
  if (
    user.attributes["custom:Company"].toLowerCase().includes("zentia") ||
    user.attributes["custom:Company"].toLowerCase().includes("squareone")
  )
    return "Internal";
  return "External";
}
