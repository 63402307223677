import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Analytics } from "aws-amplify";
import "./fonts/Ubuntu-Regular.ttf";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { SavesDataProvider } from "./hooks/SavesHook";
import { ParametricDataProvider } from "./hooks/ParametricDataContext";
import { UserProvider } from "./hooks/UseContext";
import { setupConfig } from "./globals/Config";
import { RaftCardDataProvider } from "./hooks/RaftCardsContext";
import { GuideImageProvider } from "./hooks/GuideImageContext";
setupConfig();
Amplify.configure(awsExports);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
ReactDOM.render(
  <SavesDataProvider>
    <ParametricDataProvider>
      <UserProvider>
        <RaftCardDataProvider>
          <GuideImageProvider>
            <App />
          </GuideImageProvider>
        </RaftCardDataProvider>
      </UserProvider>
    </ParametricDataProvider>
  </SavesDataProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
