import React, { useEffect, useState } from "react";
import "../App.css";
import { useUser } from "../hooks/UseContext";
import configIcon from "../assets/icons/configIcon.svg";
import {
  AiOutlineAppstoreAdd,
  AiOutlineCamera,
  AiOutlineEdit,
} from "react-icons/ai";
import { BiFolder } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import { parametricData } from "../globals/GolbalConfig";
import { RiPencilRuler2Line, RiRuler2Line } from "react-icons/ri";

function RightMenu(props) {
  useEffect(() => {
    console.log("menu index is " + props.menuIndex);
  }, [props.menuIndex]);
  const user = useUser();
  const [guideIsOpen, setGuideIsOpen] = useState(false);

  function sendGuideClose() {
    console.log("guide closed");
    var message = { messageType: "CloseGuide" };
    props.inputEmitter.EmitUIInteraction(message);
    setGuideIsOpen(false);
  }

  function toggleMenu(menu) {
    if (guideIsOpen) sendGuideClose();
    props.onSelectMenu(props.menuIndex !== menu ? menu : -1);
  }

  return (
    <div className="rightMenu">
      <div className="rightMenuItems">
        {/* config settings tab */}
        <div
          className="img-wrap"
          onClick={() => {
            // if (guideIsOpen) sendGuideClose();
            // props.onSelectMenu(props.menuIndex !== 0 ? 0 : -1);
            toggleMenu(0);
          }}
          tabIndex={0}
          role={"button"}
          onKeyUp={() => {}}
        >
          <GoSettings
            src={configIcon}
            alt="config"
            size={30}
            style={{ display: "block" }}
            color={"#212121"}
          />
        </div>

        {/* saved configs tab */}
        {!parametricData.Admin && (
          <div
            className="img-wrap"
            onClick={() => {
              // if (guideIsOpen) sendGuideClose();
              // props.onSelectMenu(props.menuIndex !== 1 ? 1 : -1);
              toggleMenu(1);
            }}
            tabIndex={0}
            role={"button"}
            onKeyUp={() => {}}
          >
            <BiFolder
              alt="config"
              size={30}
              style={{ display: "block" }}
              color={"#212121"}
            />
          </div>
        )}

        {/* rafts tab */}
        <div
          className="img-wrap"
          onClick={() => {
            // if (guideIsOpen) sendGuideClose();
            // props.onSelectMenu(props.menuIndex !== 2 ? 2 : -1);
            toggleMenu(2);
          }}
          tabIndex={0}
          role={"button"}
          onKeyUp={() => {}}
        >
          <AiOutlineAppstoreAdd
            color="#212121"
            size={30}
            style={{ display: "block" }}
          />
        </div>

        {/* single asset edit tab */}
        <div
          className="img-wrap"
          onClick={() => {
            // if (guideIsOpen) sendGuideClose();
            // props.onSelectMenu(props.menuIndex !== 3 ? 3 : -1);
            toggleMenu(3);
          }}
          tabIndex={0}
          role={"button"}
          onKeyUp={() => {}}
        >
          <AiOutlineEdit
            color="#212121"
            size={30}
            style={{ display: "block" }}
          />
        </div>

        {/* ikea testing feature tab */}
        {user &&
          user.signInUserSession.accessToken.payload["cognito:groups"] &&
          user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "Admin"
          ) && (
            <div
              className="img-wrap"
              onClick={() => {
                // if (guideIsOpen) sendGuideClose();
                // props.onSelectMenu(props.menuIndex !== 4 ? 4 : -1);
                toggleMenu(4);
                if (props.menuIndex !== 4) setGuideIsOpen(true);
              }}
              tabIndex={0}
              role={"button"}
              onKeyUp={() => {}}
            >
              <RiRuler2Line
                color="#212121"
                size={30}
                style={{ display: "block" }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default RightMenu;
