import React, { useEffect } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { BiMove } from "react-icons/bi";
import {
  BoxButtons,
  BoxContainer,
  StyledBoxHeader,
  StyledLoadingSpinner,
} from "../../styles/ConstructionGuide.styled";
import MessageButton from "../MessageButton";
import PlaceholderImage from "../../assets/img-placeholder.png";

const StageBox = ({
  enabled,
  title,
  inputEmitter,
  menuStage,
  clickEvent,
  image,
  isLoading,
  setIsLoading,
}) => {
  let disabledBox = !enabled;

  const cameraData = {
    DisplayName: isLoading ? (
      <StyledLoadingSpinner />
    ) : (
      <AiOutlineCamera size={30} />
    ),
    Message: {
      messageType: "GuideScreenshot",
    },
  };

  const moveData = {
    DisplayName: <BiMove size={30} />,
    Message: {
      messageType: "Scene",
      SceneCommand: menuStage === 0 ? "GuideMoving" : "RaftMoving",
    },
  };

  useEffect(() => {
    if (isLoading && image) {
      setIsLoading(false);
    }
  }, [isLoading, image]);

  return (
    <BoxContainer>
      <StyledBoxHeader>{title}</StyledBoxHeader>
      <div
        style={{
          height: "200px",
          width: "100%",
          borderRadius: "5px",
        }}
      >
        <img
          src={image ? image : PlaceholderImage}
          height="100%"
          width="100%"
          alt=""
          style={{ borderRadius: "2px" }}
        />
      </div>
      <BoxButtons>
        <MessageButton
          inputEmitter={inputEmitter}
          componentData={moveData}
          overrideClass="con-box-button"
          disabled={disabledBox}
        />
        <MessageButton
          inputEmitter={inputEmitter}
          componentData={cameraData}
          overrideClass="con-box-button"
          disabled={disabledBox}
          clickEvent={clickEvent}
        />
      </BoxButtons>
    </BoxContainer>
  );
};

export default StageBox;
