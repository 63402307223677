import React from "react";

function AccordionComponent({
  title,
  overrideTitle,
  menu,
  isOpen,
  index,
  toggleItem,
  inputEmitter,
  children,
}) {
  const accordionTitle = overrideTitle ? overrideTitle : title;
  if (menu) {
    return <div className="configureTitle">{title}</div>;
  }
  return (
    <div className={"item " + (isOpen ? "open" : "")} key={index}>
      <div
        onClick={() => toggleItem(index)}
        onKeyUp={() => console.log(index)}
        role="button"
        tabIndex="0"
        className={"item-title " + accordionTitle}
      >
        {title.replace("_", " ")}
      </div>
      <div className="item-answer">{children}</div>
    </div>
  );
}
export default AccordionComponent;
