import { keyframes } from "styled-components";
import styled from "styled-components";
const flashAnimation = keyframes`
 0% {
    background: #32b8a1; /* Initial background position */
  }
  50% {
    background: rgb(226, 219, 122); /* Flash background position */
  }
  100% {
    background: #32b8a1; /* Back to initial background position */
  }
`;
export const StyledHelpButton = styled.div`
  background-size: 100% 200%;
  height: 35px;
  width: 35px;
  background: #32b8a1;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ clickable }) => clickable && "pointer"};
  transition: transform 0.3s ease-out;
  color: white;
  font-size: 22px;
  &:hover {
    transform: ${({ clickable }) => clickable && "scale(1.2)"};
  }
  animation-name: ${flashAnimation};
  animation-duration: 1s;
  animation-iteration-count: ${({ flashing }) => (flashing ? 5 : 0)};
`;
