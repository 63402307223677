import styled from "styled-components";

export const StyledOverallCeilContainer = styled.div``;

export const StyledLookContainerCeil = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledLabelsCeil = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
`;

export const StyledReferenceCeil = styled.div`
  margin-top: 20px;
  font-size: 11px;
  color: gray;
`;
