import React from "react";
import Modal from "react-modal";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import {
  StyledHelpModalContent,
  StyledPagination,
  StyledTextContainer,
} from "../../styles/HelpModalContent.styled";

import "swiper/modules/pagination/pagination.min.css";

import data from "./HelpData.json";
import HelpButton from "./HelpButton";

const customStyles = {
  content: {
    width: "50%",
    height: "auto",
    top: "50%",
    left: "40%",
    bottom: "auto",
    transform: "translate(-30%, -50%)",
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement("#root");
SwiperCore.use([Navigation, Pagination]);

export default function HelpModal({ title, section, onClickClose }) {
  let values = Object.values(data[section]);

  return (
    <Modal isOpen={true} style={customStyles}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>{title}</h2>
        <HelpButton clickable={false} />
      </div>
      <br />
      <Swiper
        navigation={true}
        spaceBetween={50}
        slidesPerView={1}
        autoHeight={true}
        pagination={{ clickable: true }}
      >
        {values.map((item) => {
          return (
            <>
              <SwiperSlide key={item.id}>
                <StyledHelpModalContent>
                  <img
                    src={item.image}
                    alt={`item ${item.id}`}
                    height="190px"
                    width="520px"
                    style={{ borderRadius: "0px" }}
                  />
                  <StyledTextContainer>
                    <p>{item.text}</p>
                  </StyledTextContainer>
                  <button className="modal-button" onClick={onClickClose}>
                    Close
                  </button>
                </StyledHelpModalContent>
              </SwiperSlide>
              {item.id} / {values.length}
            </>
          );
        })}
      </Swiper>
    </Modal>
  );
}
