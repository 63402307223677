import React, { useEffect, useState } from "react";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";

function CheckboxComponent(props) {
  const parametricData = useParametricData();
  const updateParametricData = useParametricUpdate();

  const isChecked = () => {
    let isChecked = true;
    Object.keys(props.componentData.True).every((key) => {
      let objectToCheck = props.componentData.Path
        ? parametricData[props.componentData.Path]
        : parametricData;
      if (
        objectToCheck[key] !== props.componentData.True[key] &&
        objectToCheck.hasOwnProperty(key)
      ) {
        isChecked = false;
        return false;
      }
      return true;
    });
    return isChecked;
  };

  useEffect(() => {
    setChecked(isChecked());
  }, [parametricData]);

  const [checked, setChecked] = useState(isChecked());

  function onClick() {
    var data = checked ? props.componentData.False : props.componentData.True;
    if (props.componentData.Path) {
      let pathData = {};
      pathData[props.componentData.Path] = { ...data };
      data = pathData;
      Object.keys(data[props.componentData.Path]).forEach((key) => {
        console.log(data[key]);
        parametricData[props.componentData.Path][key] =
          data[props.componentData.Path][key];
      });
    } else {
      Object.keys(data).forEach((key) => {
        console.log(data[key]);
        parametricData[key] = data[key];
      });
    }

    data["messageType"] = "SetValue";
    props.inputEmitter.EmitUIInteraction(data);

    if (props.componentData.Update) {
      updateParametricData({ ...parametricData });
    }
    setChecked(!checked);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <button
        style={{ background: "none", border: "none" }}
        onClick={() => onClick()}
      >
        <div
          style={{
            borderRadius: "50%",
            borderWidth: 2,
            borderColor: checked ? "#32B8A1" : "black",
            borderStyle: "solid",
            width: 16,
            height: 16,
            alignContent: "center",
            padding: "2px 2px",
            cursor: "pointer",
          }}
        >
          {checked && (
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor: "#32B8A1",
                borderRadius: "50%",
              }}
            ></div>
          )}
        </div>
      </button>

      <div style={{ paddingLeft: 5 }}>{props.componentData.DisplayName}</div>
    </div>
  );
}

export default CheckboxComponent;
