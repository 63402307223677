import React, { useContext, useState } from "react";

const SavesContext = React.createContext();
const SavesSetContext = React.createContext();

export function useSavesData() {
  return useContext(SavesContext);
}

export function UseSetSaves() {
  return useContext(SavesSetContext);
}
export function SavesDataProvider({ children }) {
  const [currentSaves, setSaves] = useState([]);
  function setData(data) {
    console.log(data);
    setSaves(data);
  }
  return (
    <SavesContext.Provider value={currentSaves}>
      <SavesSetContext.Provider value={setData}>
        {children}
      </SavesSetContext.Provider>
    </SavesContext.Provider>
  );
}
