import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import AuthModal from "./AuthModal";
import CeilingModal from "./CeilingModal";
import SceneModal from "./SceneModal";
import WelcomeModal from "./WelcomeModal";
import { useSetUser, useUser } from "../hooks/UseContext";
import LoadConfigModal from "./LoadConfigModal";

function JourneyModals(props) {
  const user = useUser();
  const setUser = useSetUser();
  const [state, SetState] = useState({ index: 0, isSigningIn: true });
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  function updateState(newState) {
    SetState(newState);
  }
  useEffect(() => {
    const checkUser = () => {
      console.log(user);
      setSignedIn(user !== null);
      setLoading(false);
    };

    checkUser();
  }, [user]);

  if (loading) {
    return <div>loading</div>;
  } else {
    return (
      <div>
        {state.index === 0 && (
          <WelcomeModal
            onSignOut={() => {
              setSignedIn(false);
              setUser("");
              Auth.signOut();
            }}
            onLogin={() => SetState({ ...state, index: 1, isSigningIn: true })}
            onRegister={() =>
              SetState({ ...state, index: 1, isSigningIn: false })
            }
            onStart={() => SetState({ ...state, index: 2, isSigningIn: true })}
            onContinue={() => SetState({ ...state, index: 4 })}
            isSignedIn={signedIn}
          />
        )}

        {state.index === 1 && (
          <AuthModal
            signIn={state.isSigningIn}
            onBack={() => SetState({ ...state, index: 0 })}
            onSignedIn={() => SetState({ ...state, index: 2 })}
          />
        )}

        {state.index === 2 && (
          <SceneModal
            onBack={() => SetState({ ...state, index: 0 })}
            next={() => SetState({ ...state, index: 3 })}
          />
        )}

        {state.index === 3 && (
          <CeilingModal
            inputEmitter={props.inputEmitter}
            onBack={() => SetState({ ...state, index: 2 })}
            onStart={() => {
              props.launch();
            }}
          />
        )}

        {state.index === 4 && (
          <LoadConfigModal
            inputEmitter={props.inputEmitter}
            launch={props.launch}
            onBack={() => SetState({ ...state, index: 0 })}
          />
        )}
      </div>
    );
  }
}

export default JourneyModals;
