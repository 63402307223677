import React, { useContext, useState } from "react";


const UserContext = React.createContext();
const SetUserContext = React.createContext();

export function useSetUser() {
  return useContext(SetUserContext);
}

export function useUser() {
  return useContext(UserContext);
}
export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  function setData(user) {
    console.log(user)
    setCurrentUser(user);
  }
  return (
    <UserContext.Provider value={currentUser}>
      <SetUserContext.Provider value={setData}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
}
