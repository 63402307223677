import React from "react";
import { useTable } from "react-table";
import { Table } from "semantic-ui-react";

import "./AdminTable.css";
function AdminUserTable(props) {
  const data = props.data;
  const columns = props.columns;
  const tableInstance = useTable({ columns, data }, props.tableHooks);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <div style={{ paddingTop: 50, width: "80%" }}>
        <Table {...getTableProps()}>
          <Table.Header>
            {
              // Loop over the header rows

              headerGroups.map((headerGroup) => (
                // Apply the header row props

                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row

                    headerGroup.headers.map((column) => (
                      // Apply the header cell props

                      <Table.HeaderCell
                        className="tableHead"
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header

                          column.render("Header")
                        }
                      </Table.HeaderCell>
                    ))
                  }
                </Table.Row>
              ))
            }
          </Table.Header>

          {/* Apply the table body props */}

          <Table.Body {...getTableBodyProps()}>
            {
              // Loop over the table rows

              rows.map((row) => {
                // Prepare the row for display

                prepareRow(row);

                return (
                  // Apply the row props

                  <Table.Row {...row.getRowProps()}>
                    {
                      // Loop over the rows cells

                      row.cells.map((cell) => {
                        // Apply the cell props

                        return (
                          <Table.Cell
                            className="tableCell"
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents

                              cell.render("Cell")
                            }
                          </Table.Cell>
                        );
                      })
                    }
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default AdminUserTable;
