import styled from "styled-components";

export const StyledUpdateModalContent = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 10px;
    color: grey;
  }

  ul {
    margin: 0;
    width: 90%;
  }

  li {
    text-align: justify;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const StyledMainTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
