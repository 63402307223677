import React, { useEffect, useState } from "react";
import { useParametricData } from "../hooks/ParametricDataContext";

import { useSavesData, UseSetSaves } from "../hooks/SavesHook";
import { useUser } from "../hooks/UseContext";
import "./ConfigLayout.css";
import { TempModal } from "./TempModal";
import { Auth } from "aws-amplify";
function ConfigLayout(props) {
  const parametricData = useParametricData();
  const names = useSavesData();
  const setNames = UseSetSaves();
  const user = useUser();
  const [showModal, setShowModal] = useState(false);
  function onModalClicked(value) {
    var message = { messageType: "Save", Name: value };
    console.log(message);
    props.inputEmitter.EmitUIInteraction(message);

    setShowModal(false);
    const interval = setInterval(async () => {
      if (!names.includes(value)) {
        setNames([...names, value]);
        console.log(names.length);
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(currentUser, {
            "custom:Configs": (names.length + 1).toString(),
          });
        } catch (e) {
          console.log(e);
          clearInterval(interval);
        }
      }

      clearInterval(interval);
    }, 3000);
  }
  const loadSave = (index) => {
    parametricData.isSave = true;
    if (props.isModal) {
      parametricData.Loading = true;
      parametricData.LoadName = names[index].ConfigurationID;
      if (props.isModal) {
        props.launch();
      }
    } else {
      props.inputEmitter.EmitUIInteraction({
        messageType: "Login",
        UserID: user.username,
      });

      var message = { messageType: "Load", Name: names[index].ConfigurationID };
      props.inputEmitter.EmitUIInteraction(message);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <TempModal
        buttonTitle="Save"
        fieldTitle="Save name:"
        onModalClick={onModalClicked}
        isOpen={showModal}
        label="Save"
      />
      {!user ? (
        <div>
          Not signed in, please sign in at the bottom of the config menu.
        </div>
      ) : (
        <>
          <div
            className="config-container"
            style={{ flex: 1, justifyContent: "center" }}
          >
            {names.map((name, index) => {
              return (
                name !== "" && (
                  <div
                    role={"button"}
                    key={index}
                    tabIndex={0}
                    onKeyUp={() => {}}
                    onClick={() => loadSave(index)}
                    className="img-wrap config-card-container"
                  >
                    <img
                      className="config-image"
                      alt={name}
                      src={`https://zentia-configuartion-deb.s3.eu-west-2.amazonaws.com/${user.username}/${name.ConfigurationID}.png`}
                    />
                    <div className="config-text">{name.ConfigurationID}</div>
                  </div>
                )
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default ConfigLayout;
