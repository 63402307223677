import React, { useEffect, useState } from "react";
import FilterConfig from "../assets/filterConfig.json";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import AccordionComponent from "./AccordionComponent";
import CheckboxComponent from "./CheckboxComponent";
import ColourMenu from "./ColourMenu";
import GridColourComponent from "./GridColourComponent";
import HeightMenu from "./HeightMenu";
import ImageSelectComponent from "./ImageSelectComponent";
import LightingMenu from "./LightingMenu";
import MessageButton from "./MessageButton";
import RandomDistributer from "./RandomDistributer";
import SliderComponent from "./SliderComponent";
import CanopyLength from "./CanopyLength";
import { checkParaData } from "../globals/GolbalConfig";
import ImageSelectCompCategories from "./ImageSelectCompCategories";
import ImageSlider from "./ImageSlider";
import GridLengths from "./GridLengths";
import AngledPanels from "./AngledPanels/AngledPanels";

function getElementValue(element, overrideKey) {
  let value = { ...element };
  if (value.Overrides && value.Overrides[overrideKey]) {
    Object.keys(value.Overrides[overrideKey]).forEach((key) => {
      value[key] = value.Overrides[overrideKey][key];
    });
  }
  return value;
}
const testDropdown = [
  {
    key: 0,
    text: "Rectangle",
    value: { name: "Rectangle", message: 0 },
  },
  {
    key: 1,
    text: "Circle",
    value: { name: "Circles", message: 1 },
  },
  {
    key: 2,
    text: "Hexagon",
    value: { name: "Hexagon", message: 2 },
  },
  {
    key: 3,
    text: "BafflesIslands",
    value: { name: "BafflesIslands", message: 3 },
  },
  {
    key: 4,
    text: "BafflesShapes",
    value: { name: "BafflesShapes", message: 4 },
  },
  {
    key: 5,
    text: "Matrix",
    value: { name: "Matrix", message: 5 },
  },
  {
    key: 6,
    text: "HighwayRunner",
    value: { name: "HighwayRunner", message: 6 },
  },
];

const subtypes = [
  "Grid",
  "Interlocked",
  "Trapezoid",
  "Hex",
  "Triangle",
  "Offset",
  "Honeycomb",
  "Rectangle",
  "Canopy",
];
function ModularMenu(props) {
  function changeSubType(subTypeString) {
    var newConfig = { ...currentConfig };
    newConfig.SubType = subTypeString;
    setConfig(newConfig);
  }

  function changeType(typeString) {
    var newConfig = { ...currentConfig };
    newConfig.Type = typeString;
    setConfig(newConfig);
  }

  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const [currentOpenAccordion, setCurrentOpenAccordian] = useState("");
  const [currentAccordionFilter, setAccordionFilter] = useState([]);
  const [currentConfig, setConfig] = useState({
    Type: testDropdown[parametricData.Type].value.name,
    SubType: subtypes[parametricData.SubType],
  });

  useEffect(() => {
    setConfig({
      Type: testDropdown[parametricData.Type].value.name,
      SubType: subtypes[parametricData.SubType],
    });
  }, [parametricData.Type, parametricData.SubType]);
  var totalIndex = -1;

  const onClickAccordion = (title) => {
    setCurrentOpenAccordian(title === currentOpenAccordion ? "" : title);
  };
  function checkIfValueMatches(element) {
    if (element.Visible === null || element.Visible === undefined) {
      return true;
    }

    let matches = checkParaData(parametricData, element.Visible);

    return matches;
  }
  function shouldDrawElement(element, currentFilter) {
    return (
      (currentFilter[element.Category] || element.Category === "") &&
      checkIfValueMatches(element) &&
      !element.HideInSonify
    );
  }
  function drawAccordians() {
    return currentAccordionFilter.map((element, index) => {
      const title =
        FilterConfig.Values[element].Title !== undefined
          ? FilterConfig.Values[element].Title
          : element;
      const currentFilter =
        FilterConfig.Filters[currentConfig.Type + "-" + currentConfig.SubType];
      totalIndex++;
      var shouldDraw = false;
      FilterConfig.Values[element].Values.forEach((element) => {
        if (shouldDrawElement(element, currentFilter)) shouldDraw = true;
      });
      return (
        shouldDraw && (
          <AccordionComponent
            toggleItem={() => {
              onClickAccordion(element);
            }}
            title={title}
            isOpen={element === currentOpenAccordion}
            index={index}
            inputEmitter={props.inputEmitter}
            overrideTitle={FilterConfig.Values[element].OverrideTitle}
          >
            {FilterConfig.Values[element].Values.map((element) => {
              let draw = shouldDrawElement(element, currentFilter);
              return (
                draw &&
                selectComponent(
                  getElementValue(
                    element,
                    currentConfig.Type + "-" + currentConfig.SubType
                  ),
                  props.inputEmitter,
                  (string) => changeSubType(string),
                  (string) => changeType(string),
                  currentConfig
                )
              );
            })}
          </AccordionComponent>
        )
      );
    });
  }

  useEffect(() => {
    function getRenderedAccordians() {
      //console.log(currentConfig);
      const currentFilter =
        FilterConfig.Filters[currentConfig.Type + "-" + currentConfig.SubType];
      // console.log(currentConfig);
      var renderedAccordianList = [];
      Object.keys(FilterConfig.Values).forEach((element) => {
        FilterConfig.Values[element].Values.every((val) => {
          if (currentFilter[val.Category]) {
            renderedAccordianList.push(element);
            return false;
          }
          return true;
        });
      });
      return renderedAccordianList;
    }
    const filter = getRenderedAccordians();
    setAccordionFilter(filter);
  }, [currentConfig]);

  function getTitle() {
    if (currentConfig.Type === "Matrix") {
      return "Nexus Configuration";
    } else if (
      currentConfig.Type === "BafflesIslands" ||
      currentConfig.Type === "BafflesIslands" ||
      currentConfig.Type === "HighwayRunner"
    ) {
      return currentConfig.Type.replace(/([A-Z])/g, " $1") + " Configuration";
    }
    return (
      currentConfig.Type.replace(/([A-Z])/g, " $1") + " Island Configuration"
    );
  }
  return (
    <div>
      <AccordionComponent title={getTitle()} menu={true} />

      {drawAccordians()}

      {currentConfig.Type !== "BafflesShapes" &&
        currentConfig.Type !== "BafflesIslands" &&
        parametricData.RaftType !== "RaftWall" &&
        parametricData.RaftType !== 1 && (
          <AccordionComponent
            toggleItem={() => {
              onClickAccordion("Height");
            }}
            title={"Height"}
            isOpen={"Height" === currentOpenAccordion}
            index={totalIndex++}
            inputEmitter={props.inputEmitter}
          >
            <HeightMenu inputEmitter={props.inputEmitter} />
          </AccordionComponent>
        )}
      <AccordionComponent
        toggleItem={() => {
          onClickAccordion("Colour_palette");
        }}
        title={"Colour_palette"}
        isOpen={"Colour_palette" === currentOpenAccordion}
        index={totalIndex++}
        inputEmitter={props.inputEmitter}
      >
        <ColourMenu inputEmitter={props.inputEmitter} />
      </AccordionComponent>
      {parametricData.Type === 0 &&
        parametricData.RaftType !== "RaftWall" &&
        parametricData.RaftType !== 1 && (
          <>
            <AccordionComponent title="Sonify Tilt" menu={true} />
            <AngledPanels
              inputEmitter={props.inputEmitter}
              onClickAccordion={onClickAccordion}
              index={totalIndex++}
              currentOpenAccordion={currentOpenAccordion}
            />
          </>
        )}

      <AccordionComponent title={"Grid Settings"} menu={true} />
      {/* {parametricData.SubType === 0 && (
        <AccordionComponent
          toggleItem={() => {
            onClickAccordion("Lighting");
          }}
          title={"Lighting"}
          isOpen={"Lighting" === currentOpenAccordion}
          index={totalIndex++}
          inputEmitter={props.inputEmitter}
        >
          <LightingMenu inputEmitter={props.inputEmitter} />
        </AccordionComponent>
      )} */}

      {parametricData.RaftType === 1 && (
        <AccordionComponent
          toggleItem={() => {
            onClickAccordion("Grid_enabled");
          }}
          title={"Grid_enabled"}
          isOpen={"Grid_enabled" === currentOpenAccordion}
          index={totalIndex++}
          inputEmitter={props.inputEmitter}
        >
          <CheckboxComponent
            inputEmitter={props.inputEmitter}
            componentData={{
              Category: "",
              AccordianGroup: "",
              Name: "bGridEnabled",
              DisplayName: "Grid Enabled",
              ComponentType: "CheckBox",
              Update: true,
              True: { bGridEnabled: true },
              False: { bGridEnabled: false },
            }}
          />
        </AccordionComponent>
      )}
      <AccordionComponent
        toggleItem={() => {
          onClickAccordion("Grid_colours");
        }}
        title={"Grid_colours"}
        isOpen={"Grid_colours" === currentOpenAccordion}
        index={totalIndex++}
        inputEmitter={props.inputEmitter}
      >
        <GridColourComponent inputEmitter={props.inputEmitter} />
      </AccordionComponent>

      {/* grid lengths */}
      {parametricData.RaftType !== "RaftWall" &&
        parametricData.RaftType !== 1 && (
          <AccordionComponent
            toggleItem={() => {
              onClickAccordion("Grid_lengths");
            }}
            title={"Grid_lengths"}
            isOpen={"Grid_lengths" === currentOpenAccordion}
            index={totalIndex++}
            inputEmitter={props.inputEmitter}
          >
            <GridLengths inputEmitter={props.inputEmitter} />
          </AccordionComponent>
        )}

      {/* Test Stuff */}
    </div>
  );
}

function selectComponent(
  componentData,
  inputEmitter,
  changeSubType_func,
  changeType_func,
  currentConfig
) {
  switch (componentData.ComponentType) {
    case "Group":
      return (
        <div style={componentData.Style}>
          {componentData.Children.map((element) =>
            selectComponent(
              getElementValue(
                element,
                currentConfig.Type + "-" + currentConfig.SubType
              ),
              inputEmitter,
              changeSubType_func,
              changeType_func
            )
          )}
        </div>
      );
    case "Slider":
      return (
        <SliderComponent
          inputEmitter={inputEmitter}
          componentData={componentData}
          key={componentData.Name}
        ></SliderComponent>
      );
    case "ImageSlider":
      return (
        <ImageSlider
          componentData={componentData}
          inputEmitter={inputEmitter}
          key={componentData.Name}
        />
      );
    case "ImageSelector":
      return (
        <ImageSelectComponent
          inputEmitter={inputEmitter}
          componentData={componentData}
          changeSubType={changeSubType_func}
          changeType={changeType_func}
          key={componentData.Name}
        />
      );
    case "ImageSelectorCategories":
      return (
        <ImageSelectCompCategories
          inputEmitter={inputEmitter}
          componentData={componentData}
          changeSubType={changeSubType_func}
          changeType={changeType_func}
          key={componentData.Name}
        />
      );
    case "MessageButton":
      return (
        <MessageButton
          componentData={componentData}
          inputEmitter={inputEmitter}
          key={componentData.Name}
        />
      );
    case "CheckBox":
      return (
        <CheckboxComponent
          componentData={componentData}
          inputEmitter={inputEmitter}
          key={componentData.Name}
        />
      );
    case "RandomDistributor":
      return (
        <RandomDistributer
          componentData={componentData}
          inputEmitter={inputEmitter}
          key={componentData.Name}
        />
      );
    case "CanopyLength":
      return (
        <CanopyLength
          componentData={componentData}
          inputEmitter={inputEmitter}
          key={componentData.Name}
        />
      );

    default:
      return;
  }
}
export default ModularMenu;
