import React, { useState } from "react";
import ColourPickerComponent from "./ColourPickerComponent";
import { coloursObject } from "../globals/GlobalColours";
import Tick from "../assets/icons/tick.svg";

const mainGridColours = [
  { r: 236, g: 236, b: 231, swatchName: "S 0500-N" },
  { r: 158, g: 158, b: 158, swatchName: "Steel" },
  { r: 15, g: 15, b: 15, swatchName: "S 9000-N" },
];
function GridColourComponent(props) {
  const [gridColours, setGridColours] = useState([2, 32]);
  const [picking, setPicking] = useState(-1);
  var pickingIndex = 0;
  const singleView = () => (
    <div>
      <div>Choose the main and secondary colour of the grid.</div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div>Grid Colour</div>
        <div>Capping Colour</div>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              setPicking(0);
            }}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            style={{
              marginRight: 10,
              cursor: "pointer",
              display: "inline-block",
              borderWidth: 2,
              borderColor: "#5EE1CB",
              borderStyle: "solid",
              height: 36,
              width: 26,
              background: `rgb(${mainGridColours[gridColours[0]].r}, ${
                mainGridColours[gridColours[0]].g
              },${mainGridColours[gridColours[0]].b})`,
            }}
          />
          <div>{mainGridColours[gridColours[0]].swatchName}</div>
          <img style={{ display: "inline-block" }} src={Tick} alt="tick" />
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              pickingIndex = 1;
              setPicking(1);
            }}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            style={{
              marginRight: 10,
              cursor: "pointer",
              display: "inline-block",
              borderWidth: 2,
              borderColor: "#5EE1CB",
              borderStyle: "solid",
              height: 36,
              width: 26,
              background: `rgb(${coloursObject[gridColours[1]].r}, ${
                coloursObject[gridColours[1]].g
              },${coloursObject[gridColours[1]].b})`,
            }}
          />
          <div>{coloursObject[gridColours[1]].swatchName}</div>
          <img style={{ display: "inline-block" }} src={Tick} alt="tick" />
        </div>
      </div>
    </div>
  );
  const onColourSelected = (index) => {
    console.log(index);
    var message = {
      messageType: "SetValue",
      Colours: {},
    };

    if (picking === 0) {
      message.Colours.gridMainColour = {
        r: mainGridColours[index].r,
        g: mainGridColours[index].g,
        b: mainGridColours[index].b,
        a: 255,
      };
      message.Colours.bGalvanizedGrid = index === 1;
    }
    if (picking === 1) {
      message.Colours.gridSecondaryColour = {
        r: coloursObject[index].r,
        g: coloursObject[index].g,
        b: coloursObject[index].b,
        a: 255,
      };
    }
    props.inputEmitter.EmitUIInteraction(message);
    var gridCols = [...gridColours];
    gridCols[picking] = index;
    setGridColours(gridCols);
    setPicking(-1);
    //parametricData.ColourIndex = index;
    //toggleItem(index);
  };

  return (
    <div>
      {picking >= 0 ? (
        <ColourPickerComponent
          onColourSelected={(index) => {
            onColourSelected(index);
            console.log(coloursObject[index]);
          }}
          coloursObject={picking === 0 ? mainGridColours : coloursObject}
          selectedIndex={gridColours[picking]}
        />
      ) : (
        singleView()
      )}
    </div>
  );
}

export default GridColourComponent;
