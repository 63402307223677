import React, { useState, useEffect } from "react";
import {
  StyledConGuideContainer,
  StyledSubContainer,
} from "../../styles/ConstructionGuide.styled";
import PageButtons from "./PageButtons";
import StageBox from "./StageBox";

const ConstructionGuide = ({
  inputEmitter,
  messageSubject,
  selectedRaftIndex,
}) => {
  const [menuStage, setMenuStage] = useState(0);
  const [raftImage, setRaftImage] = useState("");
  const [guideImage, setGuideImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [raftLoading, setRaftLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  function handleCameraClick(clickedStage) {
    setMenuStage(clickedStage);
    if (clickedStage === 1) setIsLoading(true);
    if (clickedStage === 2) setRaftLoading(true);
  }

  async function processBoqData(boqData) {
    const newExcelData = [
      ["Kit ID", boqData[0].data.code, "", ""],
      ["", "", "", ""],
      ["Index", "Code", "Description", "Quantity"],
    ];

    boqData.shift();

    boqData.forEach((element) => {
      let newEntryArray = [
        element.index,
        element.data.code,
        element.data.description,
      ];
      if (element.count >= 0) newEntryArray.push(element.count);
      newExcelData.push(newEntryArray);
    });

    return newExcelData;
  }

  useEffect(() => {
    const subscription = messageSubject.subscribe(async (value) => {
      const message = JSON.parse(value);

      if (message.messageType === "GuideScreenshot") {
        try {
          console.log("Screenshot");
          const response = await fetch(
            "https://zentia-screenshots.s3.eu-west-2.amazonaws.com/" +
              message.name,
            {
              method: "GET",
              headers: {},
            }
          );

          const buffer = await response.arrayBuffer();
          const url = window.URL.createObjectURL(new Blob([buffer]));
          menuStage === 1 ? setGuideImage(url) : setRaftImage(url);
        } catch (err) {
          console.log(err);
        }
      }

      if (message.messageType === "boq") {
        try {
          const newExcelData = await processBoqData(message.container.data);
          setExcelData(newExcelData);
          console.log(newExcelData);
        } catch (err) {
          console.log(err);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, menuStage, setGuideImage, setRaftImage, setExcelData]);

  function handleExportClick() {
    handleCameraClick(2);
    var message = { messageType: "Boq", Index: selectedRaftIndex };
    inputEmitter.EmitUIInteraction(message);
  }

  return (
    <StyledConGuideContainer>
      <StyledSubContainer>
        <StageBox
          enabled={true}
          title="Install Guide Image"
          inputEmitter={inputEmitter}
          menuStage={0}
          setMenuStage={setMenuStage}
          image={guideImage}
          clickEvent={() => handleCameraClick(1)}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <StageBox
          enabled={guideImage !== ""}
          title="Visual Screenshot"
          inputEmitter={inputEmitter}
          menuStage={1}
          setMenuStage={setMenuStage}
          image={raftImage}
          clickEvent={handleExportClick}
          isLoading={raftLoading}
          setIsLoading={setRaftLoading}
        />
        <PageButtons
          disabled={!(guideImage && raftImage)}
          inputEmitter={inputEmitter}
          menuStage={menuStage}
          guideImage={guideImage}
          raftImage={raftImage}
          boqSheet={excelData}
          selectedRaftIndex={selectedRaftIndex}
        />
      </StyledSubContainer>
    </StyledConGuideContainer>
  );
};

export default ConstructionGuide;
