export var parametricData = {
  Admin: false,
  Type: 0,
  SubType: 0,
  Colours: {
    RandomSeed: 100,
    ScatteringType: "RandomColours",
    MainColour: {
      b: 254,
      g: 254,
      r: 254,
      a: 255,
    },
    MainColourPercentage: 100,
    SecondColour: {
      b: 59,
      g: 92,
      r: 113,
      a: 255,
    },
    SecondColourPercentage: 0,
    ThirdColour: {
      b: 24,
      g: 51,
      r: 91,
      a: 255,
    },
    ThirdColourPercentage: 0,
    CheckerType: 0,
  },
  EnvironmentVariables: {
    LightStrength: 1,
    LightType: 1,
  },
  bGridEnabled: true,
  XNumber: 10,
  YNumber: 10,
  RectangleShape: 2,
  bRoundOffset: false,
  bRoundOffsetHerring: false,
  RoundOffsetPercentage: 0,
  XOffset: 1,
  YOffset: 1,
  bSyncedSpacing: false,
  bRotate90: false,
  HeightScatteringType: 1,
  HeightMain: 0,
  HeightSecond: 0,
  HeightThird: 0,
  HeightMainPercentage: 100,
  HeightSecondPercentage: 0,
  HeightThirdPercentage: 0,
  HeightRandomSeed: 33,
  PaletteIndex: -1,
  ColourIndex: 0,
  CyclesRectangleProportion: 1,
  CyclesRectangleSpacing: 1,
  Level: 0,
  BaffleHeightPercentage: 0,
  RotationAmount: 0,
  NexusSettings: {
    RowCount: 1,
    bSymmetric: false,
  },
  RowWidth: 0,
  CanopyGapQuantity: 0,
  CanopyGapSize: 140,
  CanopyGapSizeQuantity: 1,
  BorderDistance: 0,
  HighwaySettings: {
    bUseTwoGaps: false,
    GapSizeOne: 140,
    GapSizeTwo: 140,
    GapRatio: 0.0,
    GapPercentage: 0.0,
    StartingPoints: 1,
    StartingDistance: 0,
    Seed: 0,
  },
  MROffsetLength: 0,
  PCTOffsetLength: 0,
  GridOffsetSynced: false,
  AngleSettings: {
    AngleAdjust: 5,
    AnglePatternType: 0,
    AmountAngledCanopies: 50,
    AngleOrientation: 0,
    RandomAngleRotations: false,
    FlowWidth: 1,
    AngleCheckerHeightType: 0,
  },
};

export var menuSettings = {};
export function setParametricData(newData) {
  parametricData = newData;
}

export function checkParaData(paraData, objToCheck) {
  let doesMatch = true;
  Object.keys(objToCheck).every((key) => {
    if (typeof objToCheck[key] === "object") {
      doesMatch = checkParaData(paraData[key], objToCheck[key]);
      return doesMatch;
    } else {
      doesMatch = objToCheck[key] === paraData[key];
      return doesMatch;
    }
  });
  return doesMatch;
}
