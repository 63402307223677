import React from "react";
import CheckboxComponent from "./CheckboxComponent";

const lengthData = [
  [600, "one", "two"],
  [1200, "one", "two", "three"],
];

export default function CanopyLength(props) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {props.componentData.Text}
    </div>
  );
}
