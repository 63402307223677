import React, { useState, useEffect } from "react";
import "../App.css";
import ImageSelectComponent from "./ImageSelectComponent";
import Slider from "./SliderComponent";

import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
function HeightMenu(props) {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const [extended, changeExtended] = useState(
    parametricData.HeightSecondPercentage > 0 ||
      parametricData.HeightThirdPercentage > 0
  );
  useEffect(() => {
    console.log(parametricData.HeightSecondPercentage);
    changeExtended(
      parametricData.HeightSecondPercentage > 0 ||
        parametricData.HeightThirdPercentage > 0
    );
  }, [parametricData]);
  const toggleExtendedMenu = (e) => {
    if (extended) {
      props.inputEmitter.EmitUIInteraction({
        messageType: "SetValue",
        HeightMainPercentage: 100,
        HeightSecondPercentage: 0,
        HeightThirdPercentage: 0,
        HeightScatteringType: 1,
      });
      setParametricData({
        ...parametricData,
        HeightMainPercentage: 100,
        HeightSecondPercentage: 0,
        HeightThirdPercentage: 0,
        HeightScatteringType: 1,
      });
    }

    changeExtended(!extended);
  };
  return (
    <div>
      {!extended && (
        <div>
          <div>
            A second and a third installation height of the canopies can be
            added to an island. The heights can be chosen in 50mm-steps, between
            0 mm and 200 mm.
          </div>
          <Slider
            inputEmitter={props.inputEmitter}
            componentData={{
              Value: "1",
              Min: "0",
              Max: "4",
              DisplayName: "Main height",
              Units: "mm",
              Step: "1",
              Name: "HeightMain",
              Multiplier: 50,
            }}
          />
          <button
            className="button"
            onClick={() => {
              toggleExtendedMenu();
            }}
          >
            Create height variation
          </button>
        </div>
      )}
      {extended && (
        <div style={{ width: "100%", overflow: "clip" }}>
          <div>
            A second and a third installation height of the canopies can be
            added to an island. The heights can be chosen in 50mm-steps, between
            0 mm and 200 mm.
          </div>
          <ImageSelectComponent
            inputEmitter={props.inputEmitter}
            componentData={{
              KeepState: true,
              Images: [
                {
                  Icon: "heightRandom",
                  Value: { HeightScatteringType: 1 },
                  Text: "Random",
                },
                {
                  Icon: "heightRow",
                  Value: { HeightScatteringType: 2 },
                  Text: "Row",
                },
              ],
            }}
          />
          <button
            className="button"
            onClick={() => {
              const randomNum = Math.floor(Math.random() * 10000000);
              console.log(randomNum);
              props.inputEmitter.EmitUIInteraction({
                messageType: "SetValue",
                HeightRandomSeed: randomNum,
              });
            }}
          >
            Randomize
          </button>

          <div className="height-slider-container">
            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: "1",
                Min: "0",
                Max: "4",
                DisplayName: "Main height",
                Units: "mm",
                Step: "1",
                Name: "HeightMain",
                Multiplier: 50,
              }}
            />

            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: "100",
                Min: "0",
                Max: "100",
                DisplayName: "Main height %",
                Units: "%",
                Step: "10",
                Name: "HeightMainPercentage",
                Multiplier: 1,
              }}
            />
          </div>
          <div className="height-slider-container">
            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: 0,
                Min: 0,
                Max: 4,
                DisplayName: "Second height",
                Units: "mm",
                Step: 1,
                Name: "HeightSecond",
                Multiplier: 50,
              }}
            />
            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: "0",
                Min: "0",
                Max: "100",
                DisplayName: "Second height %",
                Units: "%",
                Step: "10",
                Name: "HeightSecondPercentage",
                Multiplier: 1,
              }}
            />
          </div>
          <div className="height-slider-container">
            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: 0,
                Min: 0,
                Max: 4,
                DisplayName: "Third height",
                Units: "mm",
                Step: 1,
                Name: "HeightThird",
                Multiplier: 50,
              }}
            />
            <Slider
              inputEmitter={props.inputEmitter}
              componentData={{
                Value: "0",
                Min: "0",
                Max: "100",
                DisplayName: "Third height %",
                Units: "%",
                Step: "10",
                Name: "HeightThirdPercentage",
                Multiplier: 1,
              }}
            />
          </div>
          <button
            className="button"
            onClick={() => {
              toggleExtendedMenu();
            }}
          >
            Remove height variation
          </button>
        </div>
      )}
    </div>
  );
}

export default HeightMenu;
