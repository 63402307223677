import React, { useState } from "react";
import ImageSelectElement from "./ImageSelectElement";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";

export default function ImageSelectCompCategories(props) {
  const [selected, setSelected] = useState(-1);
  const [hovered, setHovered] = useState(-1);
  const parametricData = useParametricData();
  const updateParametricData = useParametricUpdate();

  const oneWidthComponents = props.componentData.Images.filter(
    (category) => category.CategoryNumber === 1
  );
  const twoWidthComponents = props.componentData.Images.filter(
    (category) => category.CategoryNumber === 2
  );

  function setParametricData(obj, parametricData) {
    for (var i in obj) {
      if (typeof obj[i] === "object")
        setParametricData(obj[i], parametricData[i]);
      else parametricData[i] = obj[i];
    }

    updateParametricData({ ...parametricData });
  }

  function sendMessage(data) {
    data["messageType"] = "SetValue";
    props.inputEmitter.EmitUIInteraction(data);
  }

  function renderOneWidth() {
    return oneWidthComponents.map((element, index) => {
      return (
        <ImageSelectElement
          key={index}
          componentData={element}
          onEnter={() => setHovered(props.componentData[index])}
          onClick={() => {
            setSelected(element.Index);
            sendMessage(element.Value);
            setParametricData(element["Value"], parametricData);
          }}
          onExit={() => {}}
          selected={selected === element.Index}
          hovered={hovered === props.componentData[index]}
        />
      );
    });
  }

  function renderTwoWidth() {
    return twoWidthComponents.map((element, index) => {
      return (
        <ImageSelectElement
          key={index}
          componentData={element}
          onEnter={() => setHovered(props.componentData[index])}
          onClick={() => {
            setSelected(element.Index);
            sendMessage(element.Value);
            setParametricData(element["Value"], parametricData);
          }}
          onExit={() => {}}
          selected={selected === element.Index}
          hovered={hovered === props.componentData[index]}
        />
      );
    });
  }

  return (
    <>
      <h3 style={{ textAlign: "center" }}>1 width</h3>
      <div className="lookContainer">{renderOneWidth()}</div>
      <h3 style={{ textAlign: "center" }}>2 widths</h3>
      <div className="lookCategoryContainer">{renderTwoWidth()}</div>
    </>
  );
}
