import React from "react";
import Modal from "react-modal";

import EKey from "../../assets/icons/controls/e.png";
import WKey from "../../assets/icons/controls/w.png";
import AKey from "../../assets/icons/controls/a.png";
import SKey from "../../assets/icons/controls/s.png";
import DKey from "../../assets/icons/controls/d.png";
import QKey from "../../assets/icons/controls/q.png";
import Right from "../../assets/icons/controls/right.png";
import Scroll from "../../assets/icons/controls/scroll.png";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import {
  StyledControlsContentContainer,
  StyledControlsListContainer,
  StyledControlsImportance,
  StyledControlsImage,
  StyledCloseButtonParent,
} from "../../styles/HelpModalContent.styled";

import "swiper/modules/pagination/pagination.min.css";

import HelpButton from "./HelpButton";

const customStyles = {
  content: {
    width: "50%",
    height: "auto",
    top: "50%",
    left: "40%",
    bottom: "auto",
    transform: "translate(-30%, -50%)",
  },
  overlay: { zIndex: 1000 },
};

const controlsData = [
  {
    image: WKey,
    importance: "FORWARD ",
    description: "- Moves the camera forward.",
  },
  {
    image: SKey,
    importance: "BACK",
    description: " - Moves the camera back.",
  },
  {
    image: AKey,
    importance: "LEFT",
    description: " - Moves the camera left.",
  },
  {
    image: DKey,
    importance: "RIGHT",
    description: " - Moves the camera right.",
  },
  {
    image: EKey,
    importance: "DOWN",
    description: " - Moves the camera down.",
  },

  {
    image: QKey,
    importance: "UP",
    description: " - Moves the camera up.",
  },

  {
    image: Right,
    importance: "ROTATE",
    description:
      " - Hold down the right mouse button and move the mouse to rotate the camera.",
  },

  {
    image: Scroll,
    importance: "ZOOM",
    description: " - Use the scroll wheel to zoom the camera in and out.",
  },
];

Modal.setAppElement("#root");
SwiperCore.use([Navigation, Pagination]);

export default function ControlsHelpModal({ onClickClose }) {
  return (
    <Modal isOpen={true} style={customStyles}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>{"Controls"}</h2>
        <HelpButton clickable={false} />
      </div>
      <br />
      <StyledControlsListContainer>
        {controlsData.map((item) => {
          return (
            <StyledControlsContentContainer>
              <StyledControlsImage src={item.image} alt="Keyboard key" />
              <StyledControlsImportance>
                {item.importance}
              </StyledControlsImportance>
              <div>{item.description}</div>
            </StyledControlsContentContainer>
          );
        })}
      </StyledControlsListContainer>
      <StyledCloseButtonParent>
        <button className="modal-button" onClick={onClickClose}>
          Close
        </button>
      </StyledCloseButtonParent>
    </Modal>
  );
}
