import React, { useState } from "react";
import Modal from "react-modal";

import axios from "axios";
import { Auth } from "aws-amplify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export function TempModal(props) {
  const [value, setValue] = useState("");
  const [saveRequestClicked, setSaveRequestClicked] = useState(false);

  const [contactName, setContactName] = useState("");
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const handleClick = () => {
    setSaveRequestClicked(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const session = await Auth.currentSession();
      const authString = session.getIdToken().getJwtToken();

      const headers = {
        Authorization: authString ? authString : "",
      };
      axios.post(
        "https://mu0q7g8j95.execute-api.eu-west-2.amazonaws.com/Prod/sendk40",
        {
          projectName: value,
          contactName: contactName,
          location: location,
          contactNumber: contactNumber,
        },
        { headers }
      );
    } catch (e) {
      console.log(e);
    }
    props.onModalClick(value);
    setSaveRequestClicked(false);

    //clears input values
    setValue("");
    setContactName("");
    setLocation("");
    setContactNumber("");
  };

  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      contentLabel={props.label}
    >
      {saveRequestClicked ? (
        <>
          <h2>Save & Request NBS Spec - K40</h2>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  margin: "20px 0",
                }}
              >
                <input
                  type="text"
                  id="contactName"
                  value={contactName}
                  className="modal-field-input"
                  placeholder="Contact Name"
                  onChange={(e) => setContactName(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  margin: "20px 0",
                }}
              >
                <input
                  type="text"
                  id="location"
                  value={location}
                  className="modal-field-input"
                  placeholder="Location of Project"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  margin: "20px 0",
                }}
              >
                <input
                  type="tel"
                  id="contactNumber"
                  value={contactNumber}
                  className="modal-field-input"
                  placeholder="Contact Number"
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <button type="submit" className="button">
                Submit
              </button>
            </form>
            <button
              className="button"
              style={{ marginTop: "10px" }}
              onClick={() => setSaveRequestClicked(false)}
            >
              Back
            </button>
          </div>
        </>
      ) : (
        <>
          <h2>{props.label}</h2>
          <div style={{ flexDirection: "row", display: "flex", width: 300 }}>
            {/* <div style={{ paddingRight: 10 }}>{props.fieldTitle}</div> */}
            <input
              type="text"
              style={{ borderColor: "#5fe1cb" }}
              onChange={(text) => {
                setValue(text.target.value);
              }}
              className="modal-field-input"
              placeholder={props.fieldTitle}
            />
          </div>
          <button
            disabled={value === ""}
            className="button"
            onClick={() => {
              props.onModalClick(value);
              setValue("");
            }}
          >
            {props.buttonTitle}
          </button>
          <button
            disabled={value === ""}
            className="button"
            onClick={handleClick}
          >
            <span style={{ fontSize: "12px" }}>
              Save & Request NBS Spec - K40
            </span>
          </button>
          <button
            className="button"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </>
      )}
    </Modal>
  );
}
