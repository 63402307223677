import React, { useState } from "react";
import Modal from "react-modal";

import button1Off from "../assets/icons/ceiling1Inactive.png";
import button2Off from "../assets/icons/ceiling2Inactive.png";
import button3Off from "../assets/icons/ceiling3Inactive.png";
import button4Off from "../assets/icons/ceiling4Inactive.png";
import button5Off from "../assets/icons/ceiling5Inactive.png";
import button6Off from "../assets/icons/ceiling6Inactive.png";
import button7Off from "../assets/icons/ceiling7Inactive.png";
import button8Off from "../assets/icons/ceiling8Inactive.png";
import squareWall from "../assets/icons/SonifySquareWall.png";
import circleWall from "../assets/icons/SonifyCircleWall.png";
import hexWall from "../assets/icons/SonifyHexagonWall.png";
import "../assets/css/modal.css";

import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import { useUser } from "../hooks/UseContext";
import client from "../client.json";
import {
  StyledLookContainerCeil,
  StyledOverallCeilContainer,
  StyledLabelsCeil,
  StyledReferenceCeil,
} from "../styles/CeilingModal.styled";

Modal.setAppElement("#root");

const CeilingModal = (props) => {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const user = useUser();

  const [item, setItem] = useState({ type: 0, isBaffleNexus: false });
  const [selectedIndex, setSelectedIndex] = useState(item);
  const [isOpen, setIsOpen] = useState(true);

  function setType() {
    parametricData.bMatrixCanopyOrBaffles = item.isBaffleNexus;
    parametricData.Type = item.type;
    setParametricData({ ...parametricData });
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function handleClick(id, baffleNexus, index) {
    setItem({
      type: id,
      isBaffleNexus: baffleNexus,
    });
    setSelectedIndex(index);
  }

  const CeilingData = [
    {
      id: 0,
      baffleNexus: false,
      name: "Canopy Island",
      image: button1Off,
      wallImage: squareWall,
      wallAbsorberCompatible: true,
      sonifyTiltCompatible: true,
      disableInLevels: [],
    },
    {
      id: 1,
      baffleNexus: false,
      name: "Canopy Circle Island",
      image: button3Off,
      wallImage: circleWall,
      wallAbsorberCompatible: true,
      sonifyTiltCompatible: false,
      disableInLevels: [],
    },
    {
      id: 2,
      baffleNexus: false,
      name: "Canopy Hexagon Island",
      image: button4Off,
      wallImage: hexWall,
      wallAbsorberCompatible: true,
      sonifyTiltCompatible: false,
      disableInLevels: [],
    },
    {
      id: 3,
      baffleNexus: false,
      name: "Baffle Islands",
      image: button7Off,
      wallAbsorberCompatible: false,
      sonifyTiltCompatible: false,
      disableInLevels: [6],
    },
    {
      id: 4,
      baffleNexus: false,
      name: "Baffle Shapes",
      image: button6Off,
      wallAbsorberCompatible: false,
      sonifyTiltCompatible: false,
      disableInLevels: [6],
    },
    {
      id: 5,
      baffleNexus: false,
      name: "Canopy Nexus",
      image: button2Off,
      wallAbsorberCompatible: false,
      sonifyTiltCompatible: false,
      disableInLevels: [6],
    },
    {
      id: 5,
      baffleNexus: true,
      name: "Baffle Nexus",
      image: button5Off,
      wallAbsorberCompatible: false,
      sonifyTiltCompatible: false,
      disableInLevels: [6],
    },
    {
      id: 6,
      baffleNexus: false,
      name: "Highway Runner",
      image: button8Off,
      wallAbsorberCompatible: false,
      sonifyTiltCompatible: false,
      disableInLevels: [6],
    },
  ];

  return (
    <Modal
      style={{
        content: {
          maxWidth: "850px",
          maxHeight: "100%",
          background: "#fff",
          overflow: "auto",
          outline: "none",
          padding: "20px",
        },
      }}
      isOpen={isOpen}
      onRequestClose={() => {}}
      className="modalMain"
      overlayClassName="modalOverlay"
    >
      <StyledOverallCeilContainer>
        <h2>Product Type</h2>
        <hr />
        <StyledLookContainerCeil>
          {CeilingData.map(
            (ceiling, index) =>
              !ceiling.disableInLevels.includes(parametricData.Level) && (
                <div
                  class="img-wrap "
                  onMouseEnter={() => {}}
                  onMouseLeave={() => {}}
                  onKeyUp={() => {}}
                  onClick={() =>
                    handleClick(ceiling.id, ceiling.baffleNexus, index)
                  }
                  role={"button"}
                  tabIndex={0}
                >
                  {index === selectedIndex && <div class="tick" />}
                  <img
                    class="img-inner scene"
                    src={
                      parametricData.Level === 6
                        ? ceiling.wallImage
                        : ceiling.image
                    }
                    style={
                      props.height && {
                        height: props.height,
                        width: props.width,
                      }
                    }
                    alt={ceiling.name}
                  />

                  <StyledLabelsCeil>
                    <div>
                      <span>{ceiling.name}</span>{" "}
                      {ceiling.wallAbsorberCompatible &&
                        parametricData.Level !== 6 && <sup>[1]</sup>}{" "}
                      {ceiling.sonifyTiltCompatible &&
                        parametricData.Level !== 6 && <sup>[2]</sup>}
                    </div>
                  </StyledLabelsCeil>
                </div>
              )
          )}
        </StyledLookContainerCeil>

        {parametricData.Level !== 6 && (
          <StyledReferenceCeil>
            <p>[1] - Compatible with Wall Absorber</p>
            <p>[2] - Compatible with Sonify Tilt</p>
          </StyledReferenceCeil>
        )}
        <hr />
        <br />
        <br />
        <div className="flexContainer">
          <button className="modal-button back" onClick={props.onBack}>
            Back
          </button>
          <button
            className="modal-button"
            onClick={() => {
              setType();
              props.onStart();
              props.inputEmitter.EmitUIInteraction({
                MessageType: "Initialize",
                level: parametricData.Level,
                user: user ? user.username : "",
                type: parametricData.Type,
                BaffleMatrix: parametricData.bMatrixCanopyOrBaffles,
              });
              toggleModal();
            }}
          >
            <span>Start</span>
          </button>
        </div>
      </StyledOverallCeilContainer>
    </Modal>
  );
};

export default CeilingModal;
