import React from "react";
import Modal from "react-modal";
import {
  StyledMainTitle,
  StyledUpdateModalContent,
} from "../../styles/UpdateModalContent.styled";

import fireworks from "../../assets/fireworks.png";

const customStyles = {
  content: {
    width: "50%",
    height: "auto",
    top: "50%",
    left: "40%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function UpdateModal({ onClose }) {
  return (
    <Modal isOpen={true} style={customStyles}>
      <StyledUpdateModalContent>
        <StyledMainTitle>
          <h1>What's New?</h1>
          <img src={fireworks} alt="" height="50px" width="50px" />
        </StyledMainTitle>
        <h3>Here's what we've done since your last visit...</h3>
        <ul>
          <li>
            Added single asset editing to the side menu by clicking the pencil.
          </li>
          <li>
            Allowed for customers to request an K40 spec for saved projects.
          </li>
          <li>
            Added a custom room scene to allow you to design your own rooms.
          </li>
        </ul>
        <button className="button" onClick={onClose}>
          Let's Go!
        </button>
      </StyledUpdateModalContent>
    </Modal>
  );
}
