import React, { useState } from "react";
import "./SceneControlMenu.css";
import { ReactComponent as CameraIcon } from "../assets/icons/CameraIcon.svg";
import { ReactComponent as BrightnessIcon } from "../assets/icons/brightnessIcon.svg";
import CameraView0 from "../assets/icons/CameraView0.png";
import CameraView1 from "../assets/icons/CameraView1.png";
import CameraView2 from "../assets/icons/CameraView2.png";
import CameraView3 from "../assets/icons/CameraView3.png";

import { useParametricData } from "../hooks/ParametricDataContext";
import { WiDaySunny, WiSunset, WiMoonWaxingCrescent4 } from "react-icons/wi";
import { BiCameraMovie } from "react-icons/bi";
import { BsBox } from "react-icons/bs";
import HelpButton from "./HelpModal/HelpButton";
function SceneControlMenu({ inputEmitter }) {
  const paramtricData = useParametricData();
  const [selectedItem, setSelectedItem] = useState(-1);
  const [emptyCanvas, setEmptyCanvas] = useState(false);

  function toggleCanvas() {
    const message = {
      MessageType: "Scene",
      SceneCommand: "Environment",
    };
    inputEmitter.EmitUIInteraction(message);
    setEmptyCanvas(!emptyCanvas);
  }

  function startAnimation() {
    const message = {
      MessageType: "Scene",
      SceneCommand: "Animation",
    };
    inputEmitter.EmitUIInteraction(message);
  }
  function selectItem(index) {
    setSelectedItem(index === selectedItem ? -1 : index);
  }
  return (
    <div className="scene-container">
      <div className="main-flex-container">
        <div className="control-display">
          {selectedItem === 0 && <CameraViewRow inputEmitter={inputEmitter} />}
          {selectedItem === 1 && <LightSlider inputEmitter={inputEmitter} />}
        </div>
        <div className="scene-button-row">
          <CameraIcon
            className="camera-icon"
            fill={selectedItem === 0 ? "#5fe1cb" : "white"}
            onClick={() => selectItem(0)}
          />
          {paramtricData.Level !== 6 && (
            <BrightnessIcon
              fill={selectedItem === 1 ? "#5fe1cb" : "white"}
              onClick={() => selectItem(1)}
              className="camera-icon"
            />
          )}
          <BsBox
            size={32}
            className="camera-icon"
            color={emptyCanvas ? "#5fe1cb" : "white"}
            onClick={() => {
              toggleCanvas();
            }}
          />
          {paramtricData.Level !== 6 && (
            <BiCameraMovie
              size={32}
              className="camera-icon"
              color={"white"}
              onClick={() => {
                startAnimation();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function CameraViewRow({ inputEmitter }) {
  function sendCameraMessage(index) {
    const message = {
      MessageType: "Scene",
      SceneCommand: "StaticCamera",
      Index: index,
    };
    inputEmitter.EmitUIInteraction(message);
  }
  return (
    <div className="camera-angle-container">
      <div
        class="img-wrap"
        onKeyUp={() => {}}
        onClick={() => sendCameraMessage(0)}
        role={"button"}
        tabIndex={0}
      >
        <div
          style={{
            width: 120,
            height: 80,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 24 }}>1</div>
        </div>
      </div>
      <div
        class="img-wrap"
        onKeyUp={() => {}}
        onClick={() => sendCameraMessage(1)}
        role={"button"}
        tabIndex={0}
      >
        <div
          style={{
            width: 120,
            height: 80,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 24 }}>2</div>
        </div>
      </div>
      <div
        class="img-wrap"
        onKeyUp={() => {}}
        onClick={() => sendCameraMessage(2)}
        role={"button"}
        tabIndex={0}
      >
        <div
          style={{
            width: 120,
            height: 80,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 24 }}>3</div>
        </div>
      </div>
      <div
        class="img-wrap"
        onKeyUp={() => {}}
        onClick={() => sendCameraMessage(3)}
        role={"button"}
        tabIndex={0}
      >
        <div
          style={{
            width: 120,
            height: 80,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 24 }}>4</div>
        </div>
      </div>
    </div>
  );
}

function LightSlider({ inputEmitter }) {
  function updateSlider(value) {
    const message = {
      MessageType: "Scene",
      SceneCommand: "Lighting",
      Light: value,
    };
    inputEmitter.EmitUIInteraction(message);
    setValue(value);
  }
  const [value, setValue] = useState(0);
  return (
    <div>
      <div className="light-icons">
        <WiDaySunny color="white" size={40} />
        <WiSunset color="white" size={40} />
        <WiMoonWaxingCrescent4 color="white" size={40} />
      </div>
      <div className="slider" style={{ paddingLeft: 10 }}>
        <input
          type="range"
          min={0}
          max={100}
          value={value}
          step={1}
          onChange={(e) => {
            updateSlider(e.target.value);
          }}
        ></input>
      </div>
    </div>
  );
}

export default SceneControlMenu;
