import React from "react";

function PalleteDisplayComponent(props) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: 443,
        overflowY: "scroll",
      }}
    >
      {props.palletes.map((pallete, index) => (
        <div style={{ flex: 1, marginTop: 10 }}>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                props.onPalleteSelected(index);
              }}
              role="button"
              onKeyUp={() => {}}
              tabIndex={0}
              className="img-wrap"
              style={{ padding: 0, marginRight: 10 }}
            >
              {props.selectedPallete === index && (
                <div cancel className="tick" />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                {pallete.colours.map((colour, colIndex) => {
                  return (
                    colIndex < props.count && (
                      <div
                        style={{
                          height: 36,
                          width: 26,
                          backgroundColor: `rgb(${props.colours[colour].r}, ${props.colours[colour].g},${props.colours[colour].b})`,
                        }}
                      ></div>
                    )
                  );
                })}
              </div>
            </div>
            <div>{pallete.name}</div>
          </div>
          <div
            style={{
              width: "96%",
              height: 1,
              backgroundColor: "#C1C7CD",
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default PalleteDisplayComponent;
