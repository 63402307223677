import styled from "styled-components";

export const StyledOverallContainer = styled.div``;

export const StyledLookContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledLabels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
`;

export const StyledReferenceLabel = styled.div`
  margin-top: 20px;
  font-size: 11px;
  color: gray;
  margin-left: 5px;
`;
