import React, { useContext, useState } from "react";
import { parametricData } from "../globals/GolbalConfig";

const ParametricContext = React.createContext();
const ParametricUpdateContext = React.createContext();

export function useParametricData() {
  return useContext(ParametricContext);
}

export function useParametricUpdate() {
  return useContext(ParametricUpdateContext);
}
export function ParametricDataProvider({ children }) {
  const [currentParametricData, setParametricData] = useState({
    ...parametricData,
  });
  function setData(data) {
    console.log(data);
    parametricData.Level = data.Level;
    setParametricData(data);
  }
  return (
    <ParametricContext.Provider value={currentParametricData}>
      <ParametricUpdateContext.Provider value={setData}>
        {children}
      </ParametricUpdateContext.Provider>
    </ParametricContext.Provider>
  );
}
