import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Amplify, Auth, auth0SignInButton } from "aws-amplify";
import "../assets/css/modal.css";
import { useSetUser, useUser } from "../hooks/UseContext";
import { Loader } from "semantic-ui-react";
import axios from "axios";

Modal.setAppElement("#root");

export default function AuthModal(props, { updateJourney }) {
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const updateUser = useSetUser();
  const [codeEmail, setCodeEmail] = useState("");
  const [signingIn, setSigningIn] = useState(props.signIn);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  function toggleSigningIn(props) {
    setSigningIn(!signingIn);
  }

  useEffect(() => {
    if (user && props.closeOnSignIn) {
      toggleModal();
    }
  }, [user]);

  async function onMainButtonClicked() {
    if (signingIn) {
      try {
        setLoading(true);
        const newuser = await Auth.signIn(
          formData.user.email,
          formData.user.password
        );

        setLoading(false);
        if (newuser) {
          props.onSignedIn();
        }
      } catch (e) {
        if (e.code === "UserNotConfirmedException") {
          setCodeEmail(formData.user.email);
        }
        setErrorMessage(e.message);
        setLoading(false);
      }
    } else if (!signingIn) {
      if (formData.user.password !== formData.user.confirmPassword) {
        setErrorMessage("Passwords do not match!");
        return;
      }

      try {
        setLoading(true);
        const signUpData = await Auth.signUp({
          username: formData.user.email,
          password: formData.user.password,
          attributes: {
            email: formData.user.email,
            "custom:Company": formData.user.company,
            "custom:Configs": "0",
            "custom:MailList": formData.user.mailingList ? "1" : "0",
            "custom:gdpr": formData.user.gdpr ? "1" : "0",
            name: formData.user.name,
          },
        });
        Auth.currentSession().then((res) => console.log(res));
        console.log(signUpData.user.getse);
        if (signUpData.user) {
        }
        setCodeEmail(formData.user.email);
        setLoading(false);
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    }
  }
  async function onEnterCode() {
    try {
      setLoading(true);
      const newuser = await Auth.confirmSignUp(
        formData.user.email,
        formData.user.code
      );
      setLoading(false);
      if (newuser === "SUCCESS") {
        setCodeEmail("");
        setSigningIn(true);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const email = user ? user.attributes.email : "";
  const [formData, setFormData] = useState({
    user: {
      email: "",
      password: "",
      code: "",
      confirmPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      name: "",
      company: "",
      mailingList: false,
      gdpr: false,
    },
  });

  const updateState = (e) => {
    const field = e.target.name;
    const user = formData.user;
    user[field] = e.target.value;

    setFormData({ user });
    console.log(user);
  };
  const [passwordResetState, setPasswordResetState] = useState(-1);
  function renderResetPasswordScreen() {
    if (passwordResetState === 0) {
      return (
        <div style={{ boxSizing: "inherit" }}>
          <h2>Password reset</h2>
          <hr />
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              try {
                const success = await Auth.forgotPassword(formData.user.email);
                if (success) {
                  setPasswordResetState(1);
                }
              } catch (e) {
                setErrorMessage(e.message);
              }

              return false;
            }}
          >
            <div className="flexContainer modal-field-container">
              <input
                id="email"
                autoComplete="email"
                tabIndex={0}
                className="modal-field-input"
                onChange={updateState}
                type={"email"}
                name="email"
                placeholder="email"
                value={formData.user.email}
              />
            </div>

            <br />
            <div style={{ textAlign: "center", color: "red" }}>
              {errorMessage}
            </div>
            <hr />

            <div className="flexContainer">
              <button
                type="button"
                className="modal-button back"
                style={{ marginLeft: 0 }}
                disabled={loading}
                onClick={() => {
                  setPasswordResetState(-1);
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="modal-button login"
                style={{ marginLeft: 0 }}
                disabled={loading}
                onClick={() => {
                  setPasswordResetState(1);
                }}
              >
                Have Code
              </button>
              <button
                className="modal-button"
                disabled={loading}
                style={{ marginLeft: 0 }}
              >
                Request Code
              </button>
            </div>
          </form>
        </div>
      );
    }
    if (passwordResetState === 1) {
      return (
        <div style={{ boxSizing: "inherit" }}>
          <h2>Password reset</h2>
          <hr />
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              try {
                const success = await Auth.forgotPasswordSubmit(
                  formData.user.email,
                  formData.user.code,
                  formData.user.newPassword
                );

                if (success) {
                  setPasswordResetState(-1);
                  setErrorMessage("");
                }
              } catch (e) {
                setErrorMessage(e.message);
              }

              return false;
            }}
          >
            <div className="flexContainer modal-field-container">
              <input
                autoComplete="off"
                className="modal-field-input"
                type={"password"}
                placeholder="new password"
                name="newPassword"
                value={formData.user.newPassword}
                onChange={updateState}
              />
            </div>
            <div className="flexContainer modal-field-container">
              <input
                autoComplete="off"
                className="modal-field-input"
                type={"password"}
                placeholder="confirm password"
                name="newConfirmPassword"
                value={formData.user.newConfirmPassword}
                onChange={updateState}
              />
            </div>
            <div className="flexContainer modal-field-container">
              <input
                autoComplete="off"
                className="modal-field-input"
                type={"text"}
                placeholder="code"
                name="code"
                value={formData.user.code}
                onChange={updateState}
              />
            </div>
            <br />
            <div style={{ textAlign: "center", color: "red" }}>
              {errorMessage}
            </div>
            <hr />

            <div className="flexContainer">
              <button
                className="modal-button"
                disabled={loading}
                style={{ marginLeft: 0 }}
              >
                Reset
              </button>
              <button
                className="modal-button back"
                style={{ marginLeft: 0 }}
                disabled={loading}
                onClick={() => {
                  setPasswordResetState(0);
                }}
              >
                Back
              </button>
            </div>
          </form>
        </div>
      );
    }
  }
  return (
    <Modal
      appElement={document.getElementById("ui-parent")}
      style={{
        content: {
          width: 480,

          background: "#fff",
          overflow: "auto",
          outline: "none",
          padding: "20px",
        },
      }}
      isOpen={true}
      onRequestClose={props.onClose}
      className="modalMain"
      overlayClassName="modalOverlay"
    >
      <Loader active={loading}></Loader>

      {passwordResetState >= 0 ? (
        renderResetPasswordScreen()
      ) : user ? (
        <div style={{ boxSizing: "inherit" }}>
          <h2>Welcome!</h2>
          <hr />

          <>
            <div
              className="flexContainer"
              style={{ justifyContent: "center", marginTop: 50 }}
            >
              <div
                style={{ textAlign: "center", paddingRight: 10, fontSize: 18 }}
              >
                {" "}
                {"You are logged in as " + email}
              </div>
            </div>

            <br />

            <hr />

            <div className="flexContainer">
              <button
                className="modal-button login"
                disabled={loading}
                style={{ marginLeft: 0 }}
                onClick={() => {
                  Auth.signOut();
                  updateUser(null);
                }}
              >
                Log out
              </button>
              <button
                className="modal-button"
                disabled={loading}
                style={{ marginLeft: 0 }}
                onClick={props.onClose}
              >
                Close
              </button>
            </div>
          </>
        </div>
      ) : (
        <div style={{ boxSizing: "inherit" }}>
          <h2>
            {signingIn
              ? "Log in to Sonify 3D Studio"
              : "Register for Sonify 3D Studio"}
          </h2>
          <hr />

          {codeEmail !== "" ? (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                return false;
              }}
            >
              <div
                className="flexContainer"
                style={{ justifyContent: "center", marginTop: 50 }}
              >
                <div style={{ paddingRight: 10, fontSize: 18 }}>
                  {" "}
                  {"Enter the code sent to " + codeEmail}
                </div>
              </div>
              <div className="flexContainer modal-field-container">
                <input
                  tabIndex={0}
                  className="modal-field-input"
                  name="code"
                  type={"text"}
                  placeholder="code"
                  value={formData.user.code}
                  onChange={updateState}
                />
              </div>

              <br />
              <button
                type="button"
                className="button"
                onClick={() => {
                  Auth.resendSignUp(codeEmail);
                }}
              >
                resend code.
              </button>
              <hr />

              <div className="flexContainer">
                <button
                  type="button"
                  className="modal-button back"
                  disabled={loading}
                  style={{ marginLeft: 0 }}
                  onClick={() => {
                    setCodeEmail("");
                  }}
                >
                  Back
                </button>
                <button
                  className="modal-button"
                  disabled={loading}
                  style={{ marginLeft: 0 }}
                  onClick={onEnterCode}
                >
                  <span> Confirm</span>
                </button>
              </div>
            </form>
          ) : (
            <form
              onSubmit={async (e) => {
                onMainButtonClicked();
                e.preventDefault();
                return false;
              }}
            >
              <div className="flexContainer modal-field-container">
                <input
                  id="email"
                  autoComplete="email"
                  tabIndex={0}
                  className="modal-field-input"
                  type={"email"}
                  placeholder="email"
                  name="email"
                  value={formData.user.email}
                  onChange={updateState}
                />
              </div>
              {!signingIn && (
                <div
                  className={`flexContainer modal-field-container ${
                    signingIn && "hidden-field"
                  }`}
                >
                  <input
                    autoComplete="off"
                    className="modal-field-input"
                    type={"text"}
                    placeholder="Full Name"
                    name="name"
                    value={formData.user.name}
                    onChange={updateState}
                  />
                </div>
              )}
              {!signingIn && (
                <div
                  className={`flexContainer modal-field-container ${
                    signingIn && "hidden-field"
                  }`}
                >
                  <input
                    autoComplete="off"
                    className="modal-field-input"
                    type={"text"}
                    placeholder="Company"
                    name="company"
                    value={formData.user.company}
                    onChange={updateState}
                  />
                </div>
              )}
              <div className="flexContainer modal-field-container">
                <input
                  id="password"
                  autoComplete={signingIn ? "current-password" : "new-password"}
                  name="password"
                  className="modal-field-input"
                  type={"password"}
                  placeholder="password"
                  value={formData.user.password}
                  onChange={updateState}
                />
              </div>
              <div
                className={`flexContainer modal-field-container ${
                  signingIn && "hidden-field"
                }`}
              >
                <input
                  autoComplete="off"
                  className="modal-field-input"
                  type={"password"}
                  placeholder="confirm password"
                  name="confirmPassword"
                  value={formData.user.confirmPassword}
                  onChange={updateState}
                />
              </div>
              {!signingIn && (
                <div
                  className={`flexContainer modal-field-container ${
                    signingIn && "hidden-field"
                  }`}
                  style={{ marginBottom: 30, alignItems: "center" }}
                >
                  <input
                    style={{ height: 20, width: 20 }}
                    autoComplete="off"
                    className="modal-field-input"
                    type={"checkbox"}
                    name="mailingList"
                    defaultChecked={formData.user.mailingList}
                    onChange={() => {
                      const user = formData.user;
                      user.mailingList = !user.mailingList;

                      setFormData({ user });
                    }}
                  />
                  <div
                    style={{
                      paddingLeft: 5,
                      fontSize: 18,
                      textAlign: "left",
                      width: 400,
                    }}
                  >
                    Stay up-to-date with the latest Zentia news by subscribing
                    to our mailing list
                  </div>
                </div>
              )}
              {!signingIn && (
                <div
                  className={`flexContainer modal-field-container ${
                    signingIn && "hidden-field"
                  }`}
                  style={{ marginBottom: 30, alignItems: "center" }}
                >
                  <input
                    style={{ height: 20, width: 20 }}
                    autoComplete="off"
                    className="modal-field-input"
                    type={"checkbox"}
                    name="gdpr"
                    defaultChecked={formData.user.gdpr}
                    onChange={() => {
                      const user = { ...formData.user };
                      user.gdpr = !user.gdpr;

                      setFormData({ user });
                    }}
                  />
                  <div
                    style={{
                      paddingLeft: 5,
                      fontSize: 18,
                      textAlign: "left",
                      width: 400,
                    }}
                  >
                    by using this website you agree to the terms of usage as set
                    out in our usages policy and privacy policy.
                  </div>
                </div>
              )}
              <div
                hidden={!signingIn}
                className="flexContainer"
                style={{ justifyContent: "left" }}
              >
                <div style={{ paddingRight: 10, fontSize: 18 }}>
                  Forgot password?{" "}
                </div>{" "}
                <div
                  className="modal-click"
                  role={"button"}
                  onKeyUp={() => {}}
                  tabIndex={0}
                  onClick={() => {
                    setPasswordResetState(0);
                  }}
                >
                  Click here to reset
                </div>
              </div>
              <div
                className="flexContainer"
                style={{ justifyContent: "left", marginTop: 30 }}
              >
                <div style={{ paddingRight: 10, fontSize: 18 }}>
                  {signingIn ? "Need to sign up?" : "Already have an account?"}
                </div>{" "}
                <div
                  className="modal-click"
                  role={"button"}
                  onKeyUp={() => {}}
                  tabIndex={0}
                  onClick={toggleSigningIn}
                >
                  Click here
                </div>
              </div>
              <br />
              <div style={{ textAlign: "center", color: "red" }}>
                {errorMessage}
              </div>
              {!signingIn && (
                <div style={{ textAlign: "center" }}>
                  <a
                    href="https://www.zentia.com/en-gb/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{" "}
                  to view our privacy policy
                </div>
              )}
              <hr />

              <div
                className="flexContainer"
                style={{ flexDirection: "row-reverse" }}
              >
                <button
                  className="modal-button"
                  disabled={loading || (!formData.user.gdpr && !signingIn)}
                  style={{ marginLeft: 0 }}
                >
                  Next
                </button>
                <button
                  className="modal-button back"
                  style={{ marginLeft: 0 }}
                  disabled={loading}
                  onClick={() => {
                    props.onBack();
                  }}
                >
                  Back
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
