import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../assets/css/modal.css";

import { useUser } from "../hooks/UseContext";
import { useSavesData } from "../hooks/SavesHook";
import "../index.css";
import DynamicQRCode from "./DynamicQRCode";

function WelcomeModal(props, { updateJourney }) {
  const saves = useSavesData();
  const [isOpen, setIsOpen] = useState(true);
  const user = useUser();
  useEffect(() => {}, []);

  return (
    <Modal
      appElement={document.getElementById("ui-parent")}
      style={{
        content: {
          width: "80%",

          background: "#fff",
          overflow: "auto",
          outline: "none",
          padding: "20px",
        },
      }}
      isOpen={isOpen}
      onRequestClose={() => {}}
      className="modalMain"
      overlayClassName="modalOverlay"
    >
      {user ? (
        <div>
          <h2> Welcome to the Sonify 3D Studio</h2>
          <hr />

          <h2>Welcome back {user.attributes.email}!</h2>
          <h3>
            Press continue to carry on where you left off, or start over to set
            up a new scene.
          </h3>
          <br></br>
          <h3>If this is not the correct account then sign out below.</h3>
          <hr />
          {saves.length === 0 ? (
            <div className="flexContainer">
              <button className="modal-button login" onClick={props.onSignOut}>
                Sign out
              </button>

              <button className="modal-button" onClick={props.onStart}>
                Start Creating
              </button>
            </div>
          ) : (
            <div className="flexContainer">
              <button className="modal-button login" onClick={props.onSignOut}>
                Sign out
              </button>
              <button className="modal-button login" onClick={props.onStart}>
                Start over
              </button>
              <button className="modal-button" onClick={props.onContinue}>
                Continue
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Welcome to the Sonify 3D Studio</h2>
          <hr />
          <h4>
            Sonify 3D Studio uses the latest technology to offer a
            state-of-the-art ceiling design tool to architects and specifiers.
            Embracing digital planning and parametric design, large scale and
            complex discontinuous ceilings are now easy to specify without
            limiting creativity. Are you ready to make your mark?
          </h4>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://player.vimeo.com/video/861543577?h=f13621f84f"
              frameborder="0"
              allowfullscreen
              title="intro_video"
              allow="fullscreen;"
            ></iframe>
          </div>

          <hr />
          <div className="flexContainer" style={{ position: "relative" }}>
            <button className="modal-button login link" onClick={props.onLogin}>
              <span>Log In</span>
            </button>
            <button className="modal-button" onClick={props.onRegister}>
              <span>Register</span>
            </button>
            <button className="modal-button" onClick={props.onStart}>
              <div>
                <span>Start Creating</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default WelcomeModal;
