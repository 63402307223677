import React, { useEffect, useReducer } from "react";
import SliderComponent from "./SliderComponent";
import { checkParaData } from "../globals/GolbalConfig";
import { useParametricData } from "../hooks/ParametricDataContext";

function RandomDistributer(props) {
  const parametricData = useParametricData();

  const [state, dispatch] = useReducer(reducer, {
    visible:
      !props.componentData.Hidden ||
      !checkParaData(parametricData, props.componentData.Hidden),
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setVisible":
        return { ...state, visible: action.payload };
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    dispatch({
      type: "setVisible",
      payload:
        !props.componentData.Hidden ||
        !checkParaData(parametricData, props.componentData.Hidden),
    });
  }, [parametricData, props.componentData.Hidden]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            paddingRight: 10,
            visibility: state.visible ? "visible" : "hidden",
          }}
        >
          {props.componentData.LeftName}
        </div>
        <SliderComponent
          componentData={{ ...props.componentData }}
          inputEmitter={props.inputEmitter}
        />
        <div
          style={{
            paddingLeft: 10,
            visibility: state.visible ? "visible" : "hidden",
          }}
        >
          {props.componentData.RightName}
        </div>
      </div>
    </>
  );
}

export default RandomDistributer;
