import React, { useState } from "react";

export default function EnableCheckbox({ onClick, enabled }) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <button style={{ background: "none", border: "none" }} onClick={onClick}>
        <div
          style={{
            borderRadius: "50%",
            borderWidth: 2,
            borderColor: enabled ? "#32B8A1" : "black",
            borderStyle: "solid",
            width: 16,
            height: 16,
            alignContent: "center",
            padding: "2px 2px",
            cursor: "pointer",
          }}
        >
          {enabled && (
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor: "#32B8A1",
                borderRadius: "50%",
              }}
            ></div>
          )}
        </div>
      </button>

      <div style={{ paddingLeft: 5 }}>{enabled ? "Enabled" : "Disabled"}</div>
    </div>
  );
}
