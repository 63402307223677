import React, { useEffect, useState } from "react";
import { checkParaData } from "../globals/GolbalConfig";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import ImageSelectElement from "./ImageSelectElement";

function checkIfObjectAreEqual(object1, object2) {
  let equal = true;
  Object.keys(object1).every((key) => {
    if (typeof object1[key] === "object") {
      equal = checkIfObjectAreEqual(object1[key], object2[key]);
      if (!equal) return false;
    } else {
      equal = object1[key] === object2[key];
      if (!equal) return false;
    }
    return true;
  });
  return equal;
}

function ImageSelectComponent(props) {
  function setParametricData(obj, parametricData) {
    for (var i in obj) {
      if (typeof obj[i] === "object")
        setParametricData(obj[i], parametricData[i]);
      else parametricData[i] = obj[i];
    }

    updateParametricData({ ...parametricData });
  }

  function getInitialState() {
    let returnIndex = -1;
    if (props.componentData.KeepState) {
      props.componentData.Images.every((imageData, index) => {
        if (checkIfObjectAreEqual(imageData.Value, parametricData)) {
          returnIndex = index;
          return false;
        }
        return true;
      });
    }
    return returnIndex;
  }

  const parametricData = useParametricData();
  const updateParametricData = useParametricUpdate();
  const [selected, setSelected] = useState(getInitialState());
  const [hovered, setHovered] = useState(-1);
  const [selectedDimensions, setSelectedDimensions] = useState("1200x1200mm");

  useEffect(() => {
    if (props.componentData.UpdateState) {
      let returnIndex = -1;

      props.componentData.Images.every((imageData, index) => {
        if (checkIfObjectAreEqual(imageData.Value, parametricData)) {
          returnIndex = index;
          return false;
        }
        return true;
      });
      if (returnIndex !== selected) setSelected(returnIndex);
    }
  }, [parametricData]);

  function sendMessage(data) {
    data["messageType"] = "SetValue";
    props.inputEmitter.EmitUIInteraction(data);
    console.log(data);
  }
  function renderImages() {
    return props.componentData.Images.map((element, index) => {
      if (
        (element.Hidden && checkParaData(parametricData, element.Hidden)) ||
        (element.Visible && !checkParaData(parametricData, element.Visible))
      ) {
        return <></>;
      }
      return (
        <ImageSelectElement
          height={props.componentData.Height}
          width={props.componentData.Width}
          key={index}
          componentData={element}
          onEnter={() => {
            setHovered(index);
          }}
          onExit={() => {
            setHovered(-1);
          }}
          onClick={() => {
            setSelected(index);
            sendMessage(element.Value);
            if (props.onImageChange) props.onImageChange(index);
            if (props.componentData.SubType) {
              props.changeSubType(
                element.Text.includes("Interlocked")
                  ? "Interlocked"
                  : element.Text
              );
            }
            if (props.Type) {
              props.changeType(props.Type);
            }
            setParametricData(element["Value"], parametricData);
            setSelectedDimensions(element.RevitText ? element.RevitText : "");
          }}
          selected={selected === index}
          hovered={hovered === index}
        />
      );
    });
  }

  return (
    <div>
      <div className="lookContainer">{renderImages()}</div>
      {props.componentData.ShowDimensions && (
        <div style={{ textAlign: "center", color: "grey" }}>
          Dimensions: {selectedDimensions}
        </div>
      )}
    </div>
  );
}
ImageSelectComponent.defaultProps = {
  initialValue: -1,
};
export default ImageSelectComponent;
