import React, { useState } from "react";
import ProductTypeDropdown from "./ProductTypeDropdown";
import { useUser } from "../hooks/UseContext";
import { BsArrowsMove, BsTrash, BsEmojiSmile } from "react-icons/bs";
import clientConfig from "../client.json";
import { Tooltip } from "react-tippy";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import {
  StyledCard,
  StyledCardHeader,
  StyledCardBody,
  StyledActionButton,
  StyledSelectBox,
  StyledRaftButton,
  StyledTypeSelectTick,
  StyledBodySubContainer,
} from "../styles/RaftCard.styled";
import {
  UseRaftCardsContext,
  UseSetRaftCardsContext,
} from "../hooks/RaftCardsContext";

const Checked = ({ isChecked, onSelectRaft }) => {
  return (
    <Tooltip
      title="Select"
      position="left"
      arrow={true}
      size="small"
      animation="perspective"
      disabled={isChecked}
      offset={-10}
    >
      <StyledSelectBox isChecked={isChecked} onClick={onSelectRaft}>
        {isChecked && <span style={{ color: "white" }}>✓</span>}
      </StyledSelectBox>
    </Tooltip>
  );
};

const TypeChecked = ({ isChecked }) => {
  return (
    <StyledTypeSelectTick isChecked={isChecked}>
      {isChecked && <span style={{ color: "white", fontSize: 10 }}>✓</span>}
    </StyledTypeSelectTick>
  );
};

export default function RaftCard({
  raft,
  onClickBoQ,
  onDeleteRaft,
  onSelectRaft,
  onMoveRaft,
  selected,
  raftsRemaining,
  index,
  inputEmitter,
  movingRaft,
  pdfIsLoading,
}) {
  const setRaftContext = UseSetRaftCardsContext();
  const raftContext = UseRaftCardsContext();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const user = useUser();

  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const onDropdownChanged = (message, newDropdownIndex) => {
    let messsageCopy = { ...message };
    messsageCopy.Index = index;
    console.log(messsageCopy);
    inputEmitter.EmitUIInteraction(messsageCopy);
    if (selected === index) {
      inputEmitter.EmitUIInteraction({
        messageType: "SelectRaft",
        Index: index,
      });
    }
    setRaftContext(
      {
        raftType: raftContext[index] !== null ? raftContext[index].raftType : 0,
        dropdownIndex: newDropdownIndex,
      },
      index
    );
  };

  function sendPDFMessage() {
    if (!pdfIsLoading) {
      var message = { messageType: "packingList", Index: index };
      inputEmitter.EmitUIInteraction(message);
    }
  }

  function changeType(type) {
    inputEmitter.EmitUIInteraction({
      RaftType: type,
      messageType: "SetRaftType",
      Index: index,
    });
    setRaftContext(
      {
        raftType: type,
        dropdownIndex: 0,
      },
      index
    );
    if (isSelected) setParametricData({ ...parametricData, RaftType: type });
  }

  function handleDelete() {
    setShowConfirmDelete(false);
    onDeleteRaft();
  }

  const isSelected = selected === index;
  const isMoving = movingRaft.includes(index);

  console.log(user);
  return (
    <StyledCard isSelected={isSelected}>
      <StyledCardHeader>
        <h3>{raft.name}</h3>
        <Checked isChecked={isSelected} onSelectRaft={onSelectRaft} />
      </StyledCardHeader>

      <StyledCardBody>
        <StyledBodySubContainer>
          <ProductTypeDropdown
            inputEmitter={inputEmitter}
            onDropdownChanged={onDropdownChanged}
            key={"Dropdown" + index}
            index={index}
            isWall={
              raftContext[index].raftType == 1 || parametricData.Level === 6
            }
            choiceIndex={raftContext[index].dropdownIndex}
          />

          {/* ceiling and wall selection */}
          {parametricData.Level == 5 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "15px",
                width: "100%",
              }}
            >
              <StyledRaftButton
                selected={raftContext[index].raftType === 0}
                onClick={() => changeType(0)}
              >
                {raftContext[index].raftType === 0 && (
                  <span style={{ position: "absolute", right: 6, top: 5 }}>
                    <TypeChecked
                      isChecked={raftContext[index].raftType === 0}
                    />
                  </span>
                )}
                <img src="icons/ceil.png" alt="wall" />
                <span>Ceiling</span>
              </StyledRaftButton>

              <StyledRaftButton
                selected={raftContext[index].raftType === 1}
                onClick={() => changeType(1)}
              >
                {raftContext[index].raftType === 1 && (
                  <span style={{ position: "absolute", right: 6, top: 5 }}>
                    <TypeChecked
                      isChecked={raftContext[index].raftType === 1}
                    />
                  </span>
                )}
                <img src="icons/wall.png" alt="wall" />
                <span>Wall</span>
              </StyledRaftButton>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
              width: "100%",
            }}
          >
            {/* move button */}

            <StyledActionButton
              action="move"
              onClick={onMoveRaft}
              isMoving={isMoving}
            >
              <BsArrowsMove size={20} />
            </StyledActionButton>
            {/* delete button or confirm delete buttons */}
            {!showConfirmDelete ? (
              <StyledActionButton
                action="delete"
                onClick={() => setShowConfirmDelete(true)}
                disabled={raftsRemaining === 1}
              >
                <BsTrash size={20} />
              </StyledActionButton>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <button
                  onClick={() => setShowConfirmDelete(false)}
                  style={{
                    color: "red",
                  }}
                  className="raft-delete-confirm"
                >
                  X
                </button>
                <button
                  onClick={handleDelete}
                  style={{
                    color: "green",
                  }}
                  className="raft-delete-confirm"
                >
                  ✓
                </button>
              </div>
            )}
          </div>
          {/* <StyledDownloadLink
          document={<PDFDoc data={pdfData} />}
          fileName="Packing List"
        >
          {({ loading }) =>
            loading ? (
              <StyledActionButton action="pdf">Loading...</StyledActionButton>
            ) : (
              <StyledActionButton action="pdf">
                Packing List PDF
              </StyledActionButton>
            )
          }
        </StyledDownloadLink> */}
          {/* {user &&
          user.signInUserSession.accessToken.payload["cognito:groups"] &&
          user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "Admin"
          ) && (
            
          )} */}

          {user &&
            user.signInUserSession.accessToken.payload["cognito:groups"] &&
            user.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes("Admin") && (
              <StyledActionButton action="pdf" onClick={sendPDFMessage}>
                {pdfIsLoading ? "Downloading PDF..." : "Packing List PDF"}
              </StyledActionButton>
            )}
          {user &&
            user.signInUserSession.accessToken.payload["cognito:groups"] &&
            user.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes("Admin") && (
              <StyledActionButton action="boq" onClick={onClickBoQ}>
                Download BoQ
              </StyledActionButton>
            )}
          {/* <StyledActionButton action="boq" onClick={onClickBoQ}>
            Download BoQ
          </StyledActionButton> */}
        </StyledBodySubContainer>
      </StyledCardBody>
    </StyledCard>
  );
}
