import styled from "styled-components";

export const StyledConGuideContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const StyledSubContainer = styled.div`
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPageButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  gap: 1rem;
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledBoxHeader = styled.h1`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -1px;
`;

export const StyledLoadingSpinner = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 2px solid;
  border-color: #000 #000 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid;
    border-color: transparent transparent #3cdbc0 #3cdbc0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  &::before {
    width: 16px;
    height: 16px;
    border-color: #000 #000 transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;
