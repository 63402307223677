import React, { useState } from "react";
import { UseRaftCardsContext } from "../hooks/RaftCardsContext";

const options = [
  { value: "canopy_island", label: "Canopy Island", wallEnabled: true },
  {
    value: "canopy_circle_island",
    label: "Canopy Circle Island",
    wallEnabled: true,
  },
  {
    value: "canopy_hexagon_island",
    label: "Canopy Hexagon Island",
    wallEnabled: true,
  },
  { value: "baffle_islands", label: "Baffle Islands" },
  { value: "baffle_shapes", label: "Baffle Shapes" },
  { value: "canopy_nexus", label: "Canopy Nexus" },
  { value: "baffle_nexus", label: "Baffle Nexus" },
  { value: "highway_runner", label: "Highway Runner" },
];

const messages = {
  canopy_circle_island: {
    messageType: "ChangeType",
    Type: "Circles",
    SubType: "Grid",
  },
  canopy_island: {
    messageType: "ChangeType",
    Type: "Rectangle",
    SubType: "Grid",
    wallEnabled: true,
  },
  canopy_hexagon_island: {
    messageType: "ChangeType",
    Type: "Hexagon",
    SubType: "Grid",
    wallEnabled: true,
  },
  baffle_islands: {
    messageType: "ChangeType",
    Type: "BafflesIslands",
    SubType: "Grid",
  },
  baffle_shapes: {
    messageType: "ChangeType",
    Type: "BafflesShapes",
    BaffleZigZagType: 1,
    SubType: "Grid",
  },
  canopy_nexus: {
    messageType: "ChangeType",
    Type: "Matrix",
    SubType: "Grid",
    bMatrixCanopyOrBaffles: false,
  },
  baffle_nexus: {
    messageType: "ChangeType",
    Type: "Matrix",
    SubType: "Grid",
    bMatrixCanopyOrBaffles: true,
  },
  highway_runner: {
    messageType: "ChangeType",
    Type: "HighwayRunner",
    SubType: "Grid",
  },
};

export default function ProductTypeDropdown({
  inputEmitter,
  onDropdownChanged,
  index,
  isWall,
  choiceIndex,
}) {
  function handleSelect(e) {
    console.log(e.target);
    onDropdownChanged(messages[e.target.value], e.target.options.selectedIndex);
  }

  return (
    <select
      value={options[choiceIndex].value}
      onChange={handleSelect}
      className="dropdown"
      style={{ width: "100%" }}
    >
      {options.map(
        (option) =>
          (!isWall || option.wallEnabled) && (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
      )}
    </select>
  );
}
