import React from "react";
import SliderComponent from "./SliderComponent";

function PalleteSlider(props) {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        onClick={props.onClick}
        onKeyDown={() => {}}
        role={"button"}
        tabIndex={0}
        style={{
          cursor: "pointer",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "black",
          height: 36,
          width: 26,
          marginRight: 10,
          backgroundColor: `rgb(${props.colour.r}, ${props.colour.g},${props.colour.b})`,
        }}
      />
      <div style={{ width: 100 }}>{props.colour.swatchName}</div>

      <div
        style={{
          maxWidth: 160,
          paddingLeft: 6,
          visibility: props.hideSlider ? "hidden" : "visible",
        }}
      >
        <SliderComponent
          componentData={{
            Value: 100,
            Name: props.sliderValue,
            Min: 0,
            Max: 100,
            Units: "%",
            Step: 10,
            Multiplier: 1,
            Path: "Colours",
          }}
          inputEmitter={props.inputEmitter}
        />
      </div>
    </div>
  );
}

export default PalleteSlider;
