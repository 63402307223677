import React from "react";
import { icons } from "react-icons";

const style = StyleSheet.m;
function ImageSelectElement(props) {
  const state = props.selected ? "Active" : props.hovered ? "Over" : "Inactive";

  function renderText() {
    if (props.componentData.Text === undefined) {
      return;
    }
    return (
      <div>
        <div style={{ textAlign: "center" }}>{props.componentData.Text}</div>
        <div
          style={{
            display: "inline-block",
          }}
        >
          <span
            style={{
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {props.componentData.SubText ? props.componentData.SubText : ""}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        visibility: props.hidden ? "collapse" : "visible",

        border:
          props.componentData.AngledLook && state === "Active"
            ? "2px solid #3cdbc0"
            : "2px solid #fff",
        // border:
        //   state === "Active" &&
        //   props.componentData.AngledLook &&
        //   "2px solid #3cdbc0",
        borderRadius: "10px",
      }}
    >
      {props.componentData.AngledLook && renderText()}
      <div
        class="img-wrap"
        onMouseEnter={() => props.onEnter()}
        onMouseLeave={() => props.onExit()}
        onKeyUp={() => {}}
        onClick={() => props.onClick()}
        role={"button"}
        tabIndex={0}
        style={{
          border:
            state === "Over" &&
            !props.componentData.AngledLook &&
            "2px solid #5fe1cb",
          transform:
            state === "Over" && props.componentData.AngledLook && "scale(1.2)",
        }}
      >
        {state === "Active" && !props.componentData.AngledLook && (
          <div class="tick" />
        )}
        {!props.componentData.AngledLook ? (
          <img
            class="img-inner"
            src={"/icons/" + props.componentData.Icon + ".svg"}
            style={props.height && { height: props.height, width: props.width }}
            alt={props.componentData.Icon}
          />
        ) : (
          props.componentData.Icon.map((icon) => {
            return (
              <img
                class="img-inner"
                src={"/icons/" + icon + ".png"}
                style={{
                  height: props.componentData.Height,
                  width: props.componentData.Width,
                  marginBottom: "5px",
                }}
                alt={icon}
              />
            );
          })
        )}
      </div>
      {!props.componentData.AngledLook && renderText()}
    </div>
  );
}

export default ImageSelectElement;
