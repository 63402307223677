import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

const KitBox = ({ id }) => {
  return (
    <View style={styles.kitBox}>
      <Text style={styles.kitBoxLabel}>Kit ID</Text>
      <Text style={styles.kitBoxId}>{id || "NO ID"}</Text>
    </View>
  );
};

const InstallationBox = ({ id }) => {
  return (
    <>
      <View style={styles.installationBox}>
        <Text style={styles.installationLabel}>Installation Guide Canopy</Text>
        <Image
          style={styles.installationValue}
          src={`${window.location.origin}/packingList/qrCode1.png`}
        />
      </View>
      <View style={styles.installationBox}>
        <Text style={styles.installationLabel}>Installation Guide Baffle</Text>
        <Image
          style={styles.installationValue}
          src={`${window.location.origin}/packingList/qrCode3.png`}
        />
      </View>
      <View style={styles.installationBox}>
        <Text style={styles.installationLabel}>Installation Video</Text>
        <Image
          style={styles.installationValue}
          src={`${window.location.origin}/packingList/qrCode2.png`}
        />
      </View>
      <View style={styles.installationBox}>
        <Text style={styles.installationLabel}>Kit Visual</Text>
        {id ? (
          <Image
            allowDangerousPaths
            src={id}
            style={styles.installationValue}
          />
        ) : (
          <Text style={styles.installationValue}>NO QRCODE</Text>
        )}
      </View>
    </>
  );
};

const SignatureBox = () => {
  return (
    <>
      <View style={styles.signedBox}>
        <Text style={styles.signedLabel}>Signed</Text>
        <Text style={styles.signedValue}></Text>
      </View>
      <View style={styles.datedBox}>
        <Text style={styles.signedLabel}>Dated</Text>
        <Text style={styles.signedValue}></Text>
      </View>
    </>
  );
};

const PDFDoc = ({ data, url }) => {
  console.log(data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.sonifyLogo}
            src={`${window.location.origin}/packingList/sonifyLogo1.png`}
          />
        </View>
        <Text style={styles.header}>Sonify Packing List</Text>

        <View style={styles.topRow}>
          <View style={styles.kitId}>
            <KitBox id={data.kitId} />
          </View>
          <View style={styles.installationBoxes}>
            <InstallationBox id={url} />
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>Image</Text>
            </View>
            <View style={styles.descriptionHeader}>
              <Text style={styles.tableHeaderCell}>Description</Text>
            </View>
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>Colour</Text>
            </View>
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>Quantity</Text>
            </View>
          </View>

          {data.container.data.map((item) => {
            console.log(item.data.code);
            return (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Image
                    style={styles.image}
                    src={`${window.location.origin}/packingList/${item.data.code}.png`}
                  />
                  {/* <Text style={styles.tableCell}>{item.data.code}</Text> */}
                </View>
                <View style={styles.descriptionCol}>
                  <Text style={styles.tableCell}>{item.data.description}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.data.color}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.count}</Text>
                </View>
              </View>
            );
          })}

          {/* <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Image style={styles.image} src="https://fakeimg.pl/50x50" />
            </View>
            <View style={styles.descriptionCol}>
              <Text style={styles.tableCell}>asdf</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>asdf</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>asdf</Text>
            </View>
          </View> */}
        </View>
        <Text style={styles.asteriskText}>*Wire Hangers Not Included</Text>

        <View style={styles.bottomRow}>
          <View style={styles.kitPreparationBox}>
            <Text>Kit Preparation</Text>
            <SignatureBox />
          </View>
          <View style={styles.qualityCheckBox}>
            <Text>Final Quality Check</Text>
            <SignatureBox />
          </View>
        </View>
        <Text styles={styles.bottomText}>
          The packaging has been checked twice and any discrepancies are to be
          raised in accordance with your terms and conditions, being 48 hours of
          delivery of the goods.
        </Text>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 30,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  sonifyLogo: {
    height: "80px",
    width: "130px",
  },
  header: {
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    marginBottom: 20,
  },

  topRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },

  kitBox: {
    display: "flex",
    flexDirection: "row",
  },
  kitBoxLabel: {
    padding: 10,
    borderWidth: 1,
  },
  kitBoxId: {
    width: "50%",
    padding: 10,
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  installationBoxes: {
    flexDirection: "row",
    borderWidth: 1,
    borderRightWidth: 0,
  },
  installationBox: {
    borderRightWidth: 1,
    width: "110px",
    textAlign: "center",
  },
  installationLabel: {
    borderBottomWidth: 1,
    padding: 5,
    height: "35px",
    fontSize: 8,
  },
  installationValue: {
    paddingVertical: 5,
    height: "auto",
  },
  bottomRow: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signedBox: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
  },
  datedBox: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    borderTopWidth: 0,
  },
  signedLabel: {
    borderRightWidth: 1,
    padding: 5,
    paddingRight: 10,
    width: "50px",
  },
  signedValue: {
    width: "30%",
    height: "30px",
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableHeader: {
    width: "20%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  descriptionHeader: {
    width: "40%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    paddingBottom: 5,
  },
  descriptionCol: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    paddingBottom: 5,
  },
  tableHeaderCell: {
    margin: "auto",
    marginVertical: 5,
    fontSize: 10,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    height: "auto",
  },
  image: {
    height: "45px",
    display: "flex",
    margin: "auto",
    marginTop: 5,
  },
  qrImage: {
    height: "25%",
    width: "100%",
  },
  asteriskText: {
    textAlign: "right",
    fontFamily: "Helvetica-Oblique",
    marginTop: 5,
  },
  bottomText: {},
});

export default PDFDoc;
