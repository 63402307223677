import React from "react";
import AccordionComponent from "../AccordionComponent";
import ImageSelectComponent from "../ImageSelectComponent";
import SliderComponent from "../SliderComponent";
import { useParametricData } from "../../hooks/ParametricDataContext";
import CheckboxComponent from "../CheckboxComponent";

export default function AngledPanels({
  inputEmitter,
  onClickAccordion,
  index,
  currentOpenAccordion,
}) {
  const parametricData = useParametricData();
  const looksData = {
    Name: "AnglePatternType",
    Path: "AngleSettings",
    OverrideTitle: "Look",
    Images: [
      {
        Icon: ["ceiling1Inactive"],
        Value: { AngleSettings: { AnglePatternType: 0 } },
        Text: "No Angles",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["checker1"],
        Value: { AngleSettings: { AnglePatternType: 1 } },
        Text: "Checker",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["wavymesh3"],
        Value: { AngleSettings: { AnglePatternType: 2 } },
        Text: "Wavy Mesh",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["brokenzigzag2"],
        Value: { AngleSettings: { AnglePatternType: 4 } },
        Text: "Zigzag",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["flow1"],
        Value: { AngleSettings: { AnglePatternType: 5 } },
        Text: "Flow",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["woven1"],
        Value: { AngleSettings: { AnglePatternType: 6 } },
        Text: "Woven",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
      {
        Icon: ["wovenlong1"],
        Value: { AngleSettings: { AnglePatternType: 7 } },
        Text: "Woven (long)",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },

      {
        Icon: ["shade1"],
        Value: { AngleSettings: { AnglePatternType: 8 } },
        Text: "Shade",
        AngledLook: true,
        Height: 50,
        Width: 100,
      },
    ],
  };

  const checkerData = {
    Name: "AngleCheckerType",
    Path: "AngleSettings",
    ComponentType: "ImageSelector",
    Visible: {
      AngleSettings: { AnglePatternType: 1 },
    },
    Images: [
      {
        Icon: ["checkerType1"],
        Value: { AngleSettings: { AngleCheckerType: "One" } },
        Text: "1",
        AngledLook: true,
        Height: 75,
        Width: 75,
      },
      {
        Icon: ["checkerType2"],
        Value: { AngleSettings: { AngleCheckerType: "Two" } },
        Text: "2",
        AngledLook: true,
        Height: 75,
        Width: 75,
      },
      {
        Icon: ["checkerType3"],
        Value: { AngleSettings: { AngleCheckerType: "Four" } },
        Text: "4",
        AngledLook: true,
        Height: 75,
        Width: 75,
      },
    ],
  };

  const canopyCountData = {
    Value: "50",
    Min: "0",
    Max: "100",
    DisplayName: "Amount of Angled Panels",
    Units: "%",
    Step: "10",
    Name: "AmountAngledCanopies",
    Path: "AngleSettings",
    OverrideTitle: "Canopy_shape",
  };

  const flowWidthData = {
    Visible: {
      AngleSettings: { AnglePatternType: 5 },
    },
    Value: "1",
    Min: "1",
    Max: "4",
    DisplayName: "Flow Row Width",
    Units: "mm",
    Step: "1",
    Multiplier: 600,
    Name: "FlowWidth",
    Path: "AngleSettings",
  };

  const orientationData = {
    Value: "1",
    Min: "0",
    Max: "270",
    DisplayName: "Angle Orientation",
    Units: "°",
    Step: "90",
    Name: "AngleOrientation",
    Path: "AngleSettings",
    OverrideTitle: "Grid_lengths",
  };

  const rotationData = {
    Name: "RandomAngleRotations",
    Path: "AngleSettings",
    OverrideTitle: "Orientation",
    Images: [
      {
        Icon: "recLook1",
        Value: { AngleSettings: { RandomAngleRotations: true } },
        Text: "Random",
      },
      {
        Icon: "recLook1",
        Value: { AngleSettings: { RandomAngleRotations: false } },
        Text: "Not Random",
      },
    ],
  };

  const checkerHeight = {
    Category: "bRoundOffsetPercentage",
    AccordianGroup: "",
    Name: "bRoundOffset",
    DisplayName: "Lower Angled Panels?",
    ComponentType: "CheckBox",
    True: { AngleCheckerHeightType: 1 },
    False: { AngleCheckerHeightType: 0 },
    Path: "AngleSettings",
  };

  return (
    <>
      <AccordionComponent
        title={"Angled_Looks"}
        toggleItem={() => onClickAccordion("Angled_Looks")}
        index={index}
        inputEmitter={inputEmitter}
        isOpen={"Angled_Looks" === currentOpenAccordion}
        overrideTitle={looksData.OverrideTitle}
      >
        <ImageSelectComponent
          inputEmitter={inputEmitter}
          componentData={looksData}
          key={looksData.Name}
        />

        {/* <h3 style={{ textAlign: "center" }}>Angle Adjustment</h3> */}
        <SliderComponent
          inputEmitter={inputEmitter}
          componentData={{
            Name: "AngleAdjust",
            Path: "AngleSettings",
            Value: "5",
            Min: "5",
            Max: "10",
            DisplayName: "Angle Adjust",
            Units: "°",
            Step: "5",
          }}
        />
        {/* {checkerData.Visible.AngleSettings.AnglePatternType === 0 && (
          <h3 style={{ textAlign: "center" }}>Checker Type</h3>
        )} */}
        {(parametricData.AngleSettings.AnglePatternType == 1 ||
          parametricData.AngleSettings.AnglePatternType == "Checker") && (
          <ImageSelectComponent
            inputEmitter={inputEmitter}
            componentData={checkerData}
            key={checkerData.Name}
          />
        )}
        {(parametricData.AngleSettings.AnglePatternType == 5 ||
          parametricData.AngleSettings.AnglePatternType == "Flow") && (
          <SliderComponent
            inputEmitter={inputEmitter}
            componentData={flowWidthData}
          />
        )}
        {(parametricData.AngleSettings.AnglePatternType == 1 ||
          parametricData.AngleSettings.AnglePatternType == "Checker") && (
          <CheckboxComponent
            inputEmitter={inputEmitter}
            componentData={checkerHeight}
          />
        )}
      </AccordionComponent>
      {(parametricData.AngleSettings.AnglePatternType == 1 ||
        parametricData.AngleSettings.AnglePatternType == 4 ||
        parametricData.AngleSettings.AnglePatternType == "Checker" ||
        parametricData.AngleSettings.AnglePatternType == "BrokenZigZag") && (
        <AccordionComponent
          title={"Angled_canopies"}
          toggleItem={() => onClickAccordion("Angled_canopies")}
          index={index++}
          inputEmitter={inputEmitter}
          isOpen={"Angled_canopies" === currentOpenAccordion}
          overrideTitle={canopyCountData.OverrideTitle}
        >
          <SliderComponent
            inputEmitter={inputEmitter}
            componentData={canopyCountData}
          />
        </AccordionComponent>
      )}
      <AccordionComponent
        title={"Orientation"}
        toggleItem={() => onClickAccordion("Angled_orientation")}
        index={index++}
        inputEmitter={inputEmitter}
        isOpen={"Angled_orientation" === currentOpenAccordion}
        overrideTitle={orientationData.OverrideTitle}
      >
        <SliderComponent
          inputEmitter={inputEmitter}
          componentData={orientationData}
        />
      </AccordionComponent>
      <AccordionComponent
        title={"Rotation"}
        toggleItem={() => onClickAccordion("Rotation")}
        index={index++}
        inputEmitter={inputEmitter}
        isOpen={"Rotation" === currentOpenAccordion}
        overrideTitle={rotationData.OverrideTitle}
      >
        <ImageSelectComponent
          inputEmitter={inputEmitter}
          componentData={rotationData}
          key={rotationData.Name}
        />
      </AccordionComponent>
    </>
  );
}
