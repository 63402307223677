import React, { useState } from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { TbFileExport } from "react-icons/tb";
import RevitIcon from "../assets/icons/revit.png";
import DownloadModal from "./DownloadModal";

import AuthModal from "./AuthModal";
import HelpButton from "./HelpModal/HelpButton";
import ControlsHelpModal from "./HelpModal/ControlsHelpModal";

function TopMenu({ menuIndex, inputEmitter, collisionMessage }) {
  const [activeModal, setActiveModal] = useState(-1);

  function closeModals() {
    setActiveModal(-1);
  }

  function exportJson() {
    var message = { messageType: "Export" };
    inputEmitter.EmitUIInteraction(message);
  }

  function exportFolder() {
    const link = document.createElement("a");
    link.href = "jklasdf";
    link.download = "Testing.txt";
    link.click();
  } //to do

  function exportPlugin() {
    const link = document.createElement("a");
    link.href = "";
    link.download = "";
    link.click();
  } //to do

  return (
    <div
      style={{
        position: "absolute",
        top: -40,
        right: 0,
        marginTop: 0,
        marginRight: menuIndex === -1 ? 60 : 400,
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      <div style={{ padding: 4, display: "flex", alignItems: "center" }}>
        <HelpButton clickable={true} flashing={true}>
          <ControlsHelpModal />
        </HelpButton>
      </div>

      <div
        className="img-wrap"
        onClick={() => setActiveModal(0)}
        tabIndex={0}
        role={"button"}
        onKeyUp={() => {}}
      >
        <RiAccountCircleFill
          size={40}
          style={{ display: "block" }}
          color={"#3cdbc0"}
        />
      </div>
      <div
        className="img-wrap"
        onClick={() => setActiveModal(1)}
        tabIndex={0}
        role={"button"}
        onKeyUp={() => {}}
      >
        <img
          class="img-inner"
          src={RevitIcon}
          style={{
            height: 40,
          }}
          alt="Revit Icon"
        />
      </div>
      {activeModal === 0 ? (
        <AuthModal onClose={closeModals} onBack={closeModals} />
      ) : (
        activeModal === 1 && (
          <DownloadModal
            onClose={closeModals}
            onDownload={exportJson}
            onClickFolder={exportFolder}
            onClickPlugin={exportPlugin}
          />
        )
      )}
      <div>{collisionMessage}</div>
    </div>
  );
}

export default TopMenu;
