import React, { useState } from "react";
import Modal from "react-modal";
import MessageButton from "../MessageButton";

const customStyles = {
  content: {
    width: "30%",
    height: "auto",
    top: "50%",
    left: "40%",
    bottom: "auto",
    transform: "translate(-30%, -50%)",
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement("#root");

export default function CustomHeightModal({ onClose, inputEmitter }) {
  const [heightValue, setHeightValue] = useState(3);
  let units = "m";

  const messageData = {
    DisplayName: "Confirm",
    Message: {
      messageType: "SetCustomroomType",
      customRoomHeight: heightValue,
    },
  };

  return (
    <Modal isOpen={true} style={customStyles}>
      <h1>Choose your ceiling height</h1>
      <div className="slider">
        <div className="sliderTitle">Height</div>
        <div className="sliderTitleValue">{heightValue + units}</div>
        <input
          type="range"
          min="3"
          max="12"
          step="0.5"
          value={heightValue}
          onChange={(e) => {
            setHeightValue(e.target.value);
          }}
        />
      </div>
      <MessageButton
        componentData={messageData}
        inputEmitter={inputEmitter}
        clickEvent={onClose}
      />
    </Modal>
  );
}
