import React, { useContext, useState } from "react";

const initData = [
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
  {
    raftType: 0,
    dropdownIndex: 0,
  },
];
const RaftCardsContext = React.createContext();
const SetRaftCardsContext = React.createContext();

export function UseRaftCardsContext() {
  return useContext(RaftCardsContext);
}

export function UseSetRaftCardsContext() {
  return useContext(SetRaftCardsContext);
}
export function RaftCardDataProvider({ children }) {
  const [currentRaftData, setCurrentRaftData] = useState(initData);
  function setData(data, index) {
    if (index === -1) {
      setCurrentRaftData(initData);
      return;
    }
    let copyData = [...currentRaftData];
    copyData[index] = data;
    setCurrentRaftData(copyData);
    console.log(index);
    console.log(copyData);
  }
  return (
    <RaftCardsContext.Provider value={currentRaftData}>
      <SetRaftCardsContext.Provider value={setData}>
        {children}
      </SetRaftCardsContext.Provider>
    </RaftCardsContext.Provider>
  );
}
