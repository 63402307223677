import { Auth } from "aws-amplify";
import axios from "axios";
import { useState, useEffect, React, useMemo } from "react";
import { useUser } from "../hooks/UseContext";
import { useTable } from "react-table";
import { Table } from "semantic-ui-react";
import AdminUserTable from "./AdminUserTable";
import { BounceLoader } from "react-spinners";
import { parametricData } from "../globals/GolbalConfig";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function SelectedUserAdminPanel(props) {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  function getAttributeByName(name) {
    let attribute = "";
    console.log(props.user);
    props.user.Attributes.forEach((element) => {
      if (element.Name === name) {
        attribute = element.Value;
      }
    });
    return attribute;
  }
  async function getConfigs() {
    console.log("configs");
    try {
      const session = await Auth.currentSession();

      const authString = session.getIdToken().getJwtToken();

      const headers = {
        Authorization: authString ? authString : "",
      };
      const userName = props.user.Username;
      console.log(props.user.Username);
      axios
        .get(
          "https://mu0q7g8j95.execute-api.eu-west-2.amazonaws.com/Prod/getConfigs?id=" +
            userName,
          { headers }
        )
        .then((response) => {
          var responseData = [...response.data];
          responseData.forEach((elem) => {
            const newDate = new Date(elem.LastUpdate);

            elem.LastUpdate = moment(newDate).format("MMMM Do YYYY, h:mm:ss a");
            elem.Uid = elem.Uid === undefined ? "N/A" : elem.Uid;
          });
          console.log(responseData);
          setProjects(responseData);
          setLoading(false);
        })
        .catch(() => {
          setProjects([]);
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(async () => {
    await getConfigs();
  }, []);

  const columns = [
    {
      Header: "Project Name",
      accessor: "ConfigurationID",
    },
    {
      Header: "Last Updated",
      accessor: "LastUpdate",
    },
    {
      Header: "ID",
      accessor: "Uid",
    },
  ];
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((currentColumns) => [
      ...currentColumns,
      {
        id: "View",
        Header: "",
        Cell: ({ row }) => (
          <button
            onClick={() => {
              parametricData.Loading = true;
              parametricData.LoadName = row.values.ConfigurationID;
              navigate(
                "/?selectedUser=" +
                  props.user.Username +
                  "&id=" +
                  row.values.ConfigurationID
              );
            }}
            className="button"
          >
            View
          </button>
        ),
      },
    ]);
  };
  return (
    <div style={{ height: "100vh" }}>
      <button
        style={{
          borderTop: "none",
          borderLeft: "none",
          borderBottomRightRadius: 20,
        }}
        onClick={props.onBack}
      >
        <h3 style={{ padding: 10 }}>{"< Back"}</h3>
      </button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <h1>{getAttributeByName("email")}</h1>
      </div>

      {loading ? (
        <div
          style={{
            paddingTop: "20vh",
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <BounceLoader color="#36d7b7" />
        </div>
      ) : (
        <div style={{ overflowY: "scroll", height: "80%" }}>
          {projects.length !== 0 ? (
            <AdminUserTable
              data={projects}
              columns={columns}
              tableHooks={tableHooks}
            />
          ) : (
            <h3 style={{ textAlign: "center", paddingTop: 50 }}>No projects</h3>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectedUserAdminPanel;
