import React, { useState, useEffect } from "react";
import { useParametricData } from "../hooks/ParametricDataContext";
import AccordionComponent from "./AccordionComponent";
import SAssetCheckBox from "./SAssetCheckBox";
import EnableCheckbox from "./SingleAsset/EnableCheckbox";
import SingleAssetColourMenu from "./SingleAsset/SingleAssetColourMenu";
import SliderComponent from "./SliderComponent";
import HelpButton from "./HelpModal/HelpButton";

export default function OverrideMenu(props) {
  // const [canopyContent, setCanopyContent] = useState([]);
  const [colourEnabled, setColourEnabled] = useState(true);
  const [heightEnabled, setHeightEnabled] = useState(true);

  const [showColourCheckbox, setShowColourCheckbox] = useState(true);

  const [storedColour, setStoredColour] = useState({});

  const ParametricData = useParametricData();

  const [overrideValues, setOverrideValues] = useState({
    colour: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
    height: 0,
    baffleHeight: "baffleHeight1",
    bVisible: true,
  });

  const BaffleHeightSlider = {
    Category: "",
    Name: "",
    DisplayName: "Baffle Height",
    ComponentType: "Slider",
    Min: 300,
    Max: 600,
    Units: "mm",
    Step: 300,
    Value: 300,
    NotParametric: true,
    Multiplier: 1,
  };

  const HeightSlider = {
    Value: "0",
    Min: "0",
    Max: "4",
    DisplayName: "Asset Height",
    Units: "mm",
    Step: "1",
    Name: "",
    NotParametric: true,
    Multiplier: 50,
  };

  const setNewColour = (colour) => {
    setOverrideValues({ ...overrideValues, colour: colour });
    setShowColourCheckbox(true);
  };
  const setNewHeight = (height) =>
    setOverrideValues({ ...overrideValues, height: height });
  const setNewBaffleHeight = (baffleHeight) =>
    setOverrideValues({
      ...overrideValues,
      baffleHeight: baffleHeight === "300" ? "baffleHeight1" : "baffleHeight2",
    });
  const setVisibility = (isVisible) =>
    setOverrideValues({ ...overrideValues, bVisible: isVisible });

  function handleColourEnable() {
    if (colourEnabled) {
      setStoredColour(overrideValues.colour);
    }
    setColourEnabled(!colourEnabled);
    if (colourEnabled) {
      setOverrideValues({
        ...overrideValues,
        colour: { r: 0, g: 0, b: 0, a: 255 },
      });
    } else {
      setOverrideValues({ ...overrideValues, colour: storedColour });
    }
  }

  function handleHeightEnable() {
    setHeightEnabled(!heightEnabled);
    if (heightEnabled) {
      setOverrideValues({ ...overrideValues, height: -1 });
    }
  }

  useEffect(() => {
    props.inputEmitter.EmitUIInteraction({
      messageType: "SetSingleAssetEditing",
      IsSelecting: true,
    });

    return function cleanUp() {
      props.inputEmitter.EmitUIInteraction({
        messageType: "SetSingleAssetEditing",
        IsSelecting: false,
      });
    };
  }, []);

  return (
    <div
      hidden={props.menuIndex !== 3}
      style={{ height: "100%", backgroundColor: "#F1F1F1" }}
    >
      <div className="items">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "20px",
            justifyContent: "center",
          }}
          className="configureTitle"
        >
          <span style={{ marginRight: "20px" }}>Single Asset Editing</span>
          <HelpButton
            title="Single Asset Editing"
            section="single_asset"
            clickable={true}
          />
        </div>
        <br />
        {/* colour menu */}
        <div
          className="item-answer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px dotted grey",
            padding: "20px 0",
          }}
        >
          <SingleAssetColourMenu
            inputEmitter={props.inputEmitter}
            setNewColour={setNewColour}
            disabled={!colourEnabled === true}
            showCheckbox={showColourCheckbox}
            setShowCheckbox={setShowColourCheckbox}
          />
          {showColourCheckbox && (
            <EnableCheckbox
              enabled={colourEnabled}
              onClick={handleColourEnable}
            />
          )}
        </div>
        <br />
        {/* height adjustments */}
        <div
          className="item-answer"
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 20px",
            borderBottom: "1px dotted grey",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <SliderComponent
              componentData={HeightSlider}
              inputEmitter={props.inputEmitter}
              onSliderChanged={(e) => setNewHeight(e)}
              disabled={!heightEnabled === true}
            />
          </div>
          <br />

          <EnableCheckbox
            onClick={handleHeightEnable}
            enabled={heightEnabled}
          />
        </div>

        <br />

        {(ParametricData.Type === 3 ||
          (ParametricData.Type === 5 &&
            ParametricData.bMatrixCanopyOrBaffles)) && (
          <div
            className="item-answer"
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 20px",
            }}
          >
            <SliderComponent
              componentData={BaffleHeightSlider}
              inputEmitter={props.inputEmitter}
              onSliderChanged={(e) => setNewBaffleHeight(e)}
            />

            <br />
          </div>
        )}
        <div
          className="item-answer"
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <SAssetCheckBox
            setVisibility={() => setVisibility(!overrideValues.bVisible)}
          />
        </div>
        <br />
        <br />
        <div style={{ width: "100%", textAlign: "center", fontSize: 16 }}>
          Selected Panel
        </div>
        <button
          className="button"
          onClick={() =>
            props.inputEmitter.EmitUIInteraction({
              messageType: "ApplyOverrides",
              OverrideData: overrideValues,
            })
          }
        >
          Apply Edits
        </button>
        <button
          className="button"
          onClick={() =>
            props.inputEmitter.EmitUIInteraction({
              messageType: "RemoveOverrides",
            })
          }
        >
          Remove Edits
        </button>
        <br />
        <br />
        <div style={{ width: "100%", textAlign: "center", fontSize: 16 }}>
          General
        </div>
        <button
          className="button"
          onClick={() =>
            props.inputEmitter.EmitUIInteraction({
              messageType: "ClearSingleAssetSelection",
            })
          }
        >
          Clear Selection
        </button>

        <button
          className="button"
          onClick={() =>
            props.inputEmitter.EmitUIInteraction({
              messageType: "SelectHidden",
            })
          }
        >
          Select Hidden Panels
        </button>
        <br />
        <br />

        {/* printing override object here for testing */}
        {/* {JSON.stringify(overrideValues)} */}

        {/* <p style={{ textAlign: "center" }}>
          Selected Canopy Count: {canopyContent.length}
        </p> */}
      </div>
    </div>
  );
}
