import React, { createContext, useContext, useState } from "react";

const GuideImageContext = createContext();
const SetGuideImageContext = createContext();

export function useGuideImage() {
  return useContext(GuideImageContext);
}

export function useSetGuideImage() {
  return useContext(SetGuideImageContext);
}

export function GuideImageProvider({ children }) {
  const [guideImage, setGuideImage] = useState("");

  function setData(data) {
    setGuideImage(data);
  }

  return (
    <GuideImageContext.Provider value={guideImage}>
      <SetGuideImageContext.Provider value={setData}>
        {children}
      </SetGuideImageContext.Provider>
    </GuideImageContext.Provider>
  );
}
