export const coloursObject = [
  { r: 236, g: 236, b: 231, swatchName: "RAL 9003", tooltip: "WH1" },
  { r: 220, g: 226, b: 230, swatchName: "RAL 260 90 05", tooltip: "BL5" },
  { r: 224, g: 232, b: 210, swatchName: "RAL 130 90 10", tooltip: "GR5" },
  { r: 240, g: 224, b: 204, swatchName: "RAL 080 90 10", tooltip: "YE3" },
  { r: 254, g: 240, b: 228, swatchName: "RAL 050 90 10", tooltip: "OR3" },
  { r: 242, g: 218, b: 214, swatchName: "RAL 010 90 10", tooltip: "RE5" },
  { r: 234, g: 224, b: 228, swatchName: "RAL 340 90 05", tooltip: "PU5" },
  { r: 192, g: 170, b: 160, swatchName: "RAL 050 70 10", tooltip: "BR4" },
  { r: 214, g: 213, b: 212, swatchName: "RAL 000 85 00", tooltip: "GY1" }, ///
  { r: 176, g: 200, b: 222, swatchName: "RAL 260 80 15", tooltip: "BL4" },
  { r: 178, g: 206, b: 152, swatchName: "RAL 130 80 30", tooltip: "GR4" },
  { r: 240, g: 188, b: 125, swatchName: "RAL 075 80 40", tooltip: "YE2" },
  { r: 252, g: 184, b: 156, swatchName: "RAL 050 80 30", tooltip: "OR2" },
  { r: 238, g: 186, b: 188, swatchName: "RAL 020 80 20", tooltip: "RE4" },
  { r: 230, g: 186, b: 210, swatchName: "RAL 340 80 20", tooltip: "PU4" },
  { r: 208, g: 164, b: 146, swatchName: "RAL 050 70 20", tooltip: "BR3" },
  { r: 158, g: 158, b: 158, swatchName: "RAL 000 65 00", tooltip: "GY2" },
  { r: 79, g: 123, b: 160, swatchName: "RAL 260 50 25", tooltip: "BL3" },
  { r: 89, g: 128, b: 77, swatchName: "RAL 140 50 30", tooltip: "GR3" },
  { void: true },
  { void: true },
  { r: 208, g: 111, b: 121, swatchName: "RAL 020 60 40", tooltip: "RE3" },
  { r: 188, g: 119, b: 164, swatchName: "RAL 340 60 35", tooltip: "PU3" },
  { void: true },
  { r: 67, g: 67, b: 65, swatchName: "RAL 000 30 00", tooltip: "GY3" },
  { r: 37, g: 71, b: 97, swatchName: "RAL 260 30 20", tooltip: "BL2" },
  { r: 53, g: 75, b: 45, swatchName: "RAL 140 30 20", tooltip: "GR2" },
  { void: true },
  { void: true },
  { r: 121, g: 33, b: 47, swatchName: "RAL 020 30 40", tooltip: "RE2" },
  { r: 99, g: 47, b: 83, swatchName: "RAL 340 30 30", tooltip: "PU2" },
  { r: 105, g: 49, b: 37, swatchName: "RAL 040 30 30", tooltip: "BR2" },

  { r: 15, g: 15, b: 15, swatchName: "RAL 9005", tooltip: "BK1" },
  { r: 1, g: 130, b: 180, swatchName: "RAL 5015", tooltip: "BL1" },
  { r: 43, g: 146, b: 63, swatchName: "RAL 6037", tooltip: "GR1" },
  { r: 250, g: 172, b: 37, swatchName: "RAL 1003", tooltip: "YE1" },
  { r: 222, g: 93, b: 49, swatchName: "RAL 2009", tooltip: "OR1" },
  { r: 190, g: 57, b: 53, swatchName: "RAL 3020", tooltip: "RE1" },

  { r: 150, g: 71, b: 123, swatchName: "RAL 4006", tooltip: "PU1" },
  { r: 148, g: 87, b: 69, swatchName: "RAL 8004", tooltip: "BR1" },
];

export function findColour(colour, fuzziness) {
  let index = 0;
  for (let i = 0; i < coloursObject.length; i++) {
    if (!coloursObject[i].void) {
      if (
        Math.abs(colour.r - coloursObject[i].r) <= fuzziness &&
        Math.abs(colour.g - coloursObject[i].g) <= fuzziness &&
        Math.abs(colour.b - coloursObject[i].b) <= fuzziness
      ) {
        index = i;
        break;
      }
    }
  }
  return index;
}
