import React, { useEffect, useState } from "react";
import ConfigLayout from "./ConfigLayout";
import ModularMenu from "./ModularMenu";
import "../assets/css/modal.css";
import * as XLSX from "xlsx";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";
import { TempModal } from "./TempModal";
import { useSetUser, useUser } from "../hooks/UseContext";
import ConfigButton from "./ConfigButton";
import MessageButton from "./MessageButton";
import RaftCard from "./RaftCard";
import OverrideMenu from "./OverrideMenu";
import HelpButton from "./HelpModal/HelpButton";
import PDFDoc from "../docs/PDFDoc";

import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import QRCode from "qrcode";
import Modal from "react-modal";
import { StyledSpinner } from "../styles/RaftCard.styled";
import client from "../client.json";
import ConstructionGuide from "./ConstructionGuide/ConstructionGuide";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function MainMenu(props) {
  const [shiftDown, setShiftDown] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  // useEffect(() => {
  //   if (fileLoading) {
  //     <Modal isOpen={true} contentLabel="Downloading PDF" style={customStyles}>
  //       Downloading Now
  //     </Modal>;
  //   }
  // }, [fileLoading]);

  /*
   * updates state whether shift is pressed or not
   */

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey) {
        setShiftDown(true);
      }
    };

    const handleKeyUp = (event) => {
      if (!event.shiftKey) {
        setShiftDown(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  /*
   * event handlers for raft menu interaction
   */

  function handleDeleteRaft(id, index) {
    let newSelectedIndex = -1;
    if (index === props.selectedIndex) {
      //if deleting a selected
      if (props.selectedIndex === props.rafts.length - 1) {
        //if deleting final raft on list
        handleSelectRaft(index - 1);
        newSelectedIndex = index - 1;
      } else {
        handleSelectRaft(index);
        newSelectedIndex = index;
      }
    }
    let newRafts = [...props.rafts];
    newRafts.splice(index, 1);
    props.setRaft(newRafts);
    console.log(index);
    props.inputEmitter.EmitUIInteraction({
      messageType: "DeleteRaft",
      Index: index,
      SelectedIndex: newSelectedIndex,
    });
  }

  function handleSelectRaft(index) {
    props.setSelectedIndex(index);
    props.inputEmitter.EmitUIInteraction({
      messageType: "SelectRaft",
      Index: index,
    });
  }

  function handleMoveRaft(id, index, event) {
    if (props.movingRaft.includes(index)) {
      props.setMovingRaft((prevMoving) => {
        if (prevMoving.includes(index)) {
          return prevMoving.filter((i) => i !== index);
        } else {
          return [...prevMoving, index];
        }
      });
    } else {
      console.log("Moving raft " + index);
      props.inputEmitter.EmitUIInteraction({
        messageType: "MoveRaft",
        Index: index,
        shiftDown: shiftDown,
      });
      if (event.shiftKey) {
        props.setMovingRaft((prevMoving) => [...prevMoving, index]);
      } else {
        props.setMovingRaft([index]);
      }
    }
  }

  const data = {
    Category: "",
    AccordianGroup: "",
    Name: "Raft",
    DisplayName: "New Raft",
    ComponentType: "MessageButton",
    Message: {
      messageType: "SpawnRaft",
    },
  };

  const [shareButtonText, setShareButtonText] = useState("Share");
  const user = useUser();
  const setUser = useSetUser();
  function onLoginModalClick(value) {
    setUser(value);
    var message = { messageType: "Login", UserID: value };
    props.inputEmitter.EmitUIInteraction(message);
    setModalOpen(false);
  }
  const [modalOpen, setModalOpen] = useState(false);
  const setData = useParametricUpdate();
  const parametricData = useParametricData();

  useEffect(() => {
    const subscription = props.messageSubject.subscribe((value) => {
      const message = JSON.parse(value);
      const asyncPackingList = async () => {
        await downloadPackingList(message.qrURL, message);
      };

      if (message.messageType === "FinishMoving") {
        props.setMovingRaft([]);
        console.log("finished moving");
      }

      if (message.messageType === "packingList") {
        asyncPackingList();
      }

      return () => {
        subscription.unsubscribe();
      };
    });
  }, [props.messageSubject]);

  useEffect(() => {
    const subscription = props.messageSubject.subscribe(
      (value) => {
        const message = JSON.parse(value);

        if (message.messageType === "boq" && props.menuIndex === 2) {
          console.log(message);
          delete message.messageType;
          console.log("useeffect says menuindex is " + props.menuIndex);

          let excelData = [
            ["Kit ID", message.container.data[0].data.code],
            ["", "", ""],
            ["Code", "Description", "Quantity"],
          ];
          message.container.data.splice(0, 1);
          message.container.data.forEach((element) => {
            let newEntryArray = [element.data.code, element.data.description];
            if (element.count >= 0) newEntryArray.push(element.count);
            excelData.push(newEntryArray);
          });
          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const dateTimeString = new Date().toDateString();
          XLSX.writeFile(workbook, "BoQ" + dateTimeString + ".xlsx");
        } else if (message.messageType === "Export") {
          console.log("EXPORTING");
          const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(message.values)
          )}`;
          const link = document.createElement("a");
          link.href = jsonString;
          link.download =
            "Revit Export" + new Date().toDateString() + ".sonify";

          link.click();
        }
      },
      (err) => {
        console.log(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [props.messageSubject, setData, parametricData, props.menuIndex]);

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }

  const pdfData = {
    container: [
      {
        data: { code: "testCode", description: "testDesc", color: "testCol" },
        count: 1,
      },
    ],
  };

  const downloadPackingList = async (url, data) => {
    setFileLoading(true);
    const canvas = await QRCode.toCanvas(url);
    const dataUrl = canvas.toDataURL("image/png");

    const blob = await pdf(<PDFDoc data={data} url={dataUrl} />).toBlob();
    saveAs(blob, "PackingList.pdf");
    setFileLoading(false);
  };

  const ikeaData = {
    Category: "",
    AccordianGroup: "",
    Name: "Ikea",
    DisplayName: "Test Ikea Guide",
    ComponentType: "MessageButton",
    Message: {
      messageType: "TestIkeaGuide",
    },
  };

  return (
    props.menuIndex !== -1 && (
      <>
        {fileLoading && (
          <Modal
            isOpen={fileLoading}
            style={customStyles}
            label="Downloading PDF..."
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>Generating Packing List PDF</h1>
              <p>Please wait a few moments...</p>
              <StyledSpinner />
            </div>
          </Modal>
        )}
        <TempModal
          buttonTitle="Log in"
          fieldTitle="Username:"
          onModalClick={onLoginModalClick}
          isOpen={modalOpen}
          label="Log in"
        />
        <div className="configurePanel">
          {/* configuration settings menu tab */}

          {props.menuIndex === 0 && (
            <div className="items">
              <ModularMenu inputEmitter={props.inputEmitter} />
            </div>
          )}

          {parametricData.Type === 6 &&
            client.modelId === "0a44b14f-b36a-46a5-b439-ab7c5a8c59aa" && (
              <MessageButton
                componentData={ikeaData}
                inputEmitter={props.inputEmitter}
                key={ikeaData.Name}
                hidden={props.menuIndex !== 0}
              />
            )}

          <button
            hidden={props.menuIndex !== 0}
            className="button"
            onClick={() => {
              var message = { MessageType: "ScreenShot" };
              props.inputEmitter.EmitUIInteraction(message);
            }}
          >
            <span>Screenshot</span>
          </button>
          {props.menuIndex === 0 && user != null && !parametricData.Admin && (
            <ConfigButton
              inputEmitter={props.inputEmitter}
              messageSubject={props.messageSubject}
            />
          )}

          <button
            hidden={props.menuIndex !== 0}
            className="button"
            disabled={shareButtonText === "Copied!"}
            onClick={() => {
              let tempData = { ...parametricData };
              delete tempData.LoadName;
              delete tempData.Loading;
              console.log(tempData);
              delete tempData.Admin;
              if (navigator.clipboard) {
                navigator.clipboard.writeText(
                  window.origin +
                    "/?data=" +
                    window.btoa(JSON.stringify(tempData))
                );
              } else {
                unsecuredCopyToClipboard(
                  window.origin +
                    "/?data=" +
                    window.btoa(JSON.stringify(tempData))
                );
              }
              setShareButtonText("Copied!");
              const interval = setInterval(async () => {
                setShareButtonText("Share");

                clearInterval(interval);
              }, 3000);
            }}
          >
            <span>{shareButtonText}</span>
          </button>
          {props.menuIndex === 0 && !parametricData.Admin && (
            <button
              hidden={props.menuIndex !== 0}
              className="button back"
              onClick={props.reset}
            >
              Start Over
            </button>
          )}

          {/* saved configs tab */}

          {props.menuIndex === 1 && (
            <div
              hidden={props.menuIndex !== 1}
              style={{ height: "100%", backgroundColor: "#F1F1F1" }}
            >
              <ConfigLayout
                inputEmitter={props.inputEmitter}
                messageSubject={props.messageSubject}
              />
            </div>
          )}

          {/* rafts tab */}

          {props.menuIndex === 2 && (
            <div
              hidden={props.menuIndex !== 2}
              style={{ height: "100%", backgroundColor: "#F1F1F1" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "20px",
                }}
              >
                <span style={{ marginRight: "0px", width: "400%" }}>
                  <MessageButton
                    componentData={data}
                    inputEmitter={props.inputEmitter}
                    key={data.Name}
                    onClick={props.onNewRaftClick}
                    disabled={props.rafts.length >= 5}
                  />
                </span>

                <span style={{ marginBottom: "5px" }}>
                  <HelpButton
                    title="Multiple Designs"
                    section="multiple_designs"
                    clickable={true}
                  />
                </span>
              </div>

              <div>
                {props.rafts.map((item, index) => {
                  return (
                    <RaftCard
                      raft={item}
                      key={"RaftCard" + index}
                      onDeleteRaft={() =>
                        handleDeleteRaft(props.rafts.id, index)
                      }
                      onClickBoQ={() => {
                        if (props.menuIndex === 2) {
                          var message = { messageType: "Boq", Index: index };
                          props.inputEmitter.EmitUIInteraction(message);
                          console.log("m index is " + props.menuIndex);
                        }
                      }}
                      onSelectRaft={() => handleSelectRaft(index)}
                      onMoveRaft={(event) =>
                        handleMoveRaft(props.rafts.id, index, event)
                      }
                      selected={props.selectedIndex}
                      raftsRemaining={props.rafts.length}
                      index={index}
                      inputEmitter={props.inputEmitter}
                      movingRaft={props.movingRaft}
                      pdfIsLoading={fileLoading}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {/* single asset editing menu tab */}

          {props.menuIndex === 3 && (
            <OverrideMenu
              inputEmitter={props.inputEmitter}
              menuIndex={props.menuIndex}
            />
          )}

          {/* ikea testing feature tab */}

          {props.menuIndex === 4 && (
            <div
              hidden={props.menuIndex !== 4}
              style={{ height: "100%", backgroundColor: "#F1F1F1" }}
            >
              <ConstructionGuide
                inputEmitter={props.inputEmitter}
                messageSubject={props.messageSubject}
                selectedRaftIndex={props.selectedIndex}
              />
            </div>
          )}
        </div>
      </>
    )
  );
}

export default MainMenu;
