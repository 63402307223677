import React from "react";
import "react-tippy/dist/tippy.css";

import { Tooltip } from "react-tippy";

function ColourPickerComponent({
  coloursObject,
  selectedIndex,
  onColourSelected,
  disabled,
}) {
  return (
    <div className="lookContainer">
      {coloursObject.map((colour, i) => {
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        return (
          <Tooltip
            title={colour.tooltip}
            style={{ flex: "1 0 11%" }}
            position="top"
            followCursor
          >
            <div
              tabIndex={0}
              key={i}
              role="button"
              onKeyUp={() => console.log("")}
              onClick={() => {
                if (!colour.void) {
                  onColourSelected(i);
                }
              }}
              disabled={disabled}
              className={colour.void ? "img-wrap-invis" : "img-wrap"}
              style={{
                padding: 0,
              }}
            >
              {i === selectedIndex && <div className="tick-small" />}
              <div
                style={{
                  borderColor: colour.void ? "transparent" : "black",
                  borderWidth: 2,
                  borderStyle: "solid",
                  width: 34,
                  height: 28,
                  backgroundColor: `rgb(${colour.r}, ${colour.g},${colour.b})`,
                }}
              />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ColourPickerComponent;
