import React, { useEffect, useState } from "react";
import "../App.css";
import { checkParaData } from "../globals/GolbalConfig";
import {
  useParametricData,
  useParametricUpdate,
} from "../hooks/ParametricDataContext";

function SliderComponent(props) {
  const parametricData = useParametricData();
  const setParametricData = useParametricUpdate();
  const [visible, setVisible] = useState(
    !props.componentData.Hidden ||
      !checkParaData(parametricData, props.componentData.Hidden)
  );
  const [value, changeValue] = useState(
    props.componentData.NotParametric
      ? props.componentData.Value
      : props.componentData.Path
      ? parametricData[props.componentData.Path][props.componentData.Name]
      : parametricData[props.componentData.Name]
  );
  var multiplier =
    props.componentData.Multiplier === undefined
      ? 1
      : props.componentData.Multiplier;
  const [textValue, changeTextValue] = useState(
    (value * multiplier).toString() + props.componentData.Units
  );

  useEffect(() => {
    if (props.componentData.NotParametric) return;
    console.log("fired");
    setVisible(
      !props.componentData.Hidden ||
        !checkParaData(parametricData, props.componentData.Hidden)
    );
    let tempValue = props.componentData.Path
      ? parametricData[props.componentData.Path][props.componentData.Name]
      : parametricData[props.componentData.Name];
    changeValue(tempValue);

    changeTextValue(
      (tempValue * multiplier).toString() + props.componentData.Units
    );

    /*
     * Synced spacing
     */

    if (
      props.componentData.Name === "YOffset" &&
      parametricData.bSyncedSpacing
    ) {
      updateSlider(parametricData.XOffset);
      parametricData.YOffset = parametricData.XOffset;
    }
  }, [parametricData]);

  /*
   * Synced spacing for grid lengths
   */

  // useEffect(() => {
  //   if (
  //     parametricData.GridOffsetSynced &&
  //     props.componentData.Name === "PCTOffsetLength"
  //   ) {
  //     updateSlider(parametricData.PCTOffsetLength);
  //     parametricData.PCTOffsetLength = parametricData.MROffsetLength;
  //   }
  // }, [parametricData, parametricData.PCTOffsetLength]);

  /*
   * Synced spacing for grid lengths
   */

  useEffect(() => {
    if (
      parametricData.GridOffsetSynced &&
      props.componentData.Name === "MROffsetLength"
    ) {
      changeValue(parametricData.MROffsetLength);
      changeTextValue(
        parametricData.MROffsetLength.toString() + props.componentData.Units
      );
      parametricData.PCTOffsetLength = parametricData.MROffsetLength;
      console.log("Main " + parametricData.MROffsetLength);
      console.log("Secondary " + parametricData.PCTOffsetLength);
    }
  }, [parametricData, parametricData.MROffsetLength]);

  const updateSlider = (e) => {
    changeValue(e);
    multiplier =
      props.componentData.Multiplier === undefined
        ? 1
        : props.componentData.Multiplier;
    changeTextValue((e * multiplier).toString() + props.componentData.Units);
    if (props.componentData.NotParametric) {
      props.onSliderChanged(e);
      return;
    }
    var message = { messageType: "SetValue" };
    let tempData = parametricData;
    if (props.componentData.Path) {
      tempData[props.componentData.Path][props.componentData.Name] = e;
      message[props.componentData.Path] = {};
      message[props.componentData.Path][props.componentData.Name] = e;
    } else {
      message[props.componentData.Name] = e;
      tempData[props.componentData.Name] = e;
    }
    //updateParametricData(tempData);
    if (props.componentData.Update) {
      setParametricData({ ...tempData });
    }
    props.inputEmitter.EmitUIInteraction(message);
  };

  return parametricData.bSyncedSpacing &&
    props.componentData.Name === "YOffset" ? (
    <>{props.componentData.Name === "YOffset" && console.log(value)}</>
  ) : (
    <div style={{ flex: 1 }}>
      <div
        className="slider"
        style={{
          visibility: visible ? "visible" : "hidden",
          display: !visible && "none",
        }}
      >
        <div
          className="sliderTitle"
          style={{ opacity: props.textDisabled && 0.3 }}
        >
          {props.componentData.DisplayName}
        </div>

        <div
          className="sliderTitleValue"
          style={{ opacity: props.textDisabled && 0.3 }}
        >
          {textValue}
        </div>

        <input
          disabled={
            (parametricData.bSyncedSpacing &&
              props.componentData.Name === "YOffset") ||
            props.disabled
          }
          style={{ opacity: props.disabled && "0.3" }}
          type="range"
          min={props.componentData.Min}
          max={props.componentData.Max}
          value={value}
          step={props.componentData.Step}
          onChange={(e) => {
            updateSlider(e.target.value);
          }}
        ></input>
      </div>
    </div>
  );
}

export default SliderComponent;
