import React, { useState } from "react";
import Tick from "../assets/icons/tick.svg";

import { useParametricData } from "../hooks/ParametricDataContext";
import ImageSelectComponent from "./ImageSelectComponent";
import PalleteSlider from "./PalleteSlider";
function PalleteEditorComponent(props) {
  const [patternIndex, SetPatternIndex] = useState(2);
  const parametricData = useParametricData();
  return (
    <div>
      <div>
        {/* <div className="img-wrap" style={{ padding: 0, marginRight: 10 }}>
          <div
            onClick={() => {
              props.clickPallete();
            }}
            role="button"
            onKeyUp={() => {}}
            tabIndex={0}
            style={{
              display: "flex",
              flexDirection: "row",
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            {props.pallete.colours.map((colour, colIndex) => {
              return (
                colIndex < props.count && (
                  <div
                    style={{
                      height: 36,
                      width: 26,
                      backgroundColor: `rgb(${props.colours[colour].r}, ${props.colours[colour].g},${props.colours[colour].b})`,
                    }}
                  ></div>
                )
              );
            })}
          </div>
        </div>
        <div>{props.pallete.name}</div>
        <img style={{ display: "inline-block" }} src={Tick} alt="tick" /> */}
        <button className="button" onClick={props.clickPallete}>
          Load Palette Preset
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          textAlign: "center",
        }}
      >
        <div
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "transparent",
            height: 36,
            width: 26,
            marginRight: 10,
            backgroundColor: "transparent",
          }}
        />
        <div style={{ flex: 1 }}>Colour:</div>
        <div style={{ width: 190 }}>Distribution:</div>
      </div>
      <PalleteSlider
        onClick={() => props.openSelector(0)}
        hideSlider={patternIndex === 1}
        sliderValue="MainColourPercentage"
        colour={props.colours[props.items[0]]}
        inputEmitter={props.inputEmitter}
      />
      <PalleteSlider
        onClick={() => props.openSelector(1)}
        hideSlider={patternIndex === 1}
        sliderValue="SecondColourPercentage"
        colour={props.colours[props.items[1]]}
        inputEmitter={props.inputEmitter}
      />
      {props.count === 3 && (
        <PalleteSlider
          onClick={() => props.openSelector(2)}
          hideSlider={patternIndex === 1}
          sliderValue="ThirdColourPercentage"
          colour={props.colours[props.items[2]]}
          inputEmitter={props.inputEmitter}
        />
      )}
      <div style={{ height: 20 }} />

      <button
        className="button"
        style={{ visibility: patternIndex === 1 ? "hidden" : "visible" }}
        onClick={() => {
          props.inputEmitter.EmitUIInteraction({
            messageType: "SetValue",
            colours: {
              RandomSeed: Math.floor(Math.random() * 10000000),
            },
          });
        }}
      >
        Randomize
      </button>

      <div>Colour Pattern:</div>
      <ImageSelectComponent
        initialValue={patternIndex}
        onImageChange={(index) => {
          SetPatternIndex(index);
        }}
        inputEmitter={props.inputEmitter}
        enumStringArray={[
          "MainColour",
          "RandomColours",
          "RowColours",
          "CheckeredColours",
        ]}
        componentData={{
          KeepState: true,
          Images: [
            {
              Icon: "colourRows",
              Value: { Colours: { ScatteringType: "RowColours" } },
              Text: "Rows",
            },
            {
              Icon: "colourCheckered",
              Value: { Colours: { ScatteringType: "CheckeredColours" } },
              Text: "Checkered",
            },
            {
              Icon: "colourRandom",
              Value: { Colours: { ScatteringType: "RandomColours" } },

              Text: "Random",
            },
            {
              Icon: "sequence",
              Value: { Colours: { ScatteringType: "Sequence" } },

              Text: "Sequence",
            },
          ],
        }}
      />
    </div>
  );
}

export default PalleteEditorComponent;
