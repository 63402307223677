import React, { Children, useState } from "react";
import { StyledHelpButton } from "../../styles/HelpButton.styled";
import { BsQuestion } from "react-icons/bs";

import HelpModal from "./HelpModal";

export default function HelpButton({
  title,
  section,
  children,
  clickable,
  flashing,
}) {
  const [helpModalActive, setHelpModalActive] = useState(false);

  const handleClickClose = () => {
    setHelpModalActive(false);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClickClose: handleClickClose });
    }
    return child;
  });

  function drawModal() {
    return children ? (
      childrenWithProps
    ) : (
      <HelpModal
        title={title}
        section={section}
        onClickClose={handleClickClose}
      />
    );
  }
  return (
    <>
      <StyledHelpButton
        onClick={() => clickable && setHelpModalActive(true)}
        clickable={clickable}
        flashing={flashing}
      >
        ?
      </StyledHelpButton>
      {helpModalActive && drawModal()}
    </>
  );
}
