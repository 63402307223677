import React from "react";
import SliderComponent from "./SliderComponent";
import CheckboxComponent from "./CheckboxComponent";
import { TbRuler2 } from "react-icons/tb";

export default function GridLengths(props) {
  const MainRunnerOffsetData = {
    Value: "0",
    Min: "0",
    Max: "600",
    DisplayName: "Main Runner Offset",
    Units: "mm",
    Step: "100",
    Name: "MROffsetLength",
    Multiplier: 1,
    Hidden: {
      GridOffsetSynced: true,
    },
  };

  const SecondaryRunnerOffsetData = {
    Value: "0",
    Min: "0",
    Max: "600",
    DisplayName: "Secondary Runner Offset",
    Units: "mm",
    Step: "100",
    Name: "PCTOffsetLength",
    Multiplier: 1,
    Hidden: {
      GridOffsetSynced: true,
    },
  };
  const SyncedOffsetData = {
    Value: "0",
    Min: "0",
    Max: "600",
    DisplayName: "Runner Offset",
    Units: "mm",
    Step: "100",
    Name: "PCTOffsetLength",
    Multiplier: 1,
    Hidden: {
      GridOffsetSynced: false,
    },
  };
  const CheckboxData = {
    Name: "GridOffsetSynced",
    DisplayName: "Sync",
    True: { GridOffsetSynced: true },
    False: { GridOffsetSynced: false },
    Update: true,
  };

  return (
    <div>
      <SliderComponent
        inputEmitter={props.inputEmitter}
        componentData={MainRunnerOffsetData}
        key={MainRunnerOffsetData.Name}
      />
      <SliderComponent
        inputEmitter={props.inputEmitter}
        componentData={SecondaryRunnerOffsetData}
        key={SecondaryRunnerOffsetData.Name}
      />
      <SliderComponent
        inputEmitter={props.inputEmitter}
        componentData={SyncedOffsetData}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0px",
        }}
      >
        <CheckboxComponent
          inputEmitter={props.inputEmitter}
          componentData={CheckboxData}
          key={CheckboxData.Name}
        />
      </div>
    </div>
  );
}
